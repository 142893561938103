/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains a hook that proxies a hook from 
 * online-patient-management-reducers making less types required to use the hook.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import * as React from 'react';
/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { FieldContext, IFormState, IInputRenderProps, useFormState, useScopedField } from '@ngt/forms-core';
import { Typography, Theme } from '@mui/material';
import { DateTime } from 'luxon';
import { getParentPropertyPath, IValidationError } from '@ngt/forms';
import { makeStyles } from 'tss-react/mui';


/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

type TextFieldsProps = IInputRenderProps<string, IValidationError>

export interface IDateDisplayProps extends TextFieldsProps {
    comparisonDateName: string;
    comparisonDatePrependParentName?: boolean;
    comparisonDateTableCell?: boolean;
    days: number;
    applyHighlight?: (formState: IFormState, value?: string|null) => boolean
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */


const useStyles = makeStyles()((theme: Theme) => ({
    highlight: {
        color: theme.palette.error.main,
        fontWeight: 'bold'
    }
}));

/*
 * ---------------------------------------------------------------------------------
 * components
 * ---------------------------------------------------------------------------------
 */

const subscription = { value: true };
const formSubscription = { value: true };

const DateHighlightDisplay: React.FunctionComponent<IDateDisplayProps> = ({
    inputRender: { state: { value, ...restInputState }, actions: { ...restInputActions } },
    comparisonDateName,
    comparisonDatePrependParentName,
    comparisonDateTableCell,
    days,
    applyHighlight,
    ...rest
}) => {
    const { classes } = useStyles();

    const parentContext = React.useContext(FieldContext);

    const computedCompreDateName = React.useMemo(() => {
        if (parentContext?.name && comparisonDatePrependParentName === true) {
            const parentName = comparisonDateTableCell === true ? getParentPropertyPath(parentContext?.name) : parentContext?.name;

            if (comparisonDateName.startsWith('[')) {
                return `${parentName}${comparisonDateName}`;
            }

            return `${parentName}.${comparisonDateName}`;
        }

        return comparisonDateName;
    }, [parentContext?.name, comparisonDateName, comparisonDatePrependParentName, comparisonDateTableCell]);


    const { state: { value: compareDateValue } } = useScopedField<string, IValidationError>(computedCompreDateName, subscription, false);

    const derivedValue = React.useMemo(() => {
        if (!value) {
            return null;
        }

        return DateTime.fromISO(value);
    }, [value]);

    const formState = useFormState(formSubscription);

    const apply = React.useMemo(() => {
        return applyHighlight ? applyHighlight(formState, value) : true;
    }, [applyHighlight, formState, value]);

    const highlight = React.useMemo(() => {
        if (!value || !compareDateValue || !apply) {
            return false;
        }
        const date = DateTime.fromISO(value);
        const compare = DateTime.fromISO(compareDateValue);

        return date.diff(compare, 'days')?.days > days;
    }, [value, compareDateValue, days, apply]);

    return (
        <Typography className={highlight ? classes.highlight : undefined}>
            {derivedValue?.toFormat('dd/MM/yyyy')}
        </Typography>
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default DateHighlightDisplay;
