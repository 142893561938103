/* Options:
Date: 2022-12-20 14:55:05
Version: 6.10
Tip: To override a DTO option, remove "//" prefix before updating
BaseUrl: https://localhost:44800

//GlobalNamespace: 
//MakePropertiesOptional: False
//AddServiceStackTypes: True
//AddResponseStatus: False
//AddImplicitVersion: 
//AddDescriptionAsComments: True
//IncludeTypes: 
//ExcludeTypes: 
//DefaultImports: 
*/


export interface IReturn<T>
{
    createResponse(): T;
}

export interface IReturnVoid
{
    createResponse(): void;
}

export interface IGet
{
}

export interface IPost
{
}

export interface IHasSessionId
{
    sessionId: string;
}

export interface IHasBearerToken
{
    bearerToken: string;
}

export interface IDelete
{
}

export class NativeTypesBase
{
    public baseUrl: string;
    public makePartial?: boolean;
    public makeVirtual?: boolean;
    public makeInternal?: boolean;
    public addReturnMarker?: boolean;
    public addDescriptionAsComments?: boolean;
    public addDataContractAttributes?: boolean;
    public makeDataContractsExtensible?: boolean;
    public addIndexesToDataMembers?: boolean;
    public addGeneratedCodeAttributes?: boolean;
    public initializeCollections?: boolean;
    public addImplicitVersion?: number;
    public addResponseStatus?: boolean;
    public addServiceStackTypes?: boolean;
    public addModelExtensions?: boolean;
    public addPropertyAccessors?: boolean;
    public excludeGenericBaseTypes?: boolean;
    public settersReturnThis?: boolean;
    public makePropertiesOptional?: boolean;
    public exportAsTypes?: boolean;
    public exportValueTypes?: boolean;
    public excludeNamespace?: boolean;
    public addDefaultXmlNamespace: string;
    public globalNamespace: string;
    public baseClass: string;
    public package: string;
    public dataClass: string;
    public dataClassJson: string;
    public addNamespaces: string[];
    public defaultNamespaces: string[];
    public defaultImports: string[];
    public includeTypes: string[];
    public excludeTypes: string[];
    public treatTypesAsStrings: string[];

    public constructor(init?: Partial<NativeTypesBase>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ResponseError
{
    // @DataMember(Order=1)
    public errorCode: string;

    // @DataMember(Order=2)
    public fieldName: string;

    // @DataMember(Order=3)
    public message: string;

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<ResponseError>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ResponseStatus
{
    // @DataMember(Order=1)
    public errorCode: string;

    // @DataMember(Order=2)
    public message: string;

    // @DataMember(Order=3)
    public stackTrace: string;

    // @DataMember(Order=4)
    public errors: ResponseError[];

    // @DataMember(Order=5)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<ResponseStatus>) { (Object as any).assign(this, init); }
}

export class PISignOffBatchCreationOptions
{
    public formDefinitionIds: number[];
    public completeUnchangedForms?: boolean;
    public compareFormsAcrossPISignOffDefinitions?: boolean;

    public constructor(init?: Partial<PISignOffBatchCreationOptions>) { (Object as any).assign(this, init); }
}

export interface IUserGetSingle
{
    metadata: { [index: string]: Object; };
}

export interface ILookupGetCollectionByFormDefinitionId extends IGet
{
    formDefinitionId?: number;
}

export interface IConfigurationGetSingleById extends IGet
{
    id?: number;
}

export interface IConfigurationGetCollection extends IGet
{
    skip?: number;
    take?: number;
}

export interface IConfigurationGetSingleByCode extends IGet
{
    code: string;
}

export interface IDataGetSingleById extends IGet
{
    id?: number;
}

export interface IDataGetCollection extends IGet
{
    skip?: number;
    take?: number;
}

export enum PISignOffStatus
{
    New = 1,
    InProgress = 2,
    Complete = 3,
    Cancelled = 4,
    Pending = 5,
    Failed = 5,
}

export class AdverseEvent implements IPISignOffForm
{
    public id?: number;
    public patientId?: number;
    public piSignOffId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public status?: PISignOffStatus;
    public dataDate?: string;
    public studyEventId?: number;
    public eventCrfId?: number;
    public event: string;
    public systemsOrganClass: string;
    public aeTermId?: number;
    public aeTerm: string;
    public aeTermSpecify: string;
    public grade?: number;
    public onset?: string;
    public outcome: string;
    public resolution?: string;
    public serious: string;
    public relationshipToTreatment: string;
    public actionTakenNivolumab: string;
    public actionTakenPaclitaxel: string;
    public actionTakenCarboplatin: string;
    public annotations: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<AdverseEvent>) { (Object as any).assign(this, init); }
}

export interface IFormGetSingleByPISignOffIdAndRepeat extends IGet
{
    piSignOffId?: number;
    repeat?: number;
}

export interface IFormPostUpdateById extends IPost
{
    id?: number;
    metadata: { [index: string]: Object; };
}

export class BreastCancerRecurrence implements IPISignOffForm
{
    public id?: number;
    public patientId?: number;
    public piSignOffId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public status?: PISignOffStatus;
    public dataDate?: string;
    public studyEventId?: number;
    public eventCrfId?: number;
    public event: string;
    public type: string;
    public location: string;
    public dateOfFirstSuspicion?: string;
    public dateOfConfirmation?: string;
    public timeOnStudy?: number;
    public typeOfConfirmation: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<BreastCancerRecurrence>) { (Object as any).assign(this, init); }
}

export class BreastUltrasound implements IPISignOffForm
{
    public id?: number;
    public patientId?: number;
    public piSignOffId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public status?: PISignOffStatus;
    public dataDate?: string;
    public timepoint: string;
    public side: string;
    public location: string;
    public dimensionOne?: number;
    public dimensionTwo?: number;
    public response: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<BreastUltrasound>) { (Object as any).assign(this, init); }
}

export class CarboplatinTherapy implements IPISignOffForm
{
    public id?: number;
    public patientId?: number;
    public piSignOffId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public status?: PISignOffStatus;
    public dataDate?: string;
    public studyEventId?: number;
    public eventCrfId?: number;
    public eventName: string;
    public administered?: boolean;
    public notAdministeredReason: string;
    public date?: string;
    public expectedDoseCalvert?: number;
    public doseAdministered?: number;
    public reduced?: boolean;
    public reducedReason: string;
    public doseDelayed?: boolean;
    public doseDelayedReason: string;
    public lastDose?: boolean;
    public lastDoseReason: string;
    public lastDoseReasonSpecification: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<CarboplatinTherapy>) { (Object as any).assign(this, init); }
}

export class ConcomitantMedication implements IPISignOffForm
{
    public id?: number;
    public patientId?: number;
    public piSignOffId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public status?: PISignOffStatus;
    public dataDate?: string;
    public studyEventId?: number;
    public eventCrfId?: number;
    public event: string;
    public medication: string;
    public indication: string;
    public dateStarted?: string;
    public dateStopped?: string;
    public ongoing?: boolean;
    public annotations: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<ConcomitantMedication>) { (Object as any).assign(this, init); }
}

export class DeathReport implements IPISignOffForm
{
    public id?: number;
    public patientId?: number;
    public piSignOffId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public status?: PISignOffStatus;
    public dataDate?: string;
    public studyEventId?: number;
    public eventCrfId?: number;
    public event: string;
    public dateOfDeath?: string;
    public timeOnStudy?: number;
    public causeOfDeath: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<DeathReport>) { (Object as any).assign(this, init); }
}

export class MedicalHistory implements IPISignOffForm
{
    public id?: number;
    public patientId?: number;
    public piSignOffId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public status?: PISignOffStatus;
    public dataDate?: string;
    public medicalCondition: string;
    public currentAtRegistration: string;
    public dateOfDiagnosis: string;
    public treatmentOngoing: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<MedicalHistory>) { (Object as any).assign(this, init); }
}

export class MedicalHistoryMedication implements IPISignOffForm
{
    public id?: number;
    public patientId?: number;
    public piSignOffId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public status?: PISignOffStatus;
    public dataDate?: string;
    public medicationName: string;
    public indication: string;
    public startDate: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<MedicalHistoryMedication>) { (Object as any).assign(this, init); }
}

export class NivolumabTherapy implements IPISignOffForm
{
    public id?: number;
    public patientId?: number;
    public piSignOffId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public status?: PISignOffStatus;
    public dataDate?: string;
    public studyEventId?: number;
    public eventCrfId?: number;
    public eventName: string;
    public administered?: boolean;
    public notAdministeredReason: string;
    public date?: string;
    public doseAdministered?: number;
    public expectedDose?: number;
    public doseDelayed?: boolean;
    public doseDelayedReason: string;
    public lastDose?: boolean;
    public lastDoseReason: string;
    public lastDoseReasonSpecification: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<NivolumabTherapy>) { (Object as any).assign(this, init); }
}

export class PaclitaxelTherapy implements IPISignOffForm
{
    public id?: number;
    public patientId?: number;
    public piSignOffId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public status?: PISignOffStatus;
    public dataDate?: string;
    public studyEventId?: number;
    public eventCrfId?: number;
    public eventName: string;
    public timepoint: string;
    public administered?: boolean;
    public notAdministeredReason: string;
    public date?: string;
    public doseAdministered?: number;
    public reduced?: boolean;
    public reducedReason: string;
    public omitted?: boolean;
    public omittedReason: string;
    public lastDose?: boolean;
    public lastDoseReason: string;
    public lastDoseReasonSpecification: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<PaclitaxelTherapy>) { (Object as any).assign(this, init); }
}

export class SecondPrimaryMalignancy implements IPISignOffForm
{
    public id?: number;
    public patientId?: number;
    public piSignOffId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public status?: PISignOffStatus;
    public dataDate?: string;
    public studyEventId?: number;
    public eventCrfId?: number;
    public event: string;
    public location: string;
    public dateOfFirstSuspicion?: string;
    public dateOfConfirmation?: string;
    public timeOnStudy?: number;
    public typeOfConfirmation: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<SecondPrimaryMalignancy>) { (Object as any).assign(this, init); }
}

export class SeriousAdverseEvent implements IPISignOffForm
{
    public id?: number;
    public patientId?: number;
    public piSignOffId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public status?: PISignOffStatus;
    public dataDate?: string;
    public studyEventId?: number;
    public opmsSaeId?: number;
    public saeNumber?: number;
    public term: string;
    public onset?: string;
    public outcome: string;
    public outcomeDate?: string;
    public suspectDrugOrCause: string;
    public expectedness: string;
    public comments: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<SeriousAdverseEvent>) { (Object as any).assign(this, init); }
}

export class SlnbResult implements IPISignOffForm
{
    public id?: number;
    public patientId?: number;
    public piSignOffId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public status?: PISignOffStatus;
    public dataDate?: string;
    public nodeNumber?: number;
    public location: string;
    public tumourInvolvement: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<SlnbResult>) { (Object as any).assign(this, init); }
}

export class SurvivalFollowUp implements IPISignOffForm
{
    public id?: number;
    public patientId?: number;
    public piSignOffId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public status?: PISignOffStatus;
    public dataDate?: string;
    public studyEventId?: number;
    public eventCrfId?: number;
    public event: string;
    public visitNumber?: number;
    public dateOfVisit?: string;
    public changesObserved: string;
    public antiCancerTreatment: string;
    public dateCommenced: string;
    public dateCompleted: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<SurvivalFollowUp>) { (Object as any).assign(this, init); }
}

export enum QueryStatus
{
    Issued = 1,
    Responded = 2,
    Closed = 3,
    Cancelled = 4,
}

export class Query implements IQuery
{
    public id?: number;
    public piSignOffId?: number;
    public formDefinitionId?: number;
    public formId?: number;
    public propertyName: string;
    public parentQueryId?: number;
    public repeat?: number;
    public question: string;
    public response: string;
    public status?: QueryStatus;
    public issuedNotificationDate?: string;
    public respondedNotificationDate?: string;
    public cancelledNotificationDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<Query>) { (Object as any).assign(this, init); }
}

export class ParticipantStudySummary implements IPISignOffForm
{
    public id?: number;
    public patientId?: number;
    public piSignOffId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public status?: PISignOffStatus;
    public completeDate?: string;
    public cancelDate?: string;
    public dataDate?: string;
    public treatmentExposureNivolumab?: number;
    public treatmentExposureCarboplatin?: number;
    public treatmentExposurePaclitaxel?: number;
    public treatmentExposureSurgery: string;
    // @Ignore()
    public nivolumabTherapies: NivolumabTherapy[];

    // @Ignore()
    public paclitaxelTherapies: PaclitaxelTherapy[];

    // @Ignore()
    public carboplatinTherapies: CarboplatinTherapy[];

    public presurgeryEventCrfId?: number;
    public presurgeryUltrasoundPerformed: string;
    public presurgeryUltrasoundDate?: string;
    public presurgeryUltrasoundNotDoneReason: string;
    public presurgeryUltrasoundNewLeisons: string;
    public presurgeryUltrasoundSuspiciousNodesIdentified: string;
    public presurgeryUltrasoundSuspiciousNodeCount?: number;
    // @Ignore()
    public presurgeryBreastUltrasound: BreastUltrasound[];

    public presurgeryMammogramPerformed: string;
    public presurgeryMammogramDate?: string;
    public presurgeryMammogramNotDoneReason: string;
    public presurgeryMammogramNewLeisons: string;
    public presurgeryMammogramSuspiciousNodesIdentified: string;
    public presurgeryMammogramSuspiciousNodeCount?: number;
    public surgeryEventCrfId?: number;
    public surgeryPerformed: string;
    public surgeryNotDoneReason: string;
    public surgeryBiopsyPerformed: string;
    public surgeryBiopsyDate?: string;
    public surgeryLastTreatmentDate?: string;
    public surgeryBreastConservingSurgeryDate?: string;
    public surgeryBreastConservingSurgeryReexcisionOneDate?: string;
    public surgerySkinSparingMastectomyDate?: string;
    public surgeryMastectomyReconstructionDate?: string;
    public surgeryBreastConservingSurgeryReexcisionTwoDate?: string;
    public surgeryMastectomyDate?: string;
    public surgeryOncoplasticSurgeryDate?: string;
    public surgeryOther: string;
    public surgeryOtherDate?: string;
    public surgerySlnbPerformed: string;
    public surgerySlnbDate?: string;
    public surgerySnRemoved?: number;
    public surgeryNonSnRemoved?: number;
    // @Ignore()
    public surgerySlnbResults: SlnbResult[];

    public surgeryAxillaryDissectionPerformed: string;
    public surgeryAxillaryDissectionDate?: string;
    public surgeryAxillaryDissectionNodesRemoved?: number;
    public surgeryAxillaryDissectionPositiveNodes?: number;
    public surgeryAxillaryRtPlanned: string;
    public histopathologyEventCrfId?: number;
    public histopathologyNumberOfParaffinBlocks: string;
    public histopathologyResidualDiseaseInvasive?: boolean;
    public histopathologyResidualDiseaseInSitu?: boolean;
    public histopathologyResidualDiseaseNone?: boolean;
    public histopathologyType: string;
    public histopathologyGrade: string;
    public histopathologyLymphovascularInvasion: string;
    public histopathologyFocality: string;
    public histopathologyLargestUnilateralMeasurement?: number;
    public histopathologyMarginsClearOfInvasiveBreastCancer: string;
    public histopathologyMarginsClearOfInSitu: string;
    public histopathologyTStage: string;
    public histopathologyNStage: string;
    public histopathologyPercentageTil?: number;
    public histopathologyPdlCompleted: string;
    public histopathologyPdlAssayType: string;
    public histopathologyPdlResults: string;
    public histopathologyPdlValue?: number;
    public histopathologyRcbDimensionOne?: number;
    public histopathologyRcbDimensionTwo?: number;
    public histopathologyRcbCancerCellularity?: number;
    public histopathologyRcbInSituDisease?: number;
    public histopathologyRcbNumberPositiveNodes?: number;
    public histopathologyRcbLargestDiameter?: number;
    public histopathologyRcb?: number;
    public histopathologyRcbClass: string;
    // @Ignore()
    public onStudyAdverseEvents: AdverseEvent[];

    // @Ignore()
    public registrationAdverseEvents: MedicalHistory[];

    // @Ignore()
    public seriousAdverseEvents: SeriousAdverseEvent[];

    // @Ignore()
    public registrationConcomitantMedications: MedicalHistoryMedication[];

    // @Ignore()
    public onStudyConcomitantMedications: ConcomitantMedication[];

    // @Ignore()
    public survivalFollowUps: SurvivalFollowUp[];

    // @Ignore()
    public breastCancerRecurrences: BreastCancerRecurrence[];

    // @Ignore()
    public secondPrimaryMalignancies: SecondPrimaryMalignancy[];

    // @Ignore()
    public deathReports: DeathReport[];

    // @Ignore()
    public queries: Query[];

    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<ParticipantStudySummary>) { (Object as any).assign(this, init); }
}

export class MasterGroup implements IMasterGroup
{
    public id?: number;
    public code: string;
    public name: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<MasterGroup>) { (Object as any).assign(this, init); }
}

export class CoordinatingGroup implements ICoordinatingGroup
{
    public id?: number;
    public masterGroupId?: number;
    public code: string;
    public name: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<CoordinatingGroup>) { (Object as any).assign(this, init); }
}

export class Country implements ICountry
{
    public id?: number;
    public code: string;
    public name: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<Country>) { (Object as any).assign(this, init); }
}

export class Institution implements IInstitution
{
    public id?: number;
    public countryId?: number;
    public masterGroupId?: number;
    public coordinatingGroupId?: number;
    public code: string;
    public name: string;
    public enabled?: boolean;
    public activatedDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<Institution>) { (Object as any).assign(this, init); }
}

export class PISignOffPatient implements IPISignOffPatient
{
    public id?: number;
    public institutionId?: number;
    public studyNumber: string;
    public treatmentArm: string;
    public investigatorIds: number[];
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<PISignOffPatient>) { (Object as any).assign(this, init); }
}

export interface IPatientGetSingleByStudyNumber
{
    studyNumber: string;
}

export class PISignOffRequestContext
{
    public masterGroupId?: number;
    public coordinatingGroupId?: number;
    public countryId?: number;
    public institutionId?: number;
    public patientId?: number;
    public investigatorIds: number[];
    public piSignOffId?: number;
    public investigatorId?: number;
    public formDefinitionId?: number;
    public formId?: number;
    public fileId?: number;
    public queryId?: number;

    public constructor(init?: Partial<PISignOffRequestContext>) { (Object as any).assign(this, init); }
}

export enum PISignOffPermission
{
    Access = 1,
    ViewMasterGroup = 2,
    ViewCoordinatingGroup = 3,
    ViewCountry = 4,
    ViewInstitution = 5,
    ViewPatient = 6,
    ViewPISignOff = 7,
    ViewPISignOffForm = 8,
    ViewQuery = 9,
    CreatePISignOff = 10,
    CompletePISignOff = 11,
    CancelPISignOff = 12,
    CompletePISignOffForm = 13,
    IssueQuery = 14,
    RespondToQuery = 15,
    CloseQuery = 16,
    CancelQuery = 17,
    ViewAllPatients = 18,
    UpdatePISignOff = 19,
}

export enum InvestigatorType
{
    PrincipalInvestigator = 1,
    CoInvestigator = 2,
}

export class InvestigatorContext
{
    public active?: boolean;
    public type?: InvestigatorType;

    public constructor(init?: Partial<InvestigatorContext>) { (Object as any).assign(this, init); }
}

export class Investigator implements IInvestigator
{
    public id?: number;
    public onTrial: InvestigatorContext[];
    public masterGroups: { [index: number]: InvestigatorContext[]; };
    public coordinatingGroups: { [index: number]: InvestigatorContext[]; };
    public institutions: { [index: number]: InvestigatorContext[]; };
    public usernames: string[];
    public name: string;
    public email: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<Investigator>) { (Object as any).assign(this, init); }
}

export class PISignOff implements IPISignOff
{
    public id?: number;
    public patientId?: number;
    public piSignOffBatchId?: number;
    public formCount?: number;
    public investigatorId?: number;
    public status?: PISignOffStatus;
    public dataDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public notificationDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<PISignOff>) { (Object as any).assign(this, init); }
}

export interface IPISignOffGetCollectionByPISignOffBatchId extends IGet
{
    piSignOffBatchId?: number;
}

export interface IPISignOffGetSingleByPISignOffBatchIdAndPatientId extends IGet
{
    piSignOffBatchId?: number;
    patientId?: number;
}

export class PISignOffUpdateOptions extends PISignOffBatchCreationOptions
{
    public addNewForms?: boolean;
    public deleteOldForms?: boolean;

    public constructor(init?: Partial<PISignOffUpdateOptions>) { super(init); (Object as any).assign(this, init); }
}

export interface IPISignOffPostUpdateSignOff extends IPost
{
    metadata: { [index: string]: Object; };
    piSignOffId?: number;
    options: PISignOffUpdateOptions;
}

export interface IPISignOffPostFormValidationCollectionById extends IPost
{
    id?: number;
    metadata: { [index: string]: Object; };
}

export class PISignOffBatch implements IPISignOffBatch
{
    public id?: number;
    public piSignOffDefinitionId?: number;
    public repeat?: number;
    public status?: PISignOffStatus;
    public count?: number;
    public created?: number;
    public completeDate?: string;
    public cancelDate?: string;
    public formDefinitionIds: number[];
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<PISignOffBatch>) { (Object as any).assign(this, init); }
}

export interface IPISignOffBatchGetSingleByPISignOffDefinitionIdAndRepeat extends IGet
{
    piSignOffDefinitionId?: number;
    repeat?: number;
}

export interface IQueryGetCollectionByPISignOffId extends IGet
{
    piSignOffId?: number;
}

export enum UpdatedFormPropertyType
{
    Normal = 1,
    DataChange = 2,
}

export class UpdatedFormProperty implements IUpdatedFormProperty
{
    public id?: number;
    public formDefinitionId?: number;
    public formId?: number;
    public propertyPath: string;
    public type?: UpdatedFormPropertyType;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<UpdatedFormProperty>) { (Object as any).assign(this, init); }
}

export interface IUpdatedFormPropertyGetCollectionByFormDefinitionIdAndFormId extends IGet
{
    formDefinitionId?: number;
    formId?: number;
}

export class EmailAddress
{
    public displayName: string;
    public address: string;

    public constructor(init?: Partial<EmailAddress>) { (Object as any).assign(this, init); }
}

export class IdentityServerExceptionInfo
{
    public message: string;
    public stackTrace: string;
    public innerException: IdentityServerExceptionInfo;

    public constructor(init?: Partial<IdentityServerExceptionInfo>) { (Object as any).assign(this, init); }
}

export class IdentityServerDiagnostic
{
    public test: string;
    public pass: boolean;
    public exception: IdentityServerExceptionInfo;
    public values: { [index: string]: Object; };

    public constructor(init?: Partial<IdentityServerDiagnostic>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UserApiKey
{
    // @DataMember(Order=1)
    public key: string;

    // @DataMember(Order=2)
    public keyType: string;

    // @DataMember(Order=3)
    public expiryDate?: string;

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<UserApiKey>) { (Object as any).assign(this, init); }
}

export interface IFileUploadTemporary
{
    guid: string;
    name: string;
    extension: string;
    fileSize?: number;
    contentType: string;
    image: boolean;
}

export class User implements IUserDto
{
    public userId: string;
    public userName: string;
    public displayName: string;
    public sessionId: string;

    public constructor(init?: Partial<User>) { (Object as any).assign(this, init); }
}

export class LookupItem
{
    public id?: number;
    public value: string;
    public order?: number;

    public constructor(init?: Partial<LookupItem>) { (Object as any).assign(this, init); }
}

export class Lookup
{
    public propertyName: string;
    public items: LookupItem[];

    public constructor(init?: Partial<Lookup>) { (Object as any).assign(this, init); }
}

export class FormMetadata
{
    public formDefinitionId?: number;
    public formDefinitionCode: string;
    public name: string;
    public propertyName: string;
    public title: string;
    public instructions: string;

    public constructor(init?: Partial<FormMetadata>) { (Object as any).assign(this, init); }
}

export class FormType implements IFormType
{
    public id?: number;
    public code: string;
    public name: string;

    public constructor(init?: Partial<FormType>) { (Object as any).assign(this, init); }
}

export enum ValidationErrorType
{
    Warning = 1,
    Low = 2,
    Normal = 3,
    High = 4,
    Critical = 5,
}

export class FormDefinitionPropertyErrorMessage implements IFormDefinitionPropertyErrorMessage
{
    public id?: number;
    // @References("typeof(NewtonGreen.ServiceStack.Forms.Models.Configuration.FormDefinitionProperty)")
    public formDefinitionPropertyId?: number;

    public errorCode: string;
    public message: string;
    public detailedMessage: string;
    public type?: ValidationErrorType;

    public constructor(init?: Partial<FormDefinitionPropertyErrorMessage>) { (Object as any).assign(this, init); }
}

export class FormDefinitionProperty
{
    public id?: number;
    // @References("typeof(NewtonGreen.ServiceStack.Forms.Models.Configuration.FormDefinition)")
    public formDefinitionId?: number;

    public propertyName: string;
    public label: string;
    public detailedLabel: string;
    public errorMessages: FormDefinitionPropertyErrorMessage[];

    public constructor(init?: Partial<FormDefinitionProperty>) { (Object as any).assign(this, init); }
}

export class FormDefinition
{
    public id?: number;
    public code: string;
    public type?: number;
    public name: string;
    public instructions: string;
    public patientInstructions: string;
    public properties: FormDefinitionProperty[];
    // @Ignore()
    public subformProperties: { [index: string]: FormDefinitionProperty[]; };

    public constructor(init?: Partial<FormDefinition>) { (Object as any).assign(this, init); }
}

export enum ValidationResultType
{
    Valid = 1,
    Low = 2,
    Normal = 3,
    High = 4,
    Critical = 5,
}

export class ValidationError implements IValidationError
{
    public property: string;
    public message: string;
    public detailedMessage: string;
    public code: string;
    public ruleSet: string;
    public type?: ValidationErrorType;

    public constructor(init?: Partial<ValidationError>) { (Object as any).assign(this, init); }
}

export class ValidationResult
{
    public result: ValidationResultType;
    public errors: ValidationError[];
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<ValidationResult>) { (Object as any).assign(this, init); }
}

export class PISignOffDefinition implements IPISignOffDefinition
{
    public id?: number;
    public code: string;
    public name: string;
    public availableFormDefinitionIds: number[];
    public defaultFormDefinitionIds: number[];

    public constructor(init?: Partial<PISignOffDefinition>) { (Object as any).assign(this, init); }
}

export interface IPISignOffForm extends IForm, IHasPatientId
{
    piSignOffId?: number;
    status?: PISignOffStatus;
    dataDate?: string;
}

export interface IForm extends IDataModel, IHasParentFormDefinitionId, IHasParentFormId
{
    repeat?: number;
}

export interface IDataModel extends IModel, IHasAudit
{
}

export interface IModel
{
    id?: number;
}

export interface IHasAudit
{
    enteredDate?: string;
    enteredBy: string;
    modifiedDate?: string;
    modifiedBy: string;
}

export interface IHasParentFormDefinitionId
{
    parentFormDefinitionId?: number;
}

export interface IHasParentFormId
{
    parentFormId?: number;
}

export interface IHasPatientId
{
    patientId?: number;
}

export interface IMasterGroup extends IDataModel, IHasCode
{
    name: string;
}

export interface IHasCode
{
    code: string;
}

export interface ICoordinatingGroup extends IDataModel, IHasMasterGroupId, IHasCode
{
    name: string;
}

export interface IHasMasterGroupId
{
    masterGroupId?: number;
}

export interface ICountry extends IDataModel, IHasCode
{
    name: string;
}

export interface IInstitution extends IDataModel, IHasMasterGroupId, IHasCoordinatingGroupId, IHasCountryId, IHasCode
{
    name: string;
    enabled?: boolean;
    activatedDate?: string;
}

export interface IHasCoordinatingGroupId
{
    coordinatingGroupId?: number;
}

export interface IHasCountryId
{
    countryId?: number;
}

export interface IPISignOffPatient extends IPatient
{
    treatmentArm: string;
    investigatorIds: number[];
}

export interface IPatient extends IDataModel, IHasInstitutionId
{
    studyNumber: string;
}

export interface IHasInstitutionId
{
    institutionId?: number;
}

export interface IInvestigator extends IDataModel
{
    name: string;
    usernames: string[];
    email: string;
    onTrial: InvestigatorContext[];
    masterGroups: { [index: number]: InvestigatorContext[]; };
    coordinatingGroups: { [index: number]: InvestigatorContext[]; };
    institutions: { [index: number]: InvestigatorContext[]; };
}

export interface IPISignOff extends IDataModel
{
    patientId?: number;
    piSignOffBatchId?: number;
    formCount?: number;
    investigatorId?: number;
    status?: PISignOffStatus;
    completeDate?: string;
    cancelDate?: string;
    dataDate?: string;
}

export interface IPISignOffBatch extends IDataModel
{
    piSignOffDefinitionId?: number;
    status?: PISignOffStatus;
    repeat?: number;
    count?: number;
    created?: number;
    formDefinitionIds: number[];
    completeDate?: string;
    cancelDate?: string;
}

export interface IQuery extends IDataModel, IHasFormDefinitionId, IHasFormId
{
    piSignOffId?: number;
    propertyName: string;
    parentQueryId?: number;
    repeat?: number;
    question: string;
    response: string;
    status?: QueryStatus;
}

export interface IHasFormDefinitionId
{
    formDefinitionId?: number;
}

export interface IHasFormId
{
    formId?: number;
}

export interface IUpdatedFormProperty extends IDataModel
{
    formDefinitionId?: number;
    formId?: number;
    propertyPath: string;
    type?: UpdatedFormPropertyType;
}

export interface IUserDto
{
    userId: string;
    userName: string;
    displayName: string;
    sessionId: string;
}

export interface ILookupItem
{
    id: Object;
    value: Object;
    order: Object;
}

export interface ILookup
{
    propertyName: string;
    items: ILookupItem[];
}

export interface IFormType extends IConfigurationModel, IHasCode
{
    name: string;
}

export interface IConfigurationModel extends IModel
{
}

export interface IFormDefinitionPropertyErrorMessage extends IConfigurationModel, IHasFormDefinitionPropertyId
{
    errorCode: string;
    message: string;
    detailedMessage: string;
    type?: ValidationErrorType;
}

export interface IFormDefinitionProperty extends IConfigurationModel, IHasFormDefinitionId
{
    propertyName: string;
    label: string;
    detailedLabel: string;
    errorMessages: IFormDefinitionPropertyErrorMessage[];
}

export interface IFormDefinition extends IConfigurationModel, IHasCode
{
    type?: number;
    name: string;
    instructions: string;
    properties: IFormDefinitionProperty[];
    subformProperties: { [index: string]: IFormDefinitionProperty[]; };
}

export interface IValidationError
{
    property: string;
    message: string;
    detailedMessage: string;
    code: string;
    type?: ValidationErrorType;
}

export interface IValidationResult
{
    metadata: { [index: string]: Object; };
    result: ValidationResultType;
    errors: IValidationError[];
}

export interface IPISignOffDefinition extends IConfigurationModel, IHasCode
{
    name: string;
    availableFormDefinitionIds: number[];
    defaultFormDefinitionIds: number[];
}

export interface IHasFormDefinitionPropertyId
{
    formDefinitionPropertyId?: number;
}

export class CreatePISignOffResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<CreatePISignOffResponse>) { (Object as any).assign(this, init); }
}

export class SendPISignOffEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;
    public emailNotRequired: boolean;

    public constructor(init?: Partial<SendPISignOffEmailResponse>) { (Object as any).assign(this, init); }
}

export class QueueEmailJobsResponse
{
    public responseStatus: ResponseStatus;
    public count?: number;

    public constructor(init?: Partial<QueueEmailJobsResponse>) { (Object as any).assign(this, init); }
}

export class GetPatientsByIdsAsyncResponse
{
    public responseStatus: ResponseStatus;
    public patients: PISignOffPatient[];

    public constructor(init?: Partial<GetPatientsByIdsAsyncResponse>) { (Object as any).assign(this, init); }
}

export class IdentityServerDiagnosticsResponse
{
    public responseStatus: ResponseStatus;
    public diagnostics: IdentityServerDiagnostic[];

    public constructor(init?: Partial<IdentityServerDiagnosticsResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AuthenticateResponse implements IHasSessionId, IHasBearerToken
{
    // @DataMember(Order=1)
    public userId: string;

    // @DataMember(Order=2)
    public sessionId: string;

    // @DataMember(Order=3)
    public userName: string;

    // @DataMember(Order=4)
    public displayName: string;

    // @DataMember(Order=5)
    public referrerUrl: string;

    // @DataMember(Order=6)
    public bearerToken: string;

    // @DataMember(Order=7)
    public refreshToken: string;

    // @DataMember(Order=8)
    public profileUrl: string;

    // @DataMember(Order=9)
    public roles: string[];

    // @DataMember(Order=10)
    public permissions: string[];

    // @DataMember(Order=11)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=12)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<AuthenticateResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AssignRolesResponse
{
    // @DataMember(Order=1)
    public allRoles: string[];

    // @DataMember(Order=2)
    public allPermissions: string[];

    // @DataMember(Order=3)
    public meta: { [index: string]: string; };

    // @DataMember(Order=4)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<AssignRolesResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UnAssignRolesResponse
{
    // @DataMember(Order=1)
    public allRoles: string[];

    // @DataMember(Order=2)
    public allPermissions: string[];

    // @DataMember(Order=3)
    public meta: { [index: string]: string; };

    // @DataMember(Order=4)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<UnAssignRolesResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class GetApiKeysResponse
{
    // @DataMember(Order=1)
    public results: UserApiKey[];

    // @DataMember(Order=2)
    public meta: { [index: string]: string; };

    // @DataMember(Order=3)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetApiKeysResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class RegenerateApiKeysResponse
{
    // @DataMember(Order=1)
    public results: UserApiKey[];

    // @DataMember(Order=2)
    public meta: { [index: string]: string; };

    // @DataMember(Order=3)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<RegenerateApiKeysResponse>) { (Object as any).assign(this, init); }
}

export class UploadResponse
{
    public responseStatus: ResponseStatus;
    public upload: IFileUploadTemporary;

    public constructor(init?: Partial<UploadResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UserSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved user.
    */
    // @DataMember(Order=2)
    public user: User;

    public constructor(init?: Partial<UserSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class LookupCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved lookups.
    */
    // @DataMember(Order=2)
    public lookups: Lookup[];

    public constructor(init?: Partial<LookupCollectionResponse>) { (Object as any).assign(this, init); }
}

export class GetFormMetadataResponse
{
    public responseStatus: ResponseStatus;
    public formMetadata: FormMetadata[];

    public constructor(init?: Partial<GetFormMetadataResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class FormTypeSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved form type.
    */
    // @DataMember(Order=2)
    public configuration: FormType;

    public constructor(init?: Partial<FormTypeSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class FormTypeCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved form types.
    */
    // @DataMember(Order=2)
    public configuration: FormType[];

    public constructor(init?: Partial<FormTypeCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class FormDefinitionSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved form definition.
    */
    // @DataMember(Order=2)
    public configuration: FormDefinition;

    public constructor(init?: Partial<FormDefinitionSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class FormDefinitionCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved form definitions.
    */
    // @DataMember(Order=2)
    public configuration: FormDefinition[];

    public constructor(init?: Partial<FormDefinitionCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AdverseEventSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved adverse event.
    */
    // @DataMember(Order=2)
    public data: AdverseEvent;

    public constructor(init?: Partial<AdverseEventSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AdverseEventCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved adverse events.
    */
    // @DataMember(Order=2)
    public data: AdverseEvent[];

    public constructor(init?: Partial<AdverseEventCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AdverseEventValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<AdverseEventValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class BreastCancerRecurrenceSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved breast cancer recurrence.
    */
    // @DataMember(Order=2)
    public data: BreastCancerRecurrence;

    public constructor(init?: Partial<BreastCancerRecurrenceSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class BreastCancerRecurrenceCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved breast cancer recurrences.
    */
    // @DataMember(Order=2)
    public data: BreastCancerRecurrence[];

    public constructor(init?: Partial<BreastCancerRecurrenceCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class BreastCancerRecurrenceValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<BreastCancerRecurrenceValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class BreastUltrasoundSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved breast ultrasound.
    */
    // @DataMember(Order=2)
    public data: BreastUltrasound;

    public constructor(init?: Partial<BreastUltrasoundSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class BreastUltrasoundCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved breast ultrasounds.
    */
    // @DataMember(Order=2)
    public data: BreastUltrasound[];

    public constructor(init?: Partial<BreastUltrasoundCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class BreastUltrasoundValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<BreastUltrasoundValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CarboplatinTherapySingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved carboplatin therapy.
    */
    // @DataMember(Order=2)
    public data: CarboplatinTherapy;

    public constructor(init?: Partial<CarboplatinTherapySingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CarboplatinTherapyCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved carboplatin therapys.
    */
    // @DataMember(Order=2)
    public data: CarboplatinTherapy[];

    public constructor(init?: Partial<CarboplatinTherapyCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CarboplatinTherapyValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<CarboplatinTherapyValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ConcomitantMedicationSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved concomitant medication.
    */
    // @DataMember(Order=2)
    public data: ConcomitantMedication;

    public constructor(init?: Partial<ConcomitantMedicationSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ConcomitantMedicationCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved concomitant medications.
    */
    // @DataMember(Order=2)
    public data: ConcomitantMedication[];

    public constructor(init?: Partial<ConcomitantMedicationCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ConcomitantMedicationValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<ConcomitantMedicationValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class DeathReportSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved death report.
    */
    // @DataMember(Order=2)
    public data: DeathReport;

    public constructor(init?: Partial<DeathReportSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class DeathReportCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved death reports.
    */
    // @DataMember(Order=2)
    public data: DeathReport[];

    public constructor(init?: Partial<DeathReportCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class DeathReportValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<DeathReportValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MedicalHistorySingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved medical history.
    */
    // @DataMember(Order=2)
    public data: MedicalHistory;

    public constructor(init?: Partial<MedicalHistorySingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MedicalHistoryCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved medical historys.
    */
    // @DataMember(Order=2)
    public data: MedicalHistory[];

    public constructor(init?: Partial<MedicalHistoryCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MedicalHistoryValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<MedicalHistoryValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MedicalHistoryMedicationSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved medical history medication.
    */
    // @DataMember(Order=2)
    public data: MedicalHistoryMedication;

    public constructor(init?: Partial<MedicalHistoryMedicationSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MedicalHistoryMedicationCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved medical history medications.
    */
    // @DataMember(Order=2)
    public data: MedicalHistoryMedication[];

    public constructor(init?: Partial<MedicalHistoryMedicationCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MedicalHistoryMedicationValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<MedicalHistoryMedicationValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class NivolumabTherapySingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved nivolumab therapy.
    */
    // @DataMember(Order=2)
    public data: NivolumabTherapy;

    public constructor(init?: Partial<NivolumabTherapySingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class NivolumabTherapyCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved nivolumab therapys.
    */
    // @DataMember(Order=2)
    public data: NivolumabTherapy[];

    public constructor(init?: Partial<NivolumabTherapyCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class NivolumabTherapyValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<NivolumabTherapyValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PaclitaxelTherapySingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved paclitaxel therapy.
    */
    // @DataMember(Order=2)
    public data: PaclitaxelTherapy;

    public constructor(init?: Partial<PaclitaxelTherapySingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PaclitaxelTherapyCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved paclitaxel therapys.
    */
    // @DataMember(Order=2)
    public data: PaclitaxelTherapy[];

    public constructor(init?: Partial<PaclitaxelTherapyCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PaclitaxelTherapyValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<PaclitaxelTherapyValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SecondPrimaryMalignancySingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved second primary malignancy.
    */
    // @DataMember(Order=2)
    public data: SecondPrimaryMalignancy;

    public constructor(init?: Partial<SecondPrimaryMalignancySingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SecondPrimaryMalignancyCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved second primary malignancys.
    */
    // @DataMember(Order=2)
    public data: SecondPrimaryMalignancy[];

    public constructor(init?: Partial<SecondPrimaryMalignancyCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SecondPrimaryMalignancyValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<SecondPrimaryMalignancyValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SeriousAdverseEventSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved serious adverse event.
    */
    // @DataMember(Order=2)
    public data: SeriousAdverseEvent;

    public constructor(init?: Partial<SeriousAdverseEventSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SeriousAdverseEventCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved serious adverse events.
    */
    // @DataMember(Order=2)
    public data: SeriousAdverseEvent[];

    public constructor(init?: Partial<SeriousAdverseEventCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SeriousAdverseEventValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<SeriousAdverseEventValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SlnbResultSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved slnb result.
    */
    // @DataMember(Order=2)
    public data: SlnbResult;

    public constructor(init?: Partial<SlnbResultSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SlnbResultCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved slnb results.
    */
    // @DataMember(Order=2)
    public data: SlnbResult[];

    public constructor(init?: Partial<SlnbResultCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SlnbResultValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<SlnbResultValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SurvivalFollowUpSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved survival follow up.
    */
    // @DataMember(Order=2)
    public data: SurvivalFollowUp;

    public constructor(init?: Partial<SurvivalFollowUpSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SurvivalFollowUpCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved survival follow ups.
    */
    // @DataMember(Order=2)
    public data: SurvivalFollowUp[];

    public constructor(init?: Partial<SurvivalFollowUpCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SurvivalFollowUpValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<SurvivalFollowUpValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ParticipantStudySummarySingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved participant study summary.
    */
    // @DataMember(Order=2)
    public data: ParticipantStudySummary;

    public constructor(init?: Partial<ParticipantStudySummarySingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ParticipantStudySummaryCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved participant study summarys.
    */
    // @DataMember(Order=2)
    public data: ParticipantStudySummary[];

    public constructor(init?: Partial<ParticipantStudySummaryCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ParticipantStudySummaryValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<ParticipantStudySummaryValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MasterGroupSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved master group.
    */
    // @DataMember(Order=2)
    public data: MasterGroup;

    public constructor(init?: Partial<MasterGroupSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MasterGroupCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved master groups.
    */
    // @DataMember(Order=2)
    public data: MasterGroup[];

    public constructor(init?: Partial<MasterGroupCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MasterGroupValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<MasterGroupValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CoordinatingGroupSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved coordinating group.
    */
    // @DataMember(Order=2)
    public data: CoordinatingGroup;

    public constructor(init?: Partial<CoordinatingGroupSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CoordinatingGroupCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved coordinating groups.
    */
    // @DataMember(Order=2)
    public data: CoordinatingGroup[];

    public constructor(init?: Partial<CoordinatingGroupCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CoordinatingGroupValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<CoordinatingGroupValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CountrySingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved country.
    */
    // @DataMember(Order=2)
    public data: Country;

    public constructor(init?: Partial<CountrySingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CountryCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved countrys.
    */
    // @DataMember(Order=2)
    public data: Country[];

    public constructor(init?: Partial<CountryCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CountryValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<CountryValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class InstitutionSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved institution.
    */
    // @DataMember(Order=2)
    public data: Institution;

    public constructor(init?: Partial<InstitutionSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class InstitutionCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved institutions.
    */
    // @DataMember(Order=2)
    public data: Institution[];

    public constructor(init?: Partial<InstitutionCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class InstitutionValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<InstitutionValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PatientSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved patient.
    */
    // @DataMember(Order=2)
    public data: PISignOffPatient;

    public constructor(init?: Partial<PatientSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PatientCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved patients.
    */
    // @DataMember(Order=2)
    public data: PISignOffPatient[];

    public constructor(init?: Partial<PatientCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PatientValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<PatientValidationResponse>) { (Object as any).assign(this, init); }
}

export class PostHasPermissionsResponse
{
    public responseStatus: ResponseStatus;
    public permissions: { [index: string]: boolean; };

    public constructor(init?: Partial<PostHasPermissionsResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PISignOffDefinitionSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved pi sign off definition.
    */
    // @DataMember(Order=2)
    public configuration: PISignOffDefinition;

    public constructor(init?: Partial<PISignOffDefinitionSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PISignOffDefinitionCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved pi sign off definitions.
    */
    // @DataMember(Order=2)
    public configuration: PISignOffDefinition[];

    public constructor(init?: Partial<PISignOffDefinitionCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class InvestigatorSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved investigator.
    */
    // @DataMember(Order=2)
    public data: Investigator;

    public constructor(init?: Partial<InvestigatorSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class InvestigatorCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved investigators.
    */
    // @DataMember(Order=2)
    public data: Investigator[];

    public constructor(init?: Partial<InvestigatorCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class InvestigatorValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<InvestigatorValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PISignOffSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved pi sign off.
    */
    // @DataMember(Order=2)
    public data: PISignOff;

    public constructor(init?: Partial<PISignOffSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PISignOffCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved pi sign offs.
    */
    // @DataMember(Order=2)
    public data: PISignOff[];

    public constructor(init?: Partial<PISignOffCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PISignOffValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<PISignOffValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PISignOffValidationCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult[];

    public constructor(init?: Partial<PISignOffValidationCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PISignOffBatchSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved pi sign off batch.
    */
    // @DataMember(Order=2)
    public data: PISignOffBatch;

    public constructor(init?: Partial<PISignOffBatchSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PISignOffBatchCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved pi sign off batchs.
    */
    // @DataMember(Order=2)
    public data: PISignOffBatch[];

    public constructor(init?: Partial<PISignOffBatchCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PISignOffBatchValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<PISignOffBatchValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PISignOffBatchCreationSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved pi sign off batch.
    */
    // @DataMember(Order=2)
    public data: PISignOffBatch;

    public constructor(init?: Partial<PISignOffBatchCreationSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class QuerySingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved query.
    */
    // @DataMember(Order=2)
    public data: Query;

    public constructor(init?: Partial<QuerySingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class QueryCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved querys.
    */
    // @DataMember(Order=2)
    public data: Query[];

    public constructor(init?: Partial<QueryCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class QueryValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<QueryValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UpdatedFormPropertySingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved updatedformproperty.
    */
    // @DataMember(Order=2)
    public data: UpdatedFormProperty;

    public constructor(init?: Partial<UpdatedFormPropertySingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UpdatedFormPropertyCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved updatedformpropertys.
    */
    // @DataMember(Order=2)
    public data: UpdatedFormProperty[];

    public constructor(init?: Partial<UpdatedFormPropertyCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UpdatedFormPropertyValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<UpdatedFormPropertyValidationResponse>) { (Object as any).assign(this, init); }
}

// @Route("/test")
export class Test extends NativeTypesBase implements IGet
{

    public constructor(init?: Partial<Test>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'Test'; }
    public getMethod() { return 'GET'; }
    public createResponse() {}
}

export class CreatePISignOff implements IReturn<CreatePISignOffResponse>
{
    public responseStatus: ResponseStatus;
    public piSignOffBatchId?: number;
    public patientId?: number;
    public options: PISignOffBatchCreationOptions;

    public constructor(init?: Partial<CreatePISignOff>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CreatePISignOff'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CreatePISignOffResponse(); }
}

export class SendPISignOffCompleteEmail implements IReturn<SendPISignOffEmailResponse>
{
    public piSignOffId?: number;

    public constructor(init?: Partial<SendPISignOffCompleteEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendPISignOffCompleteEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendPISignOffEmailResponse(); }
}

export class QueueAvailablePISignOffEmails implements IReturn<QueueEmailJobsResponse>
{

    public constructor(init?: Partial<QueueAvailablePISignOffEmails>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QueueAvailablePISignOffEmails'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new QueueEmailJobsResponse(); }
}

export class SendPISignOffsAvailableEmails implements IReturn<SendPISignOffEmailResponse>
{
    public investigatorId?: number;

    public constructor(init?: Partial<SendPISignOffsAvailableEmails>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendPISignOffsAvailableEmails'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendPISignOffEmailResponse(); }
}

export class QueueInstitutionQuerySummaryEmails implements IReturn<QueueEmailJobsResponse>
{

    public constructor(init?: Partial<QueueInstitutionQuerySummaryEmails>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QueueInstitutionQuerySummaryEmails'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new QueueEmailJobsResponse(); }
}

// @Route("/emails/institution-query-summary/{InstitutionId}")
export class SendInstitutionQuerySummaryEmail implements IReturn<SendPISignOffEmailResponse>
{
    public institutionId?: number;

    public constructor(init?: Partial<SendInstitutionQuerySummaryEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendInstitutionQuerySummaryEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendPISignOffEmailResponse(); }
}

export class QueueInvestigatorQuerySummaryEmails implements IReturn<QueueEmailJobsResponse>
{

    public constructor(init?: Partial<QueueInvestigatorQuerySummaryEmails>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QueueInvestigatorQuerySummaryEmails'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new QueueEmailJobsResponse(); }
}

// @Route("/emails/investigator-query-summary/{InvestigatorId}")
export class SendInvestigatorQuerySummaryEmail implements IReturn<SendPISignOffEmailResponse>
{
    public investigatorId?: number;

    public constructor(init?: Partial<SendInvestigatorQuerySummaryEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendInvestigatorQuerySummaryEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendPISignOffEmailResponse(); }
}

export class SendNewQueryAddedEmail implements IReturn<SendPISignOffEmailResponse>
{
    public queryId?: number;

    public constructor(init?: Partial<SendNewQueryAddedEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendNewQueryAddedEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendPISignOffEmailResponse(); }
}

export class SendNewQueryAsResponseEmail implements IReturn<SendPISignOffEmailResponse>
{
    public queryId?: number;

    public constructor(init?: Partial<SendNewQueryAsResponseEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendNewQueryAsResponseEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendPISignOffEmailResponse(); }
}

export class SendQueryClosedEarlyEmail implements IReturn<SendPISignOffEmailResponse>
{
    public queryId?: number;

    public constructor(init?: Partial<SendQueryClosedEarlyEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendQueryClosedEarlyEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendPISignOffEmailResponse(); }
}

export class SendQueryResponseEmail implements IReturn<SendPISignOffEmailResponse>
{
    public queryId?: number;

    public constructor(init?: Partial<SendQueryResponseEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendQueryResponseEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendPISignOffEmailResponse(); }
}

// @Route("/pi-signoff/patients-by-ids", "GET")
export class GetPatientsByIdsAsync implements IReturn<GetPatientsByIdsAsyncResponse>, IGet
{
    public ids: number[];

    public constructor(init?: Partial<GetPatientsByIdsAsync>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetPatientsByIdsAsync'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetPatientsByIdsAsyncResponse(); }
}

// @Route("/neonapi/report/queries")
export class QueryReport
{
    public institutionId?: number;
    public patientId?: number;
    public piSignOffId?: number;

    public constructor(init?: Partial<QueryReport>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QueryReport'; }
    public getMethod() { return 'GET'; }
    public createResponse() {}
}

// @Route("/neonapi/export/queries/new")
export class NewQueryExport
{
    public institutionId?: number;
    public patientId?: number;
    public piSignOffId?: number;

    public constructor(init?: Partial<NewQueryExport>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NewQueryExport'; }
    public getMethod() { return 'GET'; }
    public createResponse() {}
}

// @Route("/neonapi/export/queries/more-information")
export class MoreInformationQueryExport
{
    public institutionId?: number;
    public patientId?: number;
    public piSignOffId?: number;

    public constructor(init?: Partial<MoreInformationQueryExport>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MoreInformationQueryExport'; }
    public getMethod() { return 'GET'; }
    public createResponse() {}
}

// @Route("/neonapi/export/queries/cancelled")
export class CancelledQueryExport
{
    public institutionId?: number;
    public patientId?: number;
    public piSignOffId?: number;

    public constructor(init?: Partial<CancelledQueryExport>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CancelledQueryExport'; }
    public getMethod() { return 'GET'; }
    public createResponse() {}
}

// @Route("/identity-server/authorized")
export class IdentityServerAuthorized implements IReturn<IdentityServerDiagnosticsResponse>
{

    public constructor(init?: Partial<IdentityServerAuthorized>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'IdentityServerAuthorized'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new IdentityServerDiagnosticsResponse(); }
}

// @Route("/identity-server/test")
export class IdentityServerDiagnostics implements IReturn<IdentityServerDiagnosticsResponse>
{
    public code: string;
    public state: string;
    public error: string;

    public constructor(init?: Partial<IdentityServerDiagnostics>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'IdentityServerDiagnostics'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new IdentityServerDiagnosticsResponse(); }
}

/**
* Sign In
*/
// @Route("/auth", "OPTIONS,GET,POST,DELETE")
// @Route("/auth/{provider}", "OPTIONS,GET,POST,DELETE")
// @Api(Description="Sign In")
// @DataContract
export class Authenticate implements IReturn<AuthenticateResponse>, IPost
{
    /**
    * AuthProvider, e.g. credentials
    */
    // @DataMember(Order=1)
    public provider: string;

    // @DataMember(Order=2)
    public state: string;

    // @DataMember(Order=3)
    public oauth_token: string;

    // @DataMember(Order=4)
    public oauth_verifier: string;

    // @DataMember(Order=5)
    public userName: string;

    // @DataMember(Order=6)
    public password: string;

    // @DataMember(Order=7)
    public rememberMe?: boolean;

    // @DataMember(Order=9)
    public errorView: string;

    // @DataMember(Order=10)
    public nonce: string;

    // @DataMember(Order=11)
    public uri: string;

    // @DataMember(Order=12)
    public response: string;

    // @DataMember(Order=13)
    public qop: string;

    // @DataMember(Order=14)
    public nc: string;

    // @DataMember(Order=15)
    public cnonce: string;

    // @DataMember(Order=17)
    public accessToken: string;

    // @DataMember(Order=18)
    public accessTokenSecret: string;

    // @DataMember(Order=19)
    public scope: string;

    // @DataMember(Order=20)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<Authenticate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'Authenticate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AuthenticateResponse(); }
}

// @Route("/assignroles", "POST")
// @DataContract
export class AssignRoles implements IReturn<AssignRolesResponse>, IPost
{
    // @DataMember(Order=1)
    public userName: string;

    // @DataMember(Order=2)
    public permissions: string[];

    // @DataMember(Order=3)
    public roles: string[];

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<AssignRoles>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AssignRoles'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AssignRolesResponse(); }
}

// @Route("/unassignroles", "POST")
// @DataContract
export class UnAssignRoles implements IReturn<UnAssignRolesResponse>, IPost
{
    // @DataMember(Order=1)
    public userName: string;

    // @DataMember(Order=2)
    public permissions: string[];

    // @DataMember(Order=3)
    public roles: string[];

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<UnAssignRoles>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UnAssignRoles'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new UnAssignRolesResponse(); }
}

// @Route("/apikeys")
// @Route("/apikeys/{Environment}")
// @DataContract
export class GetApiKeys implements IReturn<GetApiKeysResponse>, IGet
{
    // @DataMember(Order=1)
    public environment: string;

    // @DataMember(Order=2)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<GetApiKeys>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetApiKeys'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetApiKeysResponse(); }
}

// @Route("/apikeys/regenerate")
// @Route("/apikeys/regenerate/{Environment}")
// @DataContract
export class RegenerateApiKeys implements IReturn<RegenerateApiKeysResponse>, IPost
{
    // @DataMember(Order=1)
    public environment: string;

    // @DataMember(Order=2)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<RegenerateApiKeys>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'RegenerateApiKeys'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new RegenerateApiKeysResponse(); }
}

// @Route("/pi-signoff/file-upload", "POST")
export class PostUploadFile implements IReturn<UploadResponse>, IPost
{

    public constructor(init?: Partial<PostUploadFile>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PostUploadFile'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new UploadResponse(); }
}

// @Route("/pi-signoff/file-upload/info/{Guid}", "GET")
export class GetUploadInfo implements IReturn<UploadResponse>, IGet
{
    public guid: string;

    public constructor(init?: Partial<GetUploadInfo>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetUploadInfo'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new UploadResponse(); }
}

// @Route("/pi-signoff/file-upload/{Guid}", "GET")
export class GetUpload implements IReturn<Blob>, IGet
{
    public guid: string;

    public constructor(init?: Partial<GetUpload>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetUpload'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Blob(); }
}

// @Route("/pi-signoff/file-upload/download/{Guid}", "GET")
export class DownloadUpload implements IReturn<Blob>, IGet
{
    public guid: string;

    public constructor(init?: Partial<DownloadUpload>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DownloadUpload'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Blob(); }
}

// @Route("/pi-signoff/file-upload/{Guid}", "DELETE")
export class DeleteUpload implements IReturn<UploadResponse>, IDelete
{
    public guid: string;

    public constructor(init?: Partial<DeleteUpload>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteUpload'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new UploadResponse(); }
}

/**
* User
*/
// @Route("/pi-signoff/user/single/user", "GET")
// @Api(Description="User")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.User.UserSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.User.UserSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.User.UserSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.User.UserSingleResponse)", StatusCode=500)
// @DataContract
export class UserGetSingle implements IReturn<UserSingleResponse>, IUserGetSingle
{
    /**
    * The metadata of the request.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<UserGetSingle>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UserGetSingle'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new UserSingleResponse(); }
}

/**
* Lookup
*/
// @Route("/pi-signoff/lookup/collection/form-definition/{FormDefinitionId}", "GET")
// @Api(Description="Lookup")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Lookup.LookupCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Lookup.LookupCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Lookup.LookupCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Lookup.LookupCollectionResponse)", StatusCode=500)
// @DataContract
export class LookupGetCollectionByFormDefinitionId implements IReturn<LookupCollectionResponse>, ILookupGetCollectionByFormDefinitionId
{
    /**
    * The ID of the Form Definition associated with the lookup to retrieve.
    */
    // @DataMember(Order=1)
    public formDefinitionId?: number;

    public constructor(init?: Partial<LookupGetCollectionByFormDefinitionId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'LookupGetCollectionByFormDefinitionId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new LookupCollectionResponse(); }
}

// @Route("/pi-signoff/form-names", "GET")
export class GetFormMetadata implements IReturn<GetFormMetadataResponse>
{

    public constructor(init?: Partial<GetFormMetadata>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetFormMetadata'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetFormMetadataResponse(); }
}

/**
* Form Type
*/
// @Route("/pi-signoff/form-type/single/id/{Id}", "GET")
// @Api(Description="Form Type")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.FormType.FormTypeSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.FormType.FormTypeSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.FormType.FormTypeSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.FormType.FormTypeSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.FormType.FormTypeSingleResponse)", StatusCode=500)
// @DataContract
export class FormTypeGetSingleById implements IReturn<FormTypeSingleResponse>, IConfigurationGetSingleById
{
    /**
    * The ID of the form type to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<FormTypeGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FormTypeGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new FormTypeSingleResponse(); }
}

/**
* Form Type
*/
// @Route("/pi-signoff/form-type/collection", "GET")
// @Api(Description="Form Type")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.FormType.FormTypeCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.FormType.FormTypeCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.FormType.FormTypeCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.FormType.FormTypeCollectionResponse)", StatusCode=500)
// @DataContract
export class FormTypeGetCollection implements IReturn<FormTypeCollectionResponse>, IConfigurationGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<FormTypeGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FormTypeGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new FormTypeCollectionResponse(); }
}

/**
* Form Type
*/
// @Route("/pi-signoff/form-type/single/code/{Code}", "GET")
// @Api(Description="Form Type")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.FormType.FormTypeSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.FormType.FormTypeSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.FormType.FormTypeSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.FormType.FormTypeSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.FormType.FormTypeSingleResponse)", StatusCode=500)
// @DataContract
export class FormTypeGetSingleByCode implements IReturn<FormTypeSingleResponse>, IConfigurationGetSingleByCode
{
    /**
    * The ID of the form type to retrieve.
    */
    // @DataMember(Order=1)
    public code: string;

    public constructor(init?: Partial<FormTypeGetSingleByCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FormTypeGetSingleByCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new FormTypeSingleResponse(); }
}

/**
* Form Definition
*/
// @Route("/pi-signoff/form-definition/single/id/{Id}", "GET")
// @Api(Description="Form Definition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=500)
// @DataContract
export class FormDefinitionGetSingleById implements IReturn<FormDefinitionSingleResponse>, IConfigurationGetSingleById
{
    /**
    * The ID of the form definition to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<FormDefinitionGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FormDefinitionGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new FormDefinitionSingleResponse(); }
}

/**
* Form Definition
*/
// @Route("/pi-signoff/form-definition/collection", "GET")
// @Api(Description="Form Definition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionCollectionResponse)", StatusCode=500)
// @DataContract
export class FormDefinitionGetCollection implements IReturn<FormDefinitionCollectionResponse>, IConfigurationGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<FormDefinitionGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FormDefinitionGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new FormDefinitionCollectionResponse(); }
}

/**
* Form Definition
*/
// @Route("/pi-signoff/form-definition/single/code/{Code}", "GET")
// @Api(Description="Form Definition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=500)
// @DataContract
export class FormDefinitionGetSingleByCode implements IReturn<FormDefinitionSingleResponse>, IConfigurationGetSingleByCode
{
    /**
    * The ID of the form definition to retrieve.
    */
    // @DataMember(Order=1)
    public code: string;

    public constructor(init?: Partial<FormDefinitionGetSingleByCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FormDefinitionGetSingleByCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new FormDefinitionSingleResponse(); }
}

/**
* Form - AdverseEvent
*/
// @Route("/pi-signoff/form/adverse-event/single/id/{Id}", "GET")
// @Api(Description="Form - AdverseEvent")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AdverseEventSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AdverseEventSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AdverseEventSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AdverseEventSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AdverseEventSingleResponse)", StatusCode=500)
// @DataContract
export class AdverseEventGetSingleById implements IReturn<AdverseEventSingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the adverse event to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<AdverseEventGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdverseEventGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AdverseEventSingleResponse(); }
}

/**
* Form - AdverseEvent
*/
// @Route("/pi-signoff/form/adverse-event/collection", "GET")
// @Api(Description="Form - AdverseEvent")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AdverseEventCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AdverseEventCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AdverseEventCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AdverseEventCollectionResponse)", StatusCode=500)
// @DataContract
export class AdverseEventGetCollection implements IReturn<AdverseEventCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<AdverseEventGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdverseEventGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AdverseEventCollectionResponse(); }
}

/**
* Form - AdverseEvent
*/
// @Route("/pi-signoff/form/adverse-event/create", "POST")
// @Api(Description="Form - AdverseEvent")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AdverseEventSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AdverseEventSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AdverseEventSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AdverseEventSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AdverseEventSingleResponse)", StatusCode=500)
// @DataContract
export class AdverseEventPostCreate implements IReturn<AdverseEventSingleResponse>
{
    /**
    * The metadata to use when creating the adverse event.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the adverse event.
    */
    // @DataMember(Order=2)
    public data: AdverseEvent;

    public constructor(init?: Partial<AdverseEventPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdverseEventPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AdverseEventSingleResponse(); }
}

/**
* Form - AdverseEvent
*/
// @Route("/pi-signoff/form/adverse-event/save", "POST")
// @Api(Description="Form - AdverseEvent")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AdverseEventSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AdverseEventSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AdverseEventSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AdverseEventSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AdverseEventSingleResponse)", StatusCode=500)
// @DataContract
export class AdverseEventPostSave implements IReturn<AdverseEventSingleResponse>
{
    /**
    * The metadata to use when creating the adverse event.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The adverse event to save.
    */
    // @DataMember(Order=2)
    public data: AdverseEvent;

    public constructor(init?: Partial<AdverseEventPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdverseEventPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AdverseEventSingleResponse(); }
}

/**
* Form - AdverseEvent
*/
// @Route("/pi-signoff/form/adverse-event/validate", "POST")
// @Api(Description="Form - AdverseEvent")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AdverseEventValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AdverseEventValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AdverseEventValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AdverseEventValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AdverseEventValidationResponse)", StatusCode=500)
// @DataContract
export class AdverseEventPostValidate implements IReturn<AdverseEventValidationResponse>
{
    /**
    * The metadata to use when validating the adverse event.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The adverse event to validate.
    */
    // @DataMember(Order=2)
    public data: AdverseEvent;

    public constructor(init?: Partial<AdverseEventPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdverseEventPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AdverseEventValidationResponse(); }
}

/**
* Form - AdverseEvent
*/
// @Route("/pi-signoff/form/adverse-event/single/medical-review-id-repeat/{PISignOffId}/{Repeat}", "GET")
// @Api(Description="Form - AdverseEvent")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AdverseEventSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AdverseEventSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AdverseEventSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AdverseEventSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AdverseEventSingleResponse)", StatusCode=500)
// @DataContract
export class AdverseEventGetSingleByPISignOffIdAndRepeat implements IReturn<AdverseEventSingleResponse>, IFormGetSingleByPISignOffIdAndRepeat
{
    /**
    * The id of the PI Sign-Off.
    */
    // @DataMember(Order=1)
    public piSignOffId?: number;

    /**
    * The repeat of the adverse event to retrieve.
    */
    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<AdverseEventGetSingleByPISignOffIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdverseEventGetSingleByPISignOffIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AdverseEventSingleResponse(); }
}

/**
* Form - AdverseEvent
*/
// @Route("/pi-signoff/form/adverse-event/single/update", "POST")
// @Api(Description="Form - AdverseEvent")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AdverseEventSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AdverseEventSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AdverseEventSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AdverseEventSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AdverseEventSingleResponse)", StatusCode=500)
// @DataContract
export class AdverseEventPostUpdateById implements IReturn<AdverseEventSingleResponse>, IFormPostUpdateById
{
    /**
    * The id of the medical review form.
    */
    // @DataMember(Order=1)
    public id?: number;

    /**
    * The additional metadata for the medical review.
    */
    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<AdverseEventPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdverseEventPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AdverseEventSingleResponse(); }
}

/**
* Form - BreastCancerRecurrence
*/
// @Route("/pi-signoff/form/breast-cancer-recurrence/single/id/{Id}", "GET")
// @Api(Description="Form - BreastCancerRecurrence")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceSingleResponse)", StatusCode=500)
// @DataContract
export class BreastCancerRecurrenceGetSingleById implements IReturn<BreastCancerRecurrenceSingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the breast cancer recurrence to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<BreastCancerRecurrenceGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BreastCancerRecurrenceGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new BreastCancerRecurrenceSingleResponse(); }
}

/**
* Form - BreastCancerRecurrence
*/
// @Route("/pi-signoff/form/breast-cancer-recurrence/collection", "GET")
// @Api(Description="Form - BreastCancerRecurrence")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceCollectionResponse)", StatusCode=500)
// @DataContract
export class BreastCancerRecurrenceGetCollection implements IReturn<BreastCancerRecurrenceCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<BreastCancerRecurrenceGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BreastCancerRecurrenceGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new BreastCancerRecurrenceCollectionResponse(); }
}

/**
* Form - BreastCancerRecurrence
*/
// @Route("/pi-signoff/form/breast-cancer-recurrence/create", "POST")
// @Api(Description="Form - BreastCancerRecurrence")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceSingleResponse)", StatusCode=500)
// @DataContract
export class BreastCancerRecurrencePostCreate implements IReturn<BreastCancerRecurrenceSingleResponse>
{
    /**
    * The metadata to use when creating the breast cancer recurrence.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the breast cancer recurrence.
    */
    // @DataMember(Order=2)
    public data: BreastCancerRecurrence;

    public constructor(init?: Partial<BreastCancerRecurrencePostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BreastCancerRecurrencePostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new BreastCancerRecurrenceSingleResponse(); }
}

/**
* Form - BreastCancerRecurrence
*/
// @Route("/pi-signoff/form/breast-cancer-recurrence/save", "POST")
// @Api(Description="Form - BreastCancerRecurrence")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceSingleResponse)", StatusCode=500)
// @DataContract
export class BreastCancerRecurrencePostSave implements IReturn<BreastCancerRecurrenceSingleResponse>
{
    /**
    * The metadata to use when creating the breast cancer recurrence.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The breast cancer recurrence to save.
    */
    // @DataMember(Order=2)
    public data: BreastCancerRecurrence;

    public constructor(init?: Partial<BreastCancerRecurrencePostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BreastCancerRecurrencePostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BreastCancerRecurrenceSingleResponse(); }
}

/**
* Form - BreastCancerRecurrence
*/
// @Route("/pi-signoff/form/breast-cancer-recurrence/validate", "POST")
// @Api(Description="Form - BreastCancerRecurrence")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceValidationResponse)", StatusCode=500)
// @DataContract
export class BreastCancerRecurrencePostValidate implements IReturn<BreastCancerRecurrenceValidationResponse>
{
    /**
    * The metadata to use when validating the breast cancer recurrence.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The breast cancer recurrence to validate.
    */
    // @DataMember(Order=2)
    public data: BreastCancerRecurrence;

    public constructor(init?: Partial<BreastCancerRecurrencePostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BreastCancerRecurrencePostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BreastCancerRecurrenceValidationResponse(); }
}

/**
* Form - BreastCancerRecurrence
*/
// @Route("/pi-signoff/form/breast-cancer-recurrence/single/medical-review-id-repeat/{PISignOffId}/{Repeat}", "GET")
// @Api(Description="Form - BreastCancerRecurrence")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceSingleResponse)", StatusCode=500)
// @DataContract
export class BreastCancerRecurrenceGetSingleByPISignOffIdAndRepeat implements IReturn<BreastCancerRecurrenceSingleResponse>, IFormGetSingleByPISignOffIdAndRepeat
{
    /**
    * The id of the PI Sign-Off.
    */
    // @DataMember(Order=1)
    public piSignOffId?: number;

    /**
    * The repeat of the breast cancer recurrence to retrieve.
    */
    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<BreastCancerRecurrenceGetSingleByPISignOffIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BreastCancerRecurrenceGetSingleByPISignOffIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new BreastCancerRecurrenceSingleResponse(); }
}

/**
* Form - BreastCancerRecurrence
*/
// @Route("/pi-signoff/form/breast-cancer-recurrence/single/update", "POST")
// @Api(Description="Form - BreastCancerRecurrence")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceSingleResponse)", StatusCode=500)
// @DataContract
export class BreastCancerRecurrencePostUpdateById implements IReturn<BreastCancerRecurrenceSingleResponse>, IFormPostUpdateById
{
    /**
    * The id of the medical review form.
    */
    // @DataMember(Order=1)
    public id?: number;

    /**
    * The additional metadata for the medical review.
    */
    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<BreastCancerRecurrencePostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BreastCancerRecurrencePostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BreastCancerRecurrenceSingleResponse(); }
}

/**
* Form - BreastUltrasound
*/
// @Route("/pi-signoff/form/breast-ultrasound/single/id/{Id}", "GET")
// @Api(Description="Form - BreastUltrasound")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BreastUltrasoundSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BreastUltrasoundSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BreastUltrasoundSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BreastUltrasoundSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BreastUltrasoundSingleResponse)", StatusCode=500)
// @DataContract
export class BreastUltrasoundGetSingleById implements IReturn<BreastUltrasoundSingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the breast ultrasound to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<BreastUltrasoundGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BreastUltrasoundGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new BreastUltrasoundSingleResponse(); }
}

/**
* Form - BreastUltrasound
*/
// @Route("/pi-signoff/form/breast-ultrasound/collection", "GET")
// @Api(Description="Form - BreastUltrasound")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BreastUltrasoundCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BreastUltrasoundCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BreastUltrasoundCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BreastUltrasoundCollectionResponse)", StatusCode=500)
// @DataContract
export class BreastUltrasoundGetCollection implements IReturn<BreastUltrasoundCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<BreastUltrasoundGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BreastUltrasoundGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new BreastUltrasoundCollectionResponse(); }
}

/**
* Form - BreastUltrasound
*/
// @Route("/pi-signoff/form/breast-ultrasound/create", "POST")
// @Api(Description="Form - BreastUltrasound")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BreastUltrasoundSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BreastUltrasoundSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BreastUltrasoundSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BreastUltrasoundSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BreastUltrasoundSingleResponse)", StatusCode=500)
// @DataContract
export class BreastUltrasoundPostCreate implements IReturn<BreastUltrasoundSingleResponse>
{
    /**
    * The metadata to use when creating the breast ultrasound.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the breast ultrasound.
    */
    // @DataMember(Order=2)
    public data: BreastUltrasound;

    public constructor(init?: Partial<BreastUltrasoundPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BreastUltrasoundPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new BreastUltrasoundSingleResponse(); }
}

/**
* Form - BreastUltrasound
*/
// @Route("/pi-signoff/form/breast-ultrasound/save", "POST")
// @Api(Description="Form - BreastUltrasound")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BreastUltrasoundSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BreastUltrasoundSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BreastUltrasoundSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BreastUltrasoundSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BreastUltrasoundSingleResponse)", StatusCode=500)
// @DataContract
export class BreastUltrasoundPostSave implements IReturn<BreastUltrasoundSingleResponse>
{
    /**
    * The metadata to use when creating the breast ultrasound.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The breast ultrasound to save.
    */
    // @DataMember(Order=2)
    public data: BreastUltrasound;

    public constructor(init?: Partial<BreastUltrasoundPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BreastUltrasoundPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BreastUltrasoundSingleResponse(); }
}

/**
* Form - BreastUltrasound
*/
// @Route("/pi-signoff/form/breast-ultrasound/validate", "POST")
// @Api(Description="Form - BreastUltrasound")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BreastUltrasoundValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BreastUltrasoundValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BreastUltrasoundValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BreastUltrasoundValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BreastUltrasoundValidationResponse)", StatusCode=500)
// @DataContract
export class BreastUltrasoundPostValidate implements IReturn<BreastUltrasoundValidationResponse>
{
    /**
    * The metadata to use when validating the breast ultrasound.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The breast ultrasound to validate.
    */
    // @DataMember(Order=2)
    public data: BreastUltrasound;

    public constructor(init?: Partial<BreastUltrasoundPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BreastUltrasoundPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BreastUltrasoundValidationResponse(); }
}

/**
* Form - BreastUltrasound
*/
// @Route("/pi-signoff/form/breast-ultrasound/single/medical-review-id-repeat/{PISignOffId}/{Repeat}", "GET")
// @Api(Description="Form - BreastUltrasound")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BreastUltrasoundSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BreastUltrasoundSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BreastUltrasoundSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BreastUltrasoundSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BreastUltrasoundSingleResponse)", StatusCode=500)
// @DataContract
export class BreastUltrasoundGetSingleByPISignOffIdAndRepeat implements IReturn<BreastUltrasoundSingleResponse>, IFormGetSingleByPISignOffIdAndRepeat
{
    /**
    * The id of the PI Sign-Off.
    */
    // @DataMember(Order=1)
    public piSignOffId?: number;

    /**
    * The repeat of the breast ultrasound to retrieve.
    */
    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<BreastUltrasoundGetSingleByPISignOffIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BreastUltrasoundGetSingleByPISignOffIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new BreastUltrasoundSingleResponse(); }
}

/**
* Form - BreastUltrasound
*/
// @Route("/pi-signoff/form/breast-ultrasound/single/update", "POST")
// @Api(Description="Form - BreastUltrasound")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BreastUltrasoundSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BreastUltrasoundSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BreastUltrasoundSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BreastUltrasoundSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BreastUltrasoundSingleResponse)", StatusCode=500)
// @DataContract
export class BreastUltrasoundPostUpdateById implements IReturn<BreastUltrasoundSingleResponse>, IFormPostUpdateById
{
    /**
    * The id of the medical review form.
    */
    // @DataMember(Order=1)
    public id?: number;

    /**
    * The additional metadata for the medical review.
    */
    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<BreastUltrasoundPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BreastUltrasoundPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BreastUltrasoundSingleResponse(); }
}

/**
* Form - CarboplatinTherapy
*/
// @Route("/pi-signoff/form/carboplatin-therapy/single/id/{Id}", "GET")
// @Api(Description="Form - CarboplatinTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.CarboplatinTherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.CarboplatinTherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.CarboplatinTherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.CarboplatinTherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.CarboplatinTherapySingleResponse)", StatusCode=500)
// @DataContract
export class CarboplatinTherapyGetSingleById implements IReturn<CarboplatinTherapySingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the carboplatin therapy to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<CarboplatinTherapyGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CarboplatinTherapyGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CarboplatinTherapySingleResponse(); }
}

/**
* Form - CarboplatinTherapy
*/
// @Route("/pi-signoff/form/carboplatin-therapy/collection", "GET")
// @Api(Description="Form - CarboplatinTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.CarboplatinTherapyCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.CarboplatinTherapyCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.CarboplatinTherapyCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.CarboplatinTherapyCollectionResponse)", StatusCode=500)
// @DataContract
export class CarboplatinTherapyGetCollection implements IReturn<CarboplatinTherapyCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<CarboplatinTherapyGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CarboplatinTherapyGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CarboplatinTherapyCollectionResponse(); }
}

/**
* Form - CarboplatinTherapy
*/
// @Route("/pi-signoff/form/carboplatin-therapy/create", "POST")
// @Api(Description="Form - CarboplatinTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.CarboplatinTherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.CarboplatinTherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.CarboplatinTherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.CarboplatinTherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.CarboplatinTherapySingleResponse)", StatusCode=500)
// @DataContract
export class CarboplatinTherapyPostCreate implements IReturn<CarboplatinTherapySingleResponse>
{
    /**
    * The metadata to use when creating the carboplatin therapy.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the carboplatin therapy.
    */
    // @DataMember(Order=2)
    public data: CarboplatinTherapy;

    public constructor(init?: Partial<CarboplatinTherapyPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CarboplatinTherapyPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CarboplatinTherapySingleResponse(); }
}

/**
* Form - CarboplatinTherapy
*/
// @Route("/pi-signoff/form/carboplatin-therapy/save", "POST")
// @Api(Description="Form - CarboplatinTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.CarboplatinTherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.CarboplatinTherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.CarboplatinTherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.CarboplatinTherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.CarboplatinTherapySingleResponse)", StatusCode=500)
// @DataContract
export class CarboplatinTherapyPostSave implements IReturn<CarboplatinTherapySingleResponse>
{
    /**
    * The metadata to use when creating the carboplatin therapy.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The carboplatin therapy to save.
    */
    // @DataMember(Order=2)
    public data: CarboplatinTherapy;

    public constructor(init?: Partial<CarboplatinTherapyPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CarboplatinTherapyPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CarboplatinTherapySingleResponse(); }
}

/**
* Form - CarboplatinTherapy
*/
// @Route("/pi-signoff/form/carboplatin-therapy/validate", "POST")
// @Api(Description="Form - CarboplatinTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.CarboplatinTherapyValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.CarboplatinTherapyValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.CarboplatinTherapyValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.CarboplatinTherapyValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.CarboplatinTherapyValidationResponse)", StatusCode=500)
// @DataContract
export class CarboplatinTherapyPostValidate implements IReturn<CarboplatinTherapyValidationResponse>
{
    /**
    * The metadata to use when validating the carboplatin therapy.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The carboplatin therapy to validate.
    */
    // @DataMember(Order=2)
    public data: CarboplatinTherapy;

    public constructor(init?: Partial<CarboplatinTherapyPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CarboplatinTherapyPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CarboplatinTherapyValidationResponse(); }
}

/**
* Form - CarboplatinTherapy
*/
// @Route("/pi-signoff/form/carboplatin-therapy/single/medical-review-id-repeat/{PISignOffId}/{Repeat}", "GET")
// @Api(Description="Form - CarboplatinTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.CarboplatinTherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.CarboplatinTherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.CarboplatinTherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.CarboplatinTherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.CarboplatinTherapySingleResponse)", StatusCode=500)
// @DataContract
export class CarboplatinTherapyGetSingleByPISignOffIdAndRepeat implements IReturn<CarboplatinTherapySingleResponse>, IFormGetSingleByPISignOffIdAndRepeat
{
    /**
    * The id of the PI Sign-Off.
    */
    // @DataMember(Order=1)
    public piSignOffId?: number;

    /**
    * The repeat of the carboplatin therapy to retrieve.
    */
    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<CarboplatinTherapyGetSingleByPISignOffIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CarboplatinTherapyGetSingleByPISignOffIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CarboplatinTherapySingleResponse(); }
}

/**
* Form - CarboplatinTherapy
*/
// @Route("/pi-signoff/form/carboplatin-therapy/single/update", "POST")
// @Api(Description="Form - CarboplatinTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.CarboplatinTherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.CarboplatinTherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.CarboplatinTherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.CarboplatinTherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.CarboplatinTherapySingleResponse)", StatusCode=500)
// @DataContract
export class CarboplatinTherapyPostUpdateById implements IReturn<CarboplatinTherapySingleResponse>, IFormPostUpdateById
{
    /**
    * The id of the medical review form.
    */
    // @DataMember(Order=1)
    public id?: number;

    /**
    * The additional metadata for the medical review.
    */
    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<CarboplatinTherapyPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CarboplatinTherapyPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CarboplatinTherapySingleResponse(); }
}

/**
* Form - ConcomitantMedication
*/
// @Route("/pi-signoff/form/concomitant-medication/single/id/{Id}", "GET")
// @Api(Description="Form - ConcomitantMedication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationSingleResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationGetSingleById implements IReturn<ConcomitantMedicationSingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the concomitant medication to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<ConcomitantMedicationGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ConcomitantMedicationSingleResponse(); }
}

/**
* Form - ConcomitantMedication
*/
// @Route("/pi-signoff/form/concomitant-medication/collection", "GET")
// @Api(Description="Form - ConcomitantMedication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationCollectionResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationGetCollection implements IReturn<ConcomitantMedicationCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<ConcomitantMedicationGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ConcomitantMedicationCollectionResponse(); }
}

/**
* Form - ConcomitantMedication
*/
// @Route("/pi-signoff/form/concomitant-medication/create", "POST")
// @Api(Description="Form - ConcomitantMedication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationSingleResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationPostCreate implements IReturn<ConcomitantMedicationSingleResponse>
{
    /**
    * The metadata to use when creating the concomitant medication.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the concomitant medication.
    */
    // @DataMember(Order=2)
    public data: ConcomitantMedication;

    public constructor(init?: Partial<ConcomitantMedicationPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ConcomitantMedicationSingleResponse(); }
}

/**
* Form - ConcomitantMedication
*/
// @Route("/pi-signoff/form/concomitant-medication/save", "POST")
// @Api(Description="Form - ConcomitantMedication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationSingleResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationPostSave implements IReturn<ConcomitantMedicationSingleResponse>
{
    /**
    * The metadata to use when creating the concomitant medication.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The concomitant medication to save.
    */
    // @DataMember(Order=2)
    public data: ConcomitantMedication;

    public constructor(init?: Partial<ConcomitantMedicationPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ConcomitantMedicationSingleResponse(); }
}

/**
* Form - ConcomitantMedication
*/
// @Route("/pi-signoff/form/concomitant-medication/validate", "POST")
// @Api(Description="Form - ConcomitantMedication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationValidationResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationPostValidate implements IReturn<ConcomitantMedicationValidationResponse>
{
    /**
    * The metadata to use when validating the concomitant medication.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The concomitant medication to validate.
    */
    // @DataMember(Order=2)
    public data: ConcomitantMedication;

    public constructor(init?: Partial<ConcomitantMedicationPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ConcomitantMedicationValidationResponse(); }
}

/**
* Form - ConcomitantMedication
*/
// @Route("/pi-signoff/form/concomitant-medication/single/medical-review-id-repeat/{PISignOffId}/{Repeat}", "GET")
// @Api(Description="Form - ConcomitantMedication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationSingleResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationGetSingleByPISignOffIdAndRepeat implements IReturn<ConcomitantMedicationSingleResponse>, IFormGetSingleByPISignOffIdAndRepeat
{
    /**
    * The id of the PI Sign-Off.
    */
    // @DataMember(Order=1)
    public piSignOffId?: number;

    /**
    * The repeat of the concomitant medication to retrieve.
    */
    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<ConcomitantMedicationGetSingleByPISignOffIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationGetSingleByPISignOffIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ConcomitantMedicationSingleResponse(); }
}

/**
* Form - ConcomitantMedication
*/
// @Route("/pi-signoff/form/concomitant-medication/single/update", "POST")
// @Api(Description="Form - ConcomitantMedication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationSingleResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationPostUpdateById implements IReturn<ConcomitantMedicationSingleResponse>, IFormPostUpdateById
{
    /**
    * The id of the medical review form.
    */
    // @DataMember(Order=1)
    public id?: number;

    /**
    * The additional metadata for the medical review.
    */
    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<ConcomitantMedicationPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ConcomitantMedicationSingleResponse(); }
}

/**
* Form - DeathReport
*/
// @Route("/pi-signoff/form/death-report/single/id/{Id}", "GET")
// @Api(Description="Form - DeathReport")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.DeathReportSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.DeathReportSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.DeathReportSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.DeathReportSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.DeathReportSingleResponse)", StatusCode=500)
// @DataContract
export class DeathReportGetSingleById implements IReturn<DeathReportSingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the death report to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<DeathReportGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeathReportGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new DeathReportSingleResponse(); }
}

/**
* Form - DeathReport
*/
// @Route("/pi-signoff/form/death-report/collection", "GET")
// @Api(Description="Form - DeathReport")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.DeathReportCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.DeathReportCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.DeathReportCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.DeathReportCollectionResponse)", StatusCode=500)
// @DataContract
export class DeathReportGetCollection implements IReturn<DeathReportCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<DeathReportGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeathReportGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new DeathReportCollectionResponse(); }
}

/**
* Form - DeathReport
*/
// @Route("/pi-signoff/form/death-report/create", "POST")
// @Api(Description="Form - DeathReport")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.DeathReportSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.DeathReportSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.DeathReportSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.DeathReportSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.DeathReportSingleResponse)", StatusCode=500)
// @DataContract
export class DeathReportPostCreate implements IReturn<DeathReportSingleResponse>
{
    /**
    * The metadata to use when creating the death report.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the death report.
    */
    // @DataMember(Order=2)
    public data: DeathReport;

    public constructor(init?: Partial<DeathReportPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeathReportPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new DeathReportSingleResponse(); }
}

/**
* Form - DeathReport
*/
// @Route("/pi-signoff/form/death-report/save", "POST")
// @Api(Description="Form - DeathReport")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.DeathReportSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.DeathReportSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.DeathReportSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.DeathReportSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.DeathReportSingleResponse)", StatusCode=500)
// @DataContract
export class DeathReportPostSave implements IReturn<DeathReportSingleResponse>
{
    /**
    * The metadata to use when creating the death report.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The death report to save.
    */
    // @DataMember(Order=2)
    public data: DeathReport;

    public constructor(init?: Partial<DeathReportPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeathReportPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new DeathReportSingleResponse(); }
}

/**
* Form - DeathReport
*/
// @Route("/pi-signoff/form/death-report/validate", "POST")
// @Api(Description="Form - DeathReport")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.DeathReportValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.DeathReportValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.DeathReportValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.DeathReportValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.DeathReportValidationResponse)", StatusCode=500)
// @DataContract
export class DeathReportPostValidate implements IReturn<DeathReportValidationResponse>
{
    /**
    * The metadata to use when validating the death report.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The death report to validate.
    */
    // @DataMember(Order=2)
    public data: DeathReport;

    public constructor(init?: Partial<DeathReportPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeathReportPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new DeathReportValidationResponse(); }
}

/**
* Form - DeathReport
*/
// @Route("/pi-signoff/form/death-report/single/medical-review-id-repeat/{PISignOffId}/{Repeat}", "GET")
// @Api(Description="Form - DeathReport")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.DeathReportSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.DeathReportSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.DeathReportSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.DeathReportSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.DeathReportSingleResponse)", StatusCode=500)
// @DataContract
export class DeathReportGetSingleByPISignOffIdAndRepeat implements IReturn<DeathReportSingleResponse>, IFormGetSingleByPISignOffIdAndRepeat
{
    /**
    * The id of the PI Sign-Off.
    */
    // @DataMember(Order=1)
    public piSignOffId?: number;

    /**
    * The repeat of the death report to retrieve.
    */
    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<DeathReportGetSingleByPISignOffIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeathReportGetSingleByPISignOffIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new DeathReportSingleResponse(); }
}

/**
* Form - DeathReport
*/
// @Route("/pi-signoff/form/death-report/single/update", "POST")
// @Api(Description="Form - DeathReport")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.DeathReportSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.DeathReportSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.DeathReportSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.DeathReportSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.DeathReportSingleResponse)", StatusCode=500)
// @DataContract
export class DeathReportPostUpdateById implements IReturn<DeathReportSingleResponse>, IFormPostUpdateById
{
    /**
    * The id of the medical review form.
    */
    // @DataMember(Order=1)
    public id?: number;

    /**
    * The additional metadata for the medical review.
    */
    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<DeathReportPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeathReportPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new DeathReportSingleResponse(); }
}

/**
* Form - MedicalHistory
*/
// @Route("/pi-signoff/form/medical-history/single/id/{Id}", "GET")
// @Api(Description="Form - MedicalHistory")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryGetSingleById implements IReturn<MedicalHistorySingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the medical history to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<MedicalHistoryGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicalHistorySingleResponse(); }
}

/**
* Form - MedicalHistory
*/
// @Route("/pi-signoff/form/medical-history/collection", "GET")
// @Api(Description="Form - MedicalHistory")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.MedicalHistoryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.MedicalHistoryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.MedicalHistoryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.MedicalHistoryCollectionResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryGetCollection implements IReturn<MedicalHistoryCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<MedicalHistoryGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicalHistoryCollectionResponse(); }
}

/**
* Form - MedicalHistory
*/
// @Route("/pi-signoff/form/medical-history/create", "POST")
// @Api(Description="Form - MedicalHistory")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryPostCreate implements IReturn<MedicalHistorySingleResponse>
{
    /**
    * The metadata to use when creating the medical history.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the medical history.
    */
    // @DataMember(Order=2)
    public data: MedicalHistory;

    public constructor(init?: Partial<MedicalHistoryPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicalHistorySingleResponse(); }
}

/**
* Form - MedicalHistory
*/
// @Route("/pi-signoff/form/medical-history/save", "POST")
// @Api(Description="Form - MedicalHistory")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryPostSave implements IReturn<MedicalHistorySingleResponse>
{
    /**
    * The metadata to use when creating the medical history.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The medical history to save.
    */
    // @DataMember(Order=2)
    public data: MedicalHistory;

    public constructor(init?: Partial<MedicalHistoryPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new MedicalHistorySingleResponse(); }
}

/**
* Form - MedicalHistory
*/
// @Route("/pi-signoff/form/medical-history/validate", "POST")
// @Api(Description="Form - MedicalHistory")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.MedicalHistoryValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.MedicalHistoryValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.MedicalHistoryValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.MedicalHistoryValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.MedicalHistoryValidationResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryPostValidate implements IReturn<MedicalHistoryValidationResponse>
{
    /**
    * The metadata to use when validating the medical history.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The medical history to validate.
    */
    // @DataMember(Order=2)
    public data: MedicalHistory;

    public constructor(init?: Partial<MedicalHistoryPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new MedicalHistoryValidationResponse(); }
}

/**
* Form - MedicalHistory
*/
// @Route("/pi-signoff/form/medical-history/single/medical-review-id-repeat/{PISignOffId}/{Repeat}", "GET")
// @Api(Description="Form - MedicalHistory")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryGetSingleByPISignOffIdAndRepeat implements IReturn<MedicalHistorySingleResponse>, IFormGetSingleByPISignOffIdAndRepeat
{
    /**
    * The id of the PI Sign-Off.
    */
    // @DataMember(Order=1)
    public piSignOffId?: number;

    /**
    * The repeat of the medical history to retrieve.
    */
    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<MedicalHistoryGetSingleByPISignOffIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryGetSingleByPISignOffIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicalHistorySingleResponse(); }
}

/**
* Form - MedicalHistory
*/
// @Route("/pi-signoff/form/medical-history/single/update", "POST")
// @Api(Description="Form - MedicalHistory")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryPostUpdateById implements IReturn<MedicalHistorySingleResponse>, IFormPostUpdateById
{
    /**
    * The id of the medical review form.
    */
    // @DataMember(Order=1)
    public id?: number;

    /**
    * The additional metadata for the medical review.
    */
    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<MedicalHistoryPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new MedicalHistorySingleResponse(); }
}

/**
* Form - MedicalHistoryMedication
*/
// @Route("/pi-signoff/form/medical-history-medication/single/id/{Id}", "GET")
// @Api(Description="Form - MedicalHistoryMedication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.MedicalHistoryMedicationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.MedicalHistoryMedicationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.MedicalHistoryMedicationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.MedicalHistoryMedicationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.MedicalHistoryMedicationSingleResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryMedicationGetSingleById implements IReturn<MedicalHistoryMedicationSingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the medical history medication to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<MedicalHistoryMedicationGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryMedicationGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicalHistoryMedicationSingleResponse(); }
}

/**
* Form - MedicalHistoryMedication
*/
// @Route("/pi-signoff/form/medical-history-medication/collection", "GET")
// @Api(Description="Form - MedicalHistoryMedication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.MedicalHistoryMedicationCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.MedicalHistoryMedicationCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.MedicalHistoryMedicationCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.MedicalHistoryMedicationCollectionResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryMedicationGetCollection implements IReturn<MedicalHistoryMedicationCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<MedicalHistoryMedicationGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryMedicationGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicalHistoryMedicationCollectionResponse(); }
}

/**
* Form - MedicalHistoryMedication
*/
// @Route("/pi-signoff/form/medical-history-medication/create", "POST")
// @Api(Description="Form - MedicalHistoryMedication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.MedicalHistoryMedicationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.MedicalHistoryMedicationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.MedicalHistoryMedicationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.MedicalHistoryMedicationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.MedicalHistoryMedicationSingleResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryMedicationPostCreate implements IReturn<MedicalHistoryMedicationSingleResponse>
{
    /**
    * The metadata to use when creating the medical history medication.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the medical history medication.
    */
    // @DataMember(Order=2)
    public data: MedicalHistoryMedication;

    public constructor(init?: Partial<MedicalHistoryMedicationPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryMedicationPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicalHistoryMedicationSingleResponse(); }
}

/**
* Form - MedicalHistoryMedication
*/
// @Route("/pi-signoff/form/medical-history-medication/save", "POST")
// @Api(Description="Form - MedicalHistoryMedication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.MedicalHistoryMedicationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.MedicalHistoryMedicationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.MedicalHistoryMedicationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.MedicalHistoryMedicationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.MedicalHistoryMedicationSingleResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryMedicationPostSave implements IReturn<MedicalHistoryMedicationSingleResponse>
{
    /**
    * The metadata to use when creating the medical history medication.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The medical history medication to save.
    */
    // @DataMember(Order=2)
    public data: MedicalHistoryMedication;

    public constructor(init?: Partial<MedicalHistoryMedicationPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryMedicationPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new MedicalHistoryMedicationSingleResponse(); }
}

/**
* Form - MedicalHistoryMedication
*/
// @Route("/pi-signoff/form/medical-history-medication/validate", "POST")
// @Api(Description="Form - MedicalHistoryMedication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.MedicalHistoryMedicationValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.MedicalHistoryMedicationValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.MedicalHistoryMedicationValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.MedicalHistoryMedicationValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.MedicalHistoryMedicationValidationResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryMedicationPostValidate implements IReturn<MedicalHistoryMedicationValidationResponse>
{
    /**
    * The metadata to use when validating the medical history medication.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The medical history medication to validate.
    */
    // @DataMember(Order=2)
    public data: MedicalHistoryMedication;

    public constructor(init?: Partial<MedicalHistoryMedicationPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryMedicationPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new MedicalHistoryMedicationValidationResponse(); }
}

/**
* Form - MedicalHistoryMedication
*/
// @Route("/pi-signoff/form/medical-history-medication/single/medical-review-id-repeat/{PISignOffId}/{Repeat}", "GET")
// @Api(Description="Form - MedicalHistoryMedication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.MedicalHistoryMedicationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.MedicalHistoryMedicationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.MedicalHistoryMedicationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.MedicalHistoryMedicationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.MedicalHistoryMedicationSingleResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryMedicationGetSingleByPISignOffIdAndRepeat implements IReturn<MedicalHistoryMedicationSingleResponse>, IFormGetSingleByPISignOffIdAndRepeat
{
    /**
    * The id of the PI Sign-Off.
    */
    // @DataMember(Order=1)
    public piSignOffId?: number;

    /**
    * The repeat of the medical history medication to retrieve.
    */
    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<MedicalHistoryMedicationGetSingleByPISignOffIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryMedicationGetSingleByPISignOffIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicalHistoryMedicationSingleResponse(); }
}

/**
* Form - MedicalHistoryMedication
*/
// @Route("/pi-signoff/form/medical-history-medication/single/update", "POST")
// @Api(Description="Form - MedicalHistoryMedication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.MedicalHistoryMedicationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.MedicalHistoryMedicationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.MedicalHistoryMedicationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.MedicalHistoryMedicationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.MedicalHistoryMedicationSingleResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryMedicationPostUpdateById implements IReturn<MedicalHistoryMedicationSingleResponse>, IFormPostUpdateById
{
    /**
    * The id of the medical review form.
    */
    // @DataMember(Order=1)
    public id?: number;

    /**
    * The additional metadata for the medical review.
    */
    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<MedicalHistoryMedicationPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryMedicationPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new MedicalHistoryMedicationSingleResponse(); }
}

/**
* Form - NivolumabTherapy
*/
// @Route("/pi-signoff/form/nivolumab-therapy/single/id/{Id}", "GET")
// @Api(Description="Form - NivolumabTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.NivolumabTherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.NivolumabTherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.NivolumabTherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.NivolumabTherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.NivolumabTherapySingleResponse)", StatusCode=500)
// @DataContract
export class NivolumabTherapyGetSingleById implements IReturn<NivolumabTherapySingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the nivolumab therapy to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<NivolumabTherapyGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NivolumabTherapyGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new NivolumabTherapySingleResponse(); }
}

/**
* Form - NivolumabTherapy
*/
// @Route("/pi-signoff/form/nivolumab-therapy/collection", "GET")
// @Api(Description="Form - NivolumabTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.NivolumabTherapyCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.NivolumabTherapyCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.NivolumabTherapyCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.NivolumabTherapyCollectionResponse)", StatusCode=500)
// @DataContract
export class NivolumabTherapyGetCollection implements IReturn<NivolumabTherapyCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<NivolumabTherapyGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NivolumabTherapyGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new NivolumabTherapyCollectionResponse(); }
}

/**
* Form - NivolumabTherapy
*/
// @Route("/pi-signoff/form/nivolumab-therapy/create", "POST")
// @Api(Description="Form - NivolumabTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.NivolumabTherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.NivolumabTherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.NivolumabTherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.NivolumabTherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.NivolumabTherapySingleResponse)", StatusCode=500)
// @DataContract
export class NivolumabTherapyPostCreate implements IReturn<NivolumabTherapySingleResponse>
{
    /**
    * The metadata to use when creating the nivolumab therapy.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the nivolumab therapy.
    */
    // @DataMember(Order=2)
    public data: NivolumabTherapy;

    public constructor(init?: Partial<NivolumabTherapyPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NivolumabTherapyPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new NivolumabTherapySingleResponse(); }
}

/**
* Form - NivolumabTherapy
*/
// @Route("/pi-signoff/form/nivolumab-therapy/save", "POST")
// @Api(Description="Form - NivolumabTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.NivolumabTherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.NivolumabTherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.NivolumabTherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.NivolumabTherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.NivolumabTherapySingleResponse)", StatusCode=500)
// @DataContract
export class NivolumabTherapyPostSave implements IReturn<NivolumabTherapySingleResponse>
{
    /**
    * The metadata to use when creating the nivolumab therapy.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The nivolumab therapy to save.
    */
    // @DataMember(Order=2)
    public data: NivolumabTherapy;

    public constructor(init?: Partial<NivolumabTherapyPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NivolumabTherapyPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new NivolumabTherapySingleResponse(); }
}

/**
* Form - NivolumabTherapy
*/
// @Route("/pi-signoff/form/nivolumab-therapy/validate", "POST")
// @Api(Description="Form - NivolumabTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.NivolumabTherapyValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.NivolumabTherapyValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.NivolumabTherapyValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.NivolumabTherapyValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.NivolumabTherapyValidationResponse)", StatusCode=500)
// @DataContract
export class NivolumabTherapyPostValidate implements IReturn<NivolumabTherapyValidationResponse>
{
    /**
    * The metadata to use when validating the nivolumab therapy.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The nivolumab therapy to validate.
    */
    // @DataMember(Order=2)
    public data: NivolumabTherapy;

    public constructor(init?: Partial<NivolumabTherapyPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NivolumabTherapyPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new NivolumabTherapyValidationResponse(); }
}

/**
* Form - NivolumabTherapy
*/
// @Route("/pi-signoff/form/nivolumab-therapy/single/medical-review-id-repeat/{PISignOffId}/{Repeat}", "GET")
// @Api(Description="Form - NivolumabTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.NivolumabTherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.NivolumabTherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.NivolumabTherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.NivolumabTherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.NivolumabTherapySingleResponse)", StatusCode=500)
// @DataContract
export class NivolumabTherapyGetSingleByPISignOffIdAndRepeat implements IReturn<NivolumabTherapySingleResponse>, IFormGetSingleByPISignOffIdAndRepeat
{
    /**
    * The id of the PI Sign-Off.
    */
    // @DataMember(Order=1)
    public piSignOffId?: number;

    /**
    * The repeat of the nivolumab therapy to retrieve.
    */
    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<NivolumabTherapyGetSingleByPISignOffIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NivolumabTherapyGetSingleByPISignOffIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new NivolumabTherapySingleResponse(); }
}

/**
* Form - NivolumabTherapy
*/
// @Route("/pi-signoff/form/nivolumab-therapy/single/update", "POST")
// @Api(Description="Form - NivolumabTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.NivolumabTherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.NivolumabTherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.NivolumabTherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.NivolumabTherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.NivolumabTherapySingleResponse)", StatusCode=500)
// @DataContract
export class NivolumabTherapyPostUpdateById implements IReturn<NivolumabTherapySingleResponse>, IFormPostUpdateById
{
    /**
    * The id of the medical review form.
    */
    // @DataMember(Order=1)
    public id?: number;

    /**
    * The additional metadata for the medical review.
    */
    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<NivolumabTherapyPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NivolumabTherapyPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new NivolumabTherapySingleResponse(); }
}

/**
* Form - PaclitaxelTherapy
*/
// @Route("/pi-signoff/form/paclitaxel-therapy/single/id/{Id}", "GET")
// @Api(Description="Form - PaclitaxelTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.PaclitaxelTherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.PaclitaxelTherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.PaclitaxelTherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.PaclitaxelTherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.PaclitaxelTherapySingleResponse)", StatusCode=500)
// @DataContract
export class PaclitaxelTherapyGetSingleById implements IReturn<PaclitaxelTherapySingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the paclitaxel therapy to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<PaclitaxelTherapyGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PaclitaxelTherapyGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PaclitaxelTherapySingleResponse(); }
}

/**
* Form - PaclitaxelTherapy
*/
// @Route("/pi-signoff/form/paclitaxel-therapy/collection", "GET")
// @Api(Description="Form - PaclitaxelTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.PaclitaxelTherapyCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.PaclitaxelTherapyCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.PaclitaxelTherapyCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.PaclitaxelTherapyCollectionResponse)", StatusCode=500)
// @DataContract
export class PaclitaxelTherapyGetCollection implements IReturn<PaclitaxelTherapyCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<PaclitaxelTherapyGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PaclitaxelTherapyGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PaclitaxelTherapyCollectionResponse(); }
}

/**
* Form - PaclitaxelTherapy
*/
// @Route("/pi-signoff/form/paclitaxel-therapy/create", "POST")
// @Api(Description="Form - PaclitaxelTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.PaclitaxelTherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.PaclitaxelTherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.PaclitaxelTherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.PaclitaxelTherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.PaclitaxelTherapySingleResponse)", StatusCode=500)
// @DataContract
export class PaclitaxelTherapyPostCreate implements IReturn<PaclitaxelTherapySingleResponse>
{
    /**
    * The metadata to use when creating the paclitaxel therapy.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the paclitaxel therapy.
    */
    // @DataMember(Order=2)
    public data: PaclitaxelTherapy;

    public constructor(init?: Partial<PaclitaxelTherapyPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PaclitaxelTherapyPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PaclitaxelTherapySingleResponse(); }
}

/**
* Form - PaclitaxelTherapy
*/
// @Route("/pi-signoff/form/paclitaxel-therapy/save", "POST")
// @Api(Description="Form - PaclitaxelTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.PaclitaxelTherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.PaclitaxelTherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.PaclitaxelTherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.PaclitaxelTherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.PaclitaxelTherapySingleResponse)", StatusCode=500)
// @DataContract
export class PaclitaxelTherapyPostSave implements IReturn<PaclitaxelTherapySingleResponse>
{
    /**
    * The metadata to use when creating the paclitaxel therapy.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The paclitaxel therapy to save.
    */
    // @DataMember(Order=2)
    public data: PaclitaxelTherapy;

    public constructor(init?: Partial<PaclitaxelTherapyPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PaclitaxelTherapyPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PaclitaxelTherapySingleResponse(); }
}

/**
* Form - PaclitaxelTherapy
*/
// @Route("/pi-signoff/form/paclitaxel-therapy/validate", "POST")
// @Api(Description="Form - PaclitaxelTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.PaclitaxelTherapyValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.PaclitaxelTherapyValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.PaclitaxelTherapyValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.PaclitaxelTherapyValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.PaclitaxelTherapyValidationResponse)", StatusCode=500)
// @DataContract
export class PaclitaxelTherapyPostValidate implements IReturn<PaclitaxelTherapyValidationResponse>
{
    /**
    * The metadata to use when validating the paclitaxel therapy.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The paclitaxel therapy to validate.
    */
    // @DataMember(Order=2)
    public data: PaclitaxelTherapy;

    public constructor(init?: Partial<PaclitaxelTherapyPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PaclitaxelTherapyPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PaclitaxelTherapyValidationResponse(); }
}

/**
* Form - PaclitaxelTherapy
*/
// @Route("/pi-signoff/form/paclitaxel-therapy/single/medical-review-id-repeat/{PISignOffId}/{Repeat}", "GET")
// @Api(Description="Form - PaclitaxelTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.PaclitaxelTherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.PaclitaxelTherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.PaclitaxelTherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.PaclitaxelTherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.PaclitaxelTherapySingleResponse)", StatusCode=500)
// @DataContract
export class PaclitaxelTherapyGetSingleByPISignOffIdAndRepeat implements IReturn<PaclitaxelTherapySingleResponse>, IFormGetSingleByPISignOffIdAndRepeat
{
    /**
    * The id of the PI Sign-Off.
    */
    // @DataMember(Order=1)
    public piSignOffId?: number;

    /**
    * The repeat of the paclitaxel therapy to retrieve.
    */
    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<PaclitaxelTherapyGetSingleByPISignOffIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PaclitaxelTherapyGetSingleByPISignOffIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PaclitaxelTherapySingleResponse(); }
}

/**
* Form - PaclitaxelTherapy
*/
// @Route("/pi-signoff/form/paclitaxel-therapy/single/update", "POST")
// @Api(Description="Form - PaclitaxelTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.PaclitaxelTherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.PaclitaxelTherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.PaclitaxelTherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.PaclitaxelTherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.PaclitaxelTherapySingleResponse)", StatusCode=500)
// @DataContract
export class PaclitaxelTherapyPostUpdateById implements IReturn<PaclitaxelTherapySingleResponse>, IFormPostUpdateById
{
    /**
    * The id of the medical review form.
    */
    // @DataMember(Order=1)
    public id?: number;

    /**
    * The additional metadata for the medical review.
    */
    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<PaclitaxelTherapyPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PaclitaxelTherapyPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PaclitaxelTherapySingleResponse(); }
}

/**
* Form - SecondPrimaryMalignancy
*/
// @Route("/pi-signoff/form/second-primary-malignancy/single/id/{Id}", "GET")
// @Api(Description="Form - SecondPrimaryMalignancy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SecondPrimaryMalignancySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SecondPrimaryMalignancySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SecondPrimaryMalignancySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SecondPrimaryMalignancySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SecondPrimaryMalignancySingleResponse)", StatusCode=500)
// @DataContract
export class SecondPrimaryMalignancyGetSingleById implements IReturn<SecondPrimaryMalignancySingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the second primary malignancy to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<SecondPrimaryMalignancyGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SecondPrimaryMalignancyGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SecondPrimaryMalignancySingleResponse(); }
}

/**
* Form - SecondPrimaryMalignancy
*/
// @Route("/pi-signoff/form/second-primary-malignancy/collection", "GET")
// @Api(Description="Form - SecondPrimaryMalignancy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SecondPrimaryMalignancyCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SecondPrimaryMalignancyCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SecondPrimaryMalignancyCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SecondPrimaryMalignancyCollectionResponse)", StatusCode=500)
// @DataContract
export class SecondPrimaryMalignancyGetCollection implements IReturn<SecondPrimaryMalignancyCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<SecondPrimaryMalignancyGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SecondPrimaryMalignancyGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SecondPrimaryMalignancyCollectionResponse(); }
}

/**
* Form - SecondPrimaryMalignancy
*/
// @Route("/pi-signoff/form/second-primary-malignancy/create", "POST")
// @Api(Description="Form - SecondPrimaryMalignancy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SecondPrimaryMalignancySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SecondPrimaryMalignancySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SecondPrimaryMalignancySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SecondPrimaryMalignancySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SecondPrimaryMalignancySingleResponse)", StatusCode=500)
// @DataContract
export class SecondPrimaryMalignancyPostCreate implements IReturn<SecondPrimaryMalignancySingleResponse>
{
    /**
    * The metadata to use when creating the second primary malignancy.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the second primary malignancy.
    */
    // @DataMember(Order=2)
    public data: SecondPrimaryMalignancy;

    public constructor(init?: Partial<SecondPrimaryMalignancyPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SecondPrimaryMalignancyPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SecondPrimaryMalignancySingleResponse(); }
}

/**
* Form - SecondPrimaryMalignancy
*/
// @Route("/pi-signoff/form/second-primary-malignancy/save", "POST")
// @Api(Description="Form - SecondPrimaryMalignancy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SecondPrimaryMalignancySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SecondPrimaryMalignancySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SecondPrimaryMalignancySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SecondPrimaryMalignancySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SecondPrimaryMalignancySingleResponse)", StatusCode=500)
// @DataContract
export class SecondPrimaryMalignancyPostSave implements IReturn<SecondPrimaryMalignancySingleResponse>
{
    /**
    * The metadata to use when creating the second primary malignancy.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The second primary malignancy to save.
    */
    // @DataMember(Order=2)
    public data: SecondPrimaryMalignancy;

    public constructor(init?: Partial<SecondPrimaryMalignancyPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SecondPrimaryMalignancyPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SecondPrimaryMalignancySingleResponse(); }
}

/**
* Form - SecondPrimaryMalignancy
*/
// @Route("/pi-signoff/form/second-primary-malignancy/validate", "POST")
// @Api(Description="Form - SecondPrimaryMalignancy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SecondPrimaryMalignancyValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SecondPrimaryMalignancyValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SecondPrimaryMalignancyValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SecondPrimaryMalignancyValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SecondPrimaryMalignancyValidationResponse)", StatusCode=500)
// @DataContract
export class SecondPrimaryMalignancyPostValidate implements IReturn<SecondPrimaryMalignancyValidationResponse>
{
    /**
    * The metadata to use when validating the second primary malignancy.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The second primary malignancy to validate.
    */
    // @DataMember(Order=2)
    public data: SecondPrimaryMalignancy;

    public constructor(init?: Partial<SecondPrimaryMalignancyPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SecondPrimaryMalignancyPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SecondPrimaryMalignancyValidationResponse(); }
}

/**
* Form - SecondPrimaryMalignancy
*/
// @Route("/pi-signoff/form/second-primary-malignancy/single/medical-review-id-repeat/{PISignOffId}/{Repeat}", "GET")
// @Api(Description="Form - SecondPrimaryMalignancy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SecondPrimaryMalignancySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SecondPrimaryMalignancySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SecondPrimaryMalignancySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SecondPrimaryMalignancySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SecondPrimaryMalignancySingleResponse)", StatusCode=500)
// @DataContract
export class SecondPrimaryMalignancyGetSingleByPISignOffIdAndRepeat implements IReturn<SecondPrimaryMalignancySingleResponse>, IFormGetSingleByPISignOffIdAndRepeat
{
    /**
    * The id of the PI Sign-Off.
    */
    // @DataMember(Order=1)
    public piSignOffId?: number;

    /**
    * The repeat of the second primary malignancy to retrieve.
    */
    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<SecondPrimaryMalignancyGetSingleByPISignOffIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SecondPrimaryMalignancyGetSingleByPISignOffIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SecondPrimaryMalignancySingleResponse(); }
}

/**
* Form - SecondPrimaryMalignancy
*/
// @Route("/pi-signoff/form/second-primary-malignancy/single/update", "POST")
// @Api(Description="Form - SecondPrimaryMalignancy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SecondPrimaryMalignancySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SecondPrimaryMalignancySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SecondPrimaryMalignancySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SecondPrimaryMalignancySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SecondPrimaryMalignancySingleResponse)", StatusCode=500)
// @DataContract
export class SecondPrimaryMalignancyPostUpdateById implements IReturn<SecondPrimaryMalignancySingleResponse>, IFormPostUpdateById
{
    /**
    * The id of the medical review form.
    */
    // @DataMember(Order=1)
    public id?: number;

    /**
    * The additional metadata for the medical review.
    */
    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<SecondPrimaryMalignancyPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SecondPrimaryMalignancyPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SecondPrimaryMalignancySingleResponse(); }
}

/**
* Form - SeriousAdverseEvent
*/
// @Route("/pi-signoff/form/serious-adverse-event/single/id/{Id}", "GET")
// @Api(Description="Form - SeriousAdverseEvent")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventSingleResponse)", StatusCode=500)
// @DataContract
export class SeriousAdverseEventGetSingleById implements IReturn<SeriousAdverseEventSingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the serious adverse event to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<SeriousAdverseEventGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SeriousAdverseEventGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SeriousAdverseEventSingleResponse(); }
}

/**
* Form - SeriousAdverseEvent
*/
// @Route("/pi-signoff/form/serious-adverse-event/collection", "GET")
// @Api(Description="Form - SeriousAdverseEvent")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventCollectionResponse)", StatusCode=500)
// @DataContract
export class SeriousAdverseEventGetCollection implements IReturn<SeriousAdverseEventCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<SeriousAdverseEventGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SeriousAdverseEventGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SeriousAdverseEventCollectionResponse(); }
}

/**
* Form - SeriousAdverseEvent
*/
// @Route("/pi-signoff/form/serious-adverse-event/create", "POST")
// @Api(Description="Form - SeriousAdverseEvent")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventSingleResponse)", StatusCode=500)
// @DataContract
export class SeriousAdverseEventPostCreate implements IReturn<SeriousAdverseEventSingleResponse>
{
    /**
    * The metadata to use when creating the serious adverse event.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the serious adverse event.
    */
    // @DataMember(Order=2)
    public data: SeriousAdverseEvent;

    public constructor(init?: Partial<SeriousAdverseEventPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SeriousAdverseEventPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SeriousAdverseEventSingleResponse(); }
}

/**
* Form - SeriousAdverseEvent
*/
// @Route("/pi-signoff/form/serious-adverse-event/save", "POST")
// @Api(Description="Form - SeriousAdverseEvent")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventSingleResponse)", StatusCode=500)
// @DataContract
export class SeriousAdverseEventPostSave implements IReturn<SeriousAdverseEventSingleResponse>
{
    /**
    * The metadata to use when creating the serious adverse event.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The serious adverse event to save.
    */
    // @DataMember(Order=2)
    public data: SeriousAdverseEvent;

    public constructor(init?: Partial<SeriousAdverseEventPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SeriousAdverseEventPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SeriousAdverseEventSingleResponse(); }
}

/**
* Form - SeriousAdverseEvent
*/
// @Route("/pi-signoff/form/serious-adverse-event/validate", "POST")
// @Api(Description="Form - SeriousAdverseEvent")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventValidationResponse)", StatusCode=500)
// @DataContract
export class SeriousAdverseEventPostValidate implements IReturn<SeriousAdverseEventValidationResponse>
{
    /**
    * The metadata to use when validating the serious adverse event.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The serious adverse event to validate.
    */
    // @DataMember(Order=2)
    public data: SeriousAdverseEvent;

    public constructor(init?: Partial<SeriousAdverseEventPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SeriousAdverseEventPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SeriousAdverseEventValidationResponse(); }
}

/**
* Form - SeriousAdverseEvent
*/
// @Route("/pi-signoff/form/serious-adverse-event/single/medical-review-id-repeat/{PISignOffId}/{Repeat}", "GET")
// @Api(Description="Form - SeriousAdverseEvent")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventSingleResponse)", StatusCode=500)
// @DataContract
export class SeriousAdverseEventGetSingleByPISignOffIdAndRepeat implements IReturn<SeriousAdverseEventSingleResponse>, IFormGetSingleByPISignOffIdAndRepeat
{
    /**
    * The id of the PI Sign-Off.
    */
    // @DataMember(Order=1)
    public piSignOffId?: number;

    /**
    * The repeat of the serious adverse event to retrieve.
    */
    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<SeriousAdverseEventGetSingleByPISignOffIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SeriousAdverseEventGetSingleByPISignOffIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SeriousAdverseEventSingleResponse(); }
}

/**
* Form - SeriousAdverseEvent
*/
// @Route("/pi-signoff/form/serious-adverse-event/single/update", "POST")
// @Api(Description="Form - SeriousAdverseEvent")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventSingleResponse)", StatusCode=500)
// @DataContract
export class SeriousAdverseEventPostUpdateById implements IReturn<SeriousAdverseEventSingleResponse>, IFormPostUpdateById
{
    /**
    * The id of the medical review form.
    */
    // @DataMember(Order=1)
    public id?: number;

    /**
    * The additional metadata for the medical review.
    */
    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<SeriousAdverseEventPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SeriousAdverseEventPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SeriousAdverseEventSingleResponse(); }
}

/**
* Form - SlnbResult
*/
// @Route("/pi-signoff/form/slnb-result/single/id/{Id}", "GET")
// @Api(Description="Form - SlnbResult")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SlnbResultSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SlnbResultSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SlnbResultSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SlnbResultSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SlnbResultSingleResponse)", StatusCode=500)
// @DataContract
export class SlnbResultGetSingleById implements IReturn<SlnbResultSingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the slnb result to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<SlnbResultGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SlnbResultGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SlnbResultSingleResponse(); }
}

/**
* Form - SlnbResult
*/
// @Route("/pi-signoff/form/slnb-result/collection", "GET")
// @Api(Description="Form - SlnbResult")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SlnbResultCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SlnbResultCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SlnbResultCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SlnbResultCollectionResponse)", StatusCode=500)
// @DataContract
export class SlnbResultGetCollection implements IReturn<SlnbResultCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<SlnbResultGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SlnbResultGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SlnbResultCollectionResponse(); }
}

/**
* Form - SlnbResult
*/
// @Route("/pi-signoff/form/slnb-result/create", "POST")
// @Api(Description="Form - SlnbResult")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SlnbResultSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SlnbResultSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SlnbResultSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SlnbResultSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SlnbResultSingleResponse)", StatusCode=500)
// @DataContract
export class SlnbResultPostCreate implements IReturn<SlnbResultSingleResponse>
{
    /**
    * The metadata to use when creating the slnb result.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the slnb result.
    */
    // @DataMember(Order=2)
    public data: SlnbResult;

    public constructor(init?: Partial<SlnbResultPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SlnbResultPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SlnbResultSingleResponse(); }
}

/**
* Form - SlnbResult
*/
// @Route("/pi-signoff/form/slnb-result/save", "POST")
// @Api(Description="Form - SlnbResult")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SlnbResultSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SlnbResultSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SlnbResultSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SlnbResultSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SlnbResultSingleResponse)", StatusCode=500)
// @DataContract
export class SlnbResultPostSave implements IReturn<SlnbResultSingleResponse>
{
    /**
    * The metadata to use when creating the slnb result.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The slnb result to save.
    */
    // @DataMember(Order=2)
    public data: SlnbResult;

    public constructor(init?: Partial<SlnbResultPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SlnbResultPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SlnbResultSingleResponse(); }
}

/**
* Form - SlnbResult
*/
// @Route("/pi-signoff/form/slnb-result/validate", "POST")
// @Api(Description="Form - SlnbResult")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SlnbResultValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SlnbResultValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SlnbResultValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SlnbResultValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SlnbResultValidationResponse)", StatusCode=500)
// @DataContract
export class SlnbResultPostValidate implements IReturn<SlnbResultValidationResponse>
{
    /**
    * The metadata to use when validating the slnb result.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The slnb result to validate.
    */
    // @DataMember(Order=2)
    public data: SlnbResult;

    public constructor(init?: Partial<SlnbResultPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SlnbResultPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SlnbResultValidationResponse(); }
}

/**
* Form - SlnbResult
*/
// @Route("/pi-signoff/form/slnb-result/single/medical-review-id-repeat/{PISignOffId}/{Repeat}", "GET")
// @Api(Description="Form - SlnbResult")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SlnbResultSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SlnbResultSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SlnbResultSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SlnbResultSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SlnbResultSingleResponse)", StatusCode=500)
// @DataContract
export class SlnbResultGetSingleByPISignOffIdAndRepeat implements IReturn<SlnbResultSingleResponse>, IFormGetSingleByPISignOffIdAndRepeat
{
    /**
    * The id of the PI Sign-Off.
    */
    // @DataMember(Order=1)
    public piSignOffId?: number;

    /**
    * The repeat of the slnb result to retrieve.
    */
    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<SlnbResultGetSingleByPISignOffIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SlnbResultGetSingleByPISignOffIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SlnbResultSingleResponse(); }
}

/**
* Form - SlnbResult
*/
// @Route("/pi-signoff/form/slnb-result/single/update", "POST")
// @Api(Description="Form - SlnbResult")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SlnbResultSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SlnbResultSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SlnbResultSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SlnbResultSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SlnbResultSingleResponse)", StatusCode=500)
// @DataContract
export class SlnbResultPostUpdateById implements IReturn<SlnbResultSingleResponse>, IFormPostUpdateById
{
    /**
    * The id of the medical review form.
    */
    // @DataMember(Order=1)
    public id?: number;

    /**
    * The additional metadata for the medical review.
    */
    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<SlnbResultPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SlnbResultPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SlnbResultSingleResponse(); }
}

/**
* Form - SurvivalFollowUp
*/
// @Route("/pi-signoff/form/survival-follow-up/single/id/{Id}", "GET")
// @Api(Description="Form - SurvivalFollowUp")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpSingleResponse)", StatusCode=500)
// @DataContract
export class SurvivalFollowUpGetSingleById implements IReturn<SurvivalFollowUpSingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the survival follow up to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<SurvivalFollowUpGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SurvivalFollowUpGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SurvivalFollowUpSingleResponse(); }
}

/**
* Form - SurvivalFollowUp
*/
// @Route("/pi-signoff/form/survival-follow-up/collection", "GET")
// @Api(Description="Form - SurvivalFollowUp")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpCollectionResponse)", StatusCode=500)
// @DataContract
export class SurvivalFollowUpGetCollection implements IReturn<SurvivalFollowUpCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<SurvivalFollowUpGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SurvivalFollowUpGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SurvivalFollowUpCollectionResponse(); }
}

/**
* Form - SurvivalFollowUp
*/
// @Route("/pi-signoff/form/survival-follow-up/create", "POST")
// @Api(Description="Form - SurvivalFollowUp")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpSingleResponse)", StatusCode=500)
// @DataContract
export class SurvivalFollowUpPostCreate implements IReturn<SurvivalFollowUpSingleResponse>
{
    /**
    * The metadata to use when creating the survival follow up.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the survival follow up.
    */
    // @DataMember(Order=2)
    public data: SurvivalFollowUp;

    public constructor(init?: Partial<SurvivalFollowUpPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SurvivalFollowUpPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SurvivalFollowUpSingleResponse(); }
}

/**
* Form - SurvivalFollowUp
*/
// @Route("/pi-signoff/form/survival-follow-up/save", "POST")
// @Api(Description="Form - SurvivalFollowUp")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpSingleResponse)", StatusCode=500)
// @DataContract
export class SurvivalFollowUpPostSave implements IReturn<SurvivalFollowUpSingleResponse>
{
    /**
    * The metadata to use when creating the survival follow up.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The survival follow up to save.
    */
    // @DataMember(Order=2)
    public data: SurvivalFollowUp;

    public constructor(init?: Partial<SurvivalFollowUpPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SurvivalFollowUpPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SurvivalFollowUpSingleResponse(); }
}

/**
* Form - SurvivalFollowUp
*/
// @Route("/pi-signoff/form/survival-follow-up/validate", "POST")
// @Api(Description="Form - SurvivalFollowUp")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpValidationResponse)", StatusCode=500)
// @DataContract
export class SurvivalFollowUpPostValidate implements IReturn<SurvivalFollowUpValidationResponse>
{
    /**
    * The metadata to use when validating the survival follow up.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The survival follow up to validate.
    */
    // @DataMember(Order=2)
    public data: SurvivalFollowUp;

    public constructor(init?: Partial<SurvivalFollowUpPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SurvivalFollowUpPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SurvivalFollowUpValidationResponse(); }
}

/**
* Form - SurvivalFollowUp
*/
// @Route("/pi-signoff/form/survival-follow-up/single/medical-review-id-repeat/{PISignOffId}/{Repeat}", "GET")
// @Api(Description="Form - SurvivalFollowUp")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpSingleResponse)", StatusCode=500)
// @DataContract
export class SurvivalFollowUpGetSingleByPISignOffIdAndRepeat implements IReturn<SurvivalFollowUpSingleResponse>, IFormGetSingleByPISignOffIdAndRepeat
{
    /**
    * The id of the PI Sign-Off.
    */
    // @DataMember(Order=1)
    public piSignOffId?: number;

    /**
    * The repeat of the survival follow up to retrieve.
    */
    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<SurvivalFollowUpGetSingleByPISignOffIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SurvivalFollowUpGetSingleByPISignOffIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SurvivalFollowUpSingleResponse(); }
}

/**
* Form - SurvivalFollowUp
*/
// @Route("/pi-signoff/form/survival-follow-up/single/update", "POST")
// @Api(Description="Form - SurvivalFollowUp")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpSingleResponse)", StatusCode=500)
// @DataContract
export class SurvivalFollowUpPostUpdateById implements IReturn<SurvivalFollowUpSingleResponse>, IFormPostUpdateById
{
    /**
    * The id of the medical review form.
    */
    // @DataMember(Order=1)
    public id?: number;

    /**
    * The additional metadata for the medical review.
    */
    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<SurvivalFollowUpPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SurvivalFollowUpPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SurvivalFollowUpSingleResponse(); }
}

/**
* Form - ParticipantStudySummary
*/
// @Route("/pi-signoff/form/participant-study-summary/single/id/{Id}", "GET")
// @Api(Description="Form - ParticipantStudySummary")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ParticipantStudySummarySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ParticipantStudySummarySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ParticipantStudySummarySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ParticipantStudySummarySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ParticipantStudySummarySingleResponse)", StatusCode=500)
// @DataContract
export class ParticipantStudySummaryGetSingleById implements IReturn<ParticipantStudySummarySingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the participant study summary to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<ParticipantStudySummaryGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ParticipantStudySummaryGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ParticipantStudySummarySingleResponse(); }
}

/**
* Form - ParticipantStudySummary
*/
// @Route("/pi-signoff/form/participant-study-summary/collection", "GET")
// @Api(Description="Form - ParticipantStudySummary")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ParticipantStudySummaryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ParticipantStudySummaryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ParticipantStudySummaryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ParticipantStudySummaryCollectionResponse)", StatusCode=500)
// @DataContract
export class ParticipantStudySummaryGetCollection implements IReturn<ParticipantStudySummaryCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<ParticipantStudySummaryGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ParticipantStudySummaryGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ParticipantStudySummaryCollectionResponse(); }
}

/**
* Form - ParticipantStudySummary
*/
// @Route("/pi-signoff/form/participant-study-summary/create", "POST")
// @Api(Description="Form - ParticipantStudySummary")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ParticipantStudySummarySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ParticipantStudySummarySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ParticipantStudySummarySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ParticipantStudySummarySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ParticipantStudySummarySingleResponse)", StatusCode=500)
// @DataContract
export class ParticipantStudySummaryPostCreate implements IReturn<ParticipantStudySummarySingleResponse>
{
    /**
    * The metadata to use when creating the participant study summary.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the participant study summary.
    */
    // @DataMember(Order=2)
    public data: ParticipantStudySummary;

    public constructor(init?: Partial<ParticipantStudySummaryPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ParticipantStudySummaryPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ParticipantStudySummarySingleResponse(); }
}

/**
* Form - ParticipantStudySummary
*/
// @Route("/pi-signoff/form/participant-study-summary/save", "POST")
// @Api(Description="Form - ParticipantStudySummary")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ParticipantStudySummarySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ParticipantStudySummarySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ParticipantStudySummarySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ParticipantStudySummarySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ParticipantStudySummarySingleResponse)", StatusCode=500)
// @DataContract
export class ParticipantStudySummaryPostSave implements IReturn<ParticipantStudySummarySingleResponse>
{
    /**
    * The metadata to use when creating the participant study summary.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The participant study summary to save.
    */
    // @DataMember(Order=2)
    public data: ParticipantStudySummary;

    public constructor(init?: Partial<ParticipantStudySummaryPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ParticipantStudySummaryPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ParticipantStudySummarySingleResponse(); }
}

/**
* Form - ParticipantStudySummary
*/
// @Route("/pi-signoff/form/participant-study-summary/validate", "POST")
// @Api(Description="Form - ParticipantStudySummary")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ParticipantStudySummaryValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ParticipantStudySummaryValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ParticipantStudySummaryValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ParticipantStudySummaryValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ParticipantStudySummaryValidationResponse)", StatusCode=500)
// @DataContract
export class ParticipantStudySummaryPostValidate implements IReturn<ParticipantStudySummaryValidationResponse>
{
    /**
    * The metadata to use when validating the participant study summary.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The participant study summary to validate.
    */
    // @DataMember(Order=2)
    public data: ParticipantStudySummary;

    public constructor(init?: Partial<ParticipantStudySummaryPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ParticipantStudySummaryPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ParticipantStudySummaryValidationResponse(); }
}

/**
* Form - ParticipantStudySummary
*/
// @Route("/pi-signoff/form/participant-study-summary/single/medical-review-id-repeat/{PISignOffId}/{Repeat}", "GET")
// @Api(Description="Form - ParticipantStudySummary")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ParticipantStudySummarySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ParticipantStudySummarySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ParticipantStudySummarySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ParticipantStudySummarySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ParticipantStudySummarySingleResponse)", StatusCode=500)
// @DataContract
export class ParticipantStudySummaryGetSingleByPISignOffIdAndRepeat implements IReturn<ParticipantStudySummarySingleResponse>, IFormGetSingleByPISignOffIdAndRepeat
{
    /**
    * The id of the PI Sign-Off.
    */
    // @DataMember(Order=1)
    public piSignOffId?: number;

    /**
    * The repeat of the participant study summary to retrieve.
    */
    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<ParticipantStudySummaryGetSingleByPISignOffIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ParticipantStudySummaryGetSingleByPISignOffIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ParticipantStudySummarySingleResponse(); }
}

/**
* Form - ParticipantStudySummary
*/
// @Route("/pi-signoff/form/participant-study-summary/single/update", "POST")
// @Api(Description="Form - ParticipantStudySummary")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ParticipantStudySummarySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ParticipantStudySummarySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ParticipantStudySummarySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ParticipantStudySummarySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ParticipantStudySummarySingleResponse)", StatusCode=500)
// @DataContract
export class ParticipantStudySummaryPostUpdateById implements IReturn<ParticipantStudySummarySingleResponse>, IFormPostUpdateById
{
    /**
    * The id of the medical review form.
    */
    // @DataMember(Order=1)
    public id?: number;

    /**
    * The additional metadata for the medical review.
    */
    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<ParticipantStudySummaryPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ParticipantStudySummaryPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ParticipantStudySummarySingleResponse(); }
}

// @Route("/pi-signoff/file/view/{Id}", "GET")
export class GetFile implements IReturn<Blob>, IGet
{
    public id?: number;

    public constructor(init?: Partial<GetFile>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetFile'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Blob(); }
}

// @Route("/pi-signoff/file/download/{Id}", "GET")
export class DownloadFile implements IReturn<Blob>, IGet
{
    public id?: number;

    public constructor(init?: Partial<DownloadFile>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DownloadFile'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Blob(); }
}

/**
* Master Group
*/
// @Route("/pi-signoff/master-group/single/id/{Id}", "GET")
// @Api(Description="Master Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=500)
// @DataContract
export class MasterGroupGetSingleById implements IReturn<MasterGroupSingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the master group to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<MasterGroupGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MasterGroupGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MasterGroupSingleResponse(); }
}

/**
* Master Group
*/
// @Route("/pi-signoff/master-group/collection", "GET")
// @Api(Description="Master Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupCollectionResponse)", StatusCode=500)
// @DataContract
export class MasterGroupGetCollection implements IReturn<MasterGroupCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<MasterGroupGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MasterGroupGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MasterGroupCollectionResponse(); }
}

/**
* Master Group
*/
// @Route("/pi-signoff/master-group/create", "POST")
// @Api(Description="Master Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=500)
// @DataContract
export class MasterGroupPostCreate implements IReturn<MasterGroupSingleResponse>
{
    /**
    * The metadata to use when creating the master group.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the master group.
    */
    // @DataMember(Order=2)
    public data: MasterGroup;

    public constructor(init?: Partial<MasterGroupPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MasterGroupPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MasterGroupSingleResponse(); }
}

/**
* Master Group
*/
// @Route("/pi-signoff/master-group/save", "POST")
// @Api(Description="Master Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=500)
// @DataContract
export class MasterGroupPostSave implements IReturn<MasterGroupSingleResponse>
{
    /**
    * The metadata to use when creating the master group.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The master group to save.
    */
    // @DataMember(Order=2)
    public data: MasterGroup;

    public constructor(init?: Partial<MasterGroupPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MasterGroupPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new MasterGroupSingleResponse(); }
}

/**
* Master Group
*/
// @Route("/pi-signoff/master-group/validate", "POST")
// @Api(Description="Master Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupValidationResponse)", StatusCode=500)
// @DataContract
export class MasterGroupPostValidate implements IReturn<MasterGroupValidationResponse>
{
    /**
    * The metadata to use when validating the master group.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The master group to validate.
    */
    // @DataMember(Order=2)
    public data: MasterGroup;

    public constructor(init?: Partial<MasterGroupPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MasterGroupPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new MasterGroupValidationResponse(); }
}

/**
* Coordinating Group
*/
// @Route("/pi-signoff/coordinating-group/single/id/{Id}", "GET")
// @Api(Description="Coordinating Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=500)
// @DataContract
export class CoordinatingGroupGetSingleById implements IReturn<CoordinatingGroupSingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the coordinating group to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<CoordinatingGroupGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CoordinatingGroupGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CoordinatingGroupSingleResponse(); }
}

/**
* Coordinating Group
*/
// @Route("/pi-signoff/coordinating-group/collection", "GET")
// @Api(Description="Coordinating Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupCollectionResponse)", StatusCode=500)
// @DataContract
export class CoordinatingGroupGetCollection implements IReturn<CoordinatingGroupCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<CoordinatingGroupGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CoordinatingGroupGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CoordinatingGroupCollectionResponse(); }
}

/**
* Coordinating Group
*/
// @Route("/pi-signoff/coordinating-group/create", "POST")
// @Api(Description="Coordinating Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=500)
// @DataContract
export class CoordinatingGroupPostCreate implements IReturn<CoordinatingGroupSingleResponse>
{
    /**
    * The metadata to use when creating the coordinating group.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the coordinating group.
    */
    // @DataMember(Order=2)
    public data: CoordinatingGroup;

    public constructor(init?: Partial<CoordinatingGroupPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CoordinatingGroupPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CoordinatingGroupSingleResponse(); }
}

/**
* Coordinating Group
*/
// @Route("/pi-signoff/coordinating-group/save", "POST")
// @Api(Description="Coordinating Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=500)
// @DataContract
export class CoordinatingGroupPostSave implements IReturn<CoordinatingGroupSingleResponse>
{
    /**
    * The metadata to use when creating the coordinating group.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The coordinating group to save.
    */
    // @DataMember(Order=2)
    public data: CoordinatingGroup;

    public constructor(init?: Partial<CoordinatingGroupPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CoordinatingGroupPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CoordinatingGroupSingleResponse(); }
}

/**
* Coordinating Group
*/
// @Route("/pi-signoff/coordinating-group/validate", "POST")
// @Api(Description="Coordinating Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupValidationResponse)", StatusCode=500)
// @DataContract
export class CoordinatingGroupPostValidate implements IReturn<CoordinatingGroupValidationResponse>
{
    /**
    * The metadata to use when validating the coordinating group.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The coordinating group to validate.
    */
    // @DataMember(Order=2)
    public data: CoordinatingGroup;

    public constructor(init?: Partial<CoordinatingGroupPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CoordinatingGroupPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CoordinatingGroupValidationResponse(); }
}

/**
* Country
*/
// @Route("/pi-signoff/country/single/id/{Id}", "GET")
// @Api(Description="Country")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=500)
// @DataContract
export class CountryGetSingleById implements IReturn<CountrySingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the country to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<CountryGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CountryGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CountrySingleResponse(); }
}

/**
* Country
*/
// @Route("/pi-signoff/country/collection", "GET")
// @Api(Description="Country")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Country.CountryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Country.CountryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Country.CountryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Country.CountryCollectionResponse)", StatusCode=500)
// @DataContract
export class CountryGetCollection implements IReturn<CountryCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<CountryGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CountryGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CountryCollectionResponse(); }
}

/**
* Country
*/
// @Route("/pi-signoff/country/create", "POST")
// @Api(Description="Country")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=500)
// @DataContract
export class CountryPostCreate implements IReturn<CountrySingleResponse>
{
    /**
    * The metadata to use when creating the country.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the country.
    */
    // @DataMember(Order=2)
    public data: Country;

    public constructor(init?: Partial<CountryPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CountryPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CountrySingleResponse(); }
}

/**
* Country
*/
// @Route("/pi-signoff/country/save", "POST")
// @Api(Description="Country")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=500)
// @DataContract
export class CountryPostSave implements IReturn<CountrySingleResponse>
{
    /**
    * The metadata to use when creating the country.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The country to save.
    */
    // @DataMember(Order=2)
    public data: Country;

    public constructor(init?: Partial<CountryPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CountryPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CountrySingleResponse(); }
}

/**
* Country
*/
// @Route("/pi-signoff/country/validate", "POST")
// @Api(Description="Country")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Country.CountryValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Country.CountryValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Country.CountryValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Country.CountryValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Country.CountryValidationResponse)", StatusCode=500)
// @DataContract
export class CountryPostValidate implements IReturn<CountryValidationResponse>
{
    /**
    * The metadata to use when validating the country.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The country to validate.
    */
    // @DataMember(Order=2)
    public data: Country;

    public constructor(init?: Partial<CountryPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CountryPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CountryValidationResponse(); }
}

/**
* Institution
*/
// @Route("/pi-signoff/institution/single/id/{Id}", "GET")
// @Api(Description="Institution")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=500)
// @DataContract
export class InstitutionGetSingleById implements IReturn<InstitutionSingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the institution to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<InstitutionGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InstitutionGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InstitutionSingleResponse(); }
}

/**
* Institution
*/
// @Route("/pi-signoff/institution/collection", "GET")
// @Api(Description="Institution")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Institution.InstitutionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Institution.InstitutionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Institution.InstitutionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Institution.InstitutionCollectionResponse)", StatusCode=500)
// @DataContract
export class InstitutionGetCollection implements IReturn<InstitutionCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<InstitutionGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InstitutionGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InstitutionCollectionResponse(); }
}

/**
* Institution
*/
// @Route("/pi-signoff/institution/create", "POST")
// @Api(Description="Institution")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=500)
// @DataContract
export class InstitutionPostCreate implements IReturn<InstitutionSingleResponse>
{
    /**
    * The metadata to use when creating the institution.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the institution.
    */
    // @DataMember(Order=2)
    public data: Institution;

    public constructor(init?: Partial<InstitutionPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InstitutionPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InstitutionSingleResponse(); }
}

/**
* Institution
*/
// @Route("/pi-signoff/institution/save", "POST")
// @Api(Description="Institution")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=500)
// @DataContract
export class InstitutionPostSave implements IReturn<InstitutionSingleResponse>
{
    /**
    * The metadata to use when creating the institution.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The institution to save.
    */
    // @DataMember(Order=2)
    public data: Institution;

    public constructor(init?: Partial<InstitutionPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InstitutionPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new InstitutionSingleResponse(); }
}

/**
* Institution
*/
// @Route("/pi-signoff/institution/validate", "POST")
// @Api(Description="Institution")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Institution.InstitutionValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Institution.InstitutionValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Institution.InstitutionValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Institution.InstitutionValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Institution.InstitutionValidationResponse)", StatusCode=500)
// @DataContract
export class InstitutionPostValidate implements IReturn<InstitutionValidationResponse>
{
    /**
    * The metadata to use when validating the institution.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The institution to validate.
    */
    // @DataMember(Order=2)
    public data: Institution;

    public constructor(init?: Partial<InstitutionPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InstitutionPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new InstitutionValidationResponse(); }
}

/**
* Patient - PISignOffPatient
*/
// @Route("/pi-signoff/patient/single/id/{Id}", "GET")
// @Api(Description="Patient - PISignOffPatient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=500)
// @DataContract
export class PatientGetSingleById implements IReturn<PatientSingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the patient to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<PatientGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientSingleResponse(); }
}

/**
* Patient - PISignOffPatient
*/
// @Route("/pi-signoff/patient/collection", "GET")
// @Api(Description="Patient - PISignOffPatient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Patient.PatientCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Patient.PatientCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Patient.PatientCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Patient.PatientCollectionResponse)", StatusCode=500)
// @DataContract
export class PatientGetCollection implements IReturn<PatientCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<PatientGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientCollectionResponse(); }
}

/**
* Patient - PISignOffPatient
*/
// @Route("/pi-signoff/patient/create", "POST")
// @Api(Description="Patient - PISignOffPatient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=500)
// @DataContract
export class PatientPostCreate implements IReturn<PatientSingleResponse>
{
    /**
    * The metadata to use when creating the patient.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the patient.
    */
    // @DataMember(Order=2)
    public data: PISignOffPatient;

    public constructor(init?: Partial<PatientPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientSingleResponse(); }
}

/**
* Patient - PISignOffPatient
*/
// @Route("/pi-signoff/patient/save", "POST")
// @Api(Description="Patient - PISignOffPatient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=500)
// @DataContract
export class PatientPostSave implements IReturn<PatientSingleResponse>
{
    /**
    * The metadata to use when creating the patient.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The patient to save.
    */
    // @DataMember(Order=2)
    public data: PISignOffPatient;

    public constructor(init?: Partial<PatientPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PatientSingleResponse(); }
}

/**
* Patient - PISignOffPatient
*/
// @Route("/pi-signoff/patient/validate", "POST")
// @Api(Description="Patient - PISignOffPatient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Patient.PatientValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Patient.PatientValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Patient.PatientValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Patient.PatientValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Patient.PatientValidationResponse)", StatusCode=500)
// @DataContract
export class PatientPostValidate implements IReturn<PatientValidationResponse>
{
    /**
    * The metadata to use when validating the patient.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The patient to validate.
    */
    // @DataMember(Order=2)
    public data: PISignOffPatient;

    public constructor(init?: Partial<PatientPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PatientValidationResponse(); }
}

/**
* Patient - PISignOffPatient
*/
// @Route("/pi-signoff/patient/single/study-number/{StudyNumber}", "GET")
// @Api(Description="Patient - PISignOffPatient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=500)
// @DataContract
export class PatientGetSingleByStudyNumber implements IReturn<PatientSingleResponse>, IPatientGetSingleByStudyNumber
{
    /**
    * The Study Number of the patient to retrieve.
    */
    // @DataMember(Order=1)
    public studyNumber: string;

    public constructor(init?: Partial<PatientGetSingleByStudyNumber>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientGetSingleByStudyNumber'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientSingleResponse(); }
}

// @Route("/pi-signoff/open-clinica/document", "GET")
export class GetOpenClinicaDocument implements IReturn<Blob>, IGet
{
    public fileName: string;

    public constructor(init?: Partial<GetOpenClinicaDocument>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetOpenClinicaDocument'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Blob(); }
}

// @Route("/pi-signoff/permissions", "POST")
export class PostHasPermissions implements IReturn<PostHasPermissionsResponse>, IPost
{
    public context: PISignOffRequestContext;
    public permissions: PISignOffPermission[];

    public constructor(init?: Partial<PostHasPermissions>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PostHasPermissions'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PostHasPermissionsResponse(); }
}

/**
* PI Sign-off Definition
*/
// @Route("/pi-signoff/pi-sign-off-definition/single/id/{Id}", "GET")
// @Api(Description="PI Sign-off Definition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.PISignOffDefinition.PISignOffDefinitionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.PISignOffDefinition.PISignOffDefinitionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.PISignOffDefinition.PISignOffDefinitionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.PISignOffDefinition.PISignOffDefinitionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.PISignOffDefinition.PISignOffDefinitionSingleResponse)", StatusCode=500)
// @DataContract
export class PISignOffDefinitionGetSingleById implements IReturn<PISignOffDefinitionSingleResponse>, IConfigurationGetSingleById
{
    /**
    * The ID of the pi sign off definition to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<PISignOffDefinitionGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PISignOffDefinitionGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PISignOffDefinitionSingleResponse(); }
}

/**
* PI Sign-off Definition
*/
// @Route("/pi-signoff/pi-sign-off-definition/collection", "GET")
// @Api(Description="PI Sign-off Definition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.PISignOffDefinition.PISignOffDefinitionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.PISignOffDefinition.PISignOffDefinitionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.PISignOffDefinition.PISignOffDefinitionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.PISignOffDefinition.PISignOffDefinitionCollectionResponse)", StatusCode=500)
// @DataContract
export class PISignOffDefinitionGetCollection implements IReturn<PISignOffDefinitionCollectionResponse>, IConfigurationGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<PISignOffDefinitionGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PISignOffDefinitionGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PISignOffDefinitionCollectionResponse(); }
}

/**
* PI Sign-off Definition
*/
// @Route("/pi-signoff/pi-sign-off-definition/single/code/{Code}", "GET")
// @Api(Description="PI Sign-off Definition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.PISignOffDefinition.PISignOffDefinitionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.PISignOffDefinition.PISignOffDefinitionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.PISignOffDefinition.PISignOffDefinitionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.PISignOffDefinition.PISignOffDefinitionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.PISignOffDefinition.PISignOffDefinitionSingleResponse)", StatusCode=500)
// @DataContract
export class PISignOffDefinitionGetSingleByCode implements IReturn<PISignOffDefinitionSingleResponse>, IConfigurationGetSingleByCode
{
    /**
    * The ID of the pi sign off definition to retrieve.
    */
    // @DataMember(Order=1)
    public code: string;

    public constructor(init?: Partial<PISignOffDefinitionGetSingleByCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PISignOffDefinitionGetSingleByCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PISignOffDefinitionSingleResponse(); }
}

/**
* Investigator
*/
// @Route("/pi-signoff/investigator/single/id/{Id}", "GET")
// @Api(Description="Investigator")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.Investigator.InvestigatorSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.Investigator.InvestigatorSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.Investigator.InvestigatorSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.Investigator.InvestigatorSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.Investigator.InvestigatorSingleResponse)", StatusCode=500)
// @DataContract
export class InvestigatorGetSingleById implements IReturn<InvestigatorSingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the investigator to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<InvestigatorGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InvestigatorGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InvestigatorSingleResponse(); }
}

/**
* Investigator
*/
// @Route("/pi-signoff/investigator/collection", "GET")
// @Api(Description="Investigator")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.Investigator.InvestigatorCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.Investigator.InvestigatorCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.Investigator.InvestigatorCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.Investigator.InvestigatorCollectionResponse)", StatusCode=500)
// @DataContract
export class InvestigatorGetCollection implements IReturn<InvestigatorCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<InvestigatorGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InvestigatorGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InvestigatorCollectionResponse(); }
}

/**
* Investigator
*/
// @Route("/pi-signoff/investigator/create", "POST")
// @Api(Description="Investigator")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.Investigator.InvestigatorSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.Investigator.InvestigatorSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.Investigator.InvestigatorSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.Investigator.InvestigatorSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.Investigator.InvestigatorSingleResponse)", StatusCode=500)
// @DataContract
export class InvestigatorPostCreate implements IReturn<InvestigatorSingleResponse>
{
    /**
    * The metadata to use when creating the investigator.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the investigator.
    */
    // @DataMember(Order=2)
    public data: Investigator;

    public constructor(init?: Partial<InvestigatorPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InvestigatorPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InvestigatorSingleResponse(); }
}

/**
* Investigator
*/
// @Route("/pi-signoff/investigator/save", "POST")
// @Api(Description="Investigator")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.Investigator.InvestigatorSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.Investigator.InvestigatorSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.Investigator.InvestigatorSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.Investigator.InvestigatorSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.Investigator.InvestigatorSingleResponse)", StatusCode=500)
// @DataContract
export class InvestigatorPostSave implements IReturn<InvestigatorSingleResponse>
{
    /**
    * The metadata to use when creating the investigator.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The investigator to save.
    */
    // @DataMember(Order=2)
    public data: Investigator;

    public constructor(init?: Partial<InvestigatorPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InvestigatorPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new InvestigatorSingleResponse(); }
}

/**
* Investigator
*/
// @Route("/pi-signoff/investigator/validate", "POST")
// @Api(Description="Investigator")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.Investigator.InvestigatorValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.Investigator.InvestigatorValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.Investigator.InvestigatorValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.Investigator.InvestigatorValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.Investigator.InvestigatorValidationResponse)", StatusCode=500)
// @DataContract
export class InvestigatorPostValidate implements IReturn<InvestigatorValidationResponse>
{
    /**
    * The metadata to use when validating the investigator.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The investigator to validate.
    */
    // @DataMember(Order=2)
    public data: Investigator;

    public constructor(init?: Partial<InvestigatorPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InvestigatorPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new InvestigatorValidationResponse(); }
}

/**
* PI Sign-off
*/
// @Route("/pi-signoff/pi-sign-off/single/id/{Id}", "GET")
// @Api(Description="PI Sign-off")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffSingleResponse)", StatusCode=500)
// @DataContract
export class PISignOffGetSingleById implements IReturn<PISignOffSingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the pi sign off to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<PISignOffGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PISignOffGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PISignOffSingleResponse(); }
}

/**
* PI Sign-off
*/
// @Route("/pi-signoff/pi-sign-off/collection", "GET")
// @Api(Description="PI Sign-off")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffCollectionResponse)", StatusCode=500)
// @DataContract
export class PISignOffGetCollection implements IReturn<PISignOffCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<PISignOffGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PISignOffGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PISignOffCollectionResponse(); }
}

/**
* PI Sign-off
*/
// @Route("/pi-signoff/pi-sign-off/create", "POST")
// @Api(Description="PI Sign-off")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffSingleResponse)", StatusCode=500)
// @DataContract
export class PISignOffPostCreate implements IReturn<PISignOffSingleResponse>
{
    /**
    * The metadata to use when creating the pi sign off.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the pi sign off.
    */
    // @DataMember(Order=2)
    public data: PISignOff;

    public constructor(init?: Partial<PISignOffPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PISignOffPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PISignOffSingleResponse(); }
}

/**
* PI Sign-off
*/
// @Route("/pi-signoff/pi-sign-off/save", "POST")
// @Api(Description="PI Sign-off")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffSingleResponse)", StatusCode=500)
// @DataContract
export class PISignOffPostSave implements IReturn<PISignOffSingleResponse>
{
    /**
    * The metadata to use when creating the pi sign off.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The pi sign off to save.
    */
    // @DataMember(Order=2)
    public data: PISignOff;

    public constructor(init?: Partial<PISignOffPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PISignOffPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PISignOffSingleResponse(); }
}

/**
* PI Sign-off
*/
// @Route("/pi-signoff/pi-sign-off/validate", "POST")
// @Api(Description="PI Sign-off")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffValidationResponse)", StatusCode=500)
// @DataContract
export class PISignOffPostValidate implements IReturn<PISignOffValidationResponse>
{
    /**
    * The metadata to use when validating the pi sign off.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The pi sign off to validate.
    */
    // @DataMember(Order=2)
    public data: PISignOff;

    public constructor(init?: Partial<PISignOffPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PISignOffPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PISignOffValidationResponse(); }
}

/**
* PI Sign-off
*/
// @Route("/pi-signoff/pi-sign-off/collection/pi-signoff-batch-id/{PISignOffBatchId}", "GET")
// @Api(Description="PI Sign-off")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffCollectionResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffCollectionResponse)", StatusCode=500)
// @DataContract
export class PISignOffGetCollectionByPISignOffBatchId implements IReturn<PISignOffCollectionResponse>, IPISignOffGetCollectionByPISignOffBatchId
{
    /**
    * The PI Sign-Off Batch ID of the pi sign off to retrieve.
    */
    // @DataMember(Order=1)
    public piSignOffBatchId?: number;

    public constructor(init?: Partial<PISignOffGetCollectionByPISignOffBatchId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PISignOffGetCollectionByPISignOffBatchId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PISignOffCollectionResponse(); }
}

/**
* PI Sign-off
*/
// @Route("/pi-signoff/pi-sign-off/single/pi-signoff-batch-id-patient-id/{PISignOffBatchId}/{PatientId}", "GET")
// @Api(Description="PI Sign-off")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffSingleResponse)", StatusCode=500)
// @DataContract
export class PISignOffGetSingleByPISignOffBatchIdAndPatientId implements IReturn<PISignOffSingleResponse>, IPISignOffGetSingleByPISignOffBatchIdAndPatientId
{
    /**
    * The ID of the related Patient.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The ID of the related PI Sign-Off Batch.
    */
    // @DataMember(Order=2)
    public piSignOffBatchId?: number;

    public constructor(init?: Partial<PISignOffGetSingleByPISignOffBatchIdAndPatientId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PISignOffGetSingleByPISignOffBatchIdAndPatientId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PISignOffSingleResponse(); }
}

/**
* PI Sign-off
*/
// @Route("/pi-signoff/pi-sign-off/update-signoff", "POST")
// @Api(Description="PI Sign-off")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffSingleResponse)", StatusCode=500)
// @DataContract
export class PISignOffPostUpdateSignOff implements IReturn<PISignOffSingleResponse>, IPISignOffPostUpdateSignOff
{
    /**
    * The metadata to use when creating the pi sign off.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The Id of the pi sign off.
    */
    // @DataMember(Order=2)
    public piSignOffId?: number;

    /**
    * The options to use when creating the pi sign off.
    */
    // @DataMember(Order=2)
    public options: PISignOffUpdateOptions;

    public constructor(init?: Partial<PISignOffPostUpdateSignOff>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PISignOffPostUpdateSignOff'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PISignOffSingleResponse(); }
}

/**
* PI Sign-off
*/
// @Route("/pi-signoff/pi-sign-off/collection/validate-forms", "GET")
// @Api(Description="PI Sign-off")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffValidationCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffValidationCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffValidationCollectionResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffValidationCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffValidationCollectionResponse)", StatusCode=500)
// @DataContract
export class PISignOffPostFormValidationCollectionById implements IReturn<PISignOffValidationCollectionResponse>, IPISignOffPostFormValidationCollectionById
{
    /**
    * The metadata to use when validating the pi sign off forms.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The Id of the pi sign off to medical review.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<PISignOffPostFormValidationCollectionById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PISignOffPostFormValidationCollectionById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PISignOffValidationCollectionResponse(); }
}

/**
* PI Sign-Off Batch
*/
// @Route("/pi-signoff/pi-sign-off-batch/single/id/{Id}", "GET")
// @Api(Description="PI Sign-Off Batch")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchSingleResponse)", StatusCode=500)
// @DataContract
export class PISignOffBatchGetSingleById implements IReturn<PISignOffBatchSingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the pi sign off batch to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<PISignOffBatchGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PISignOffBatchGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PISignOffBatchSingleResponse(); }
}

/**
* PI Sign-Off Batch
*/
// @Route("/pi-signoff/pi-sign-off-batch/collection", "GET")
// @Api(Description="PI Sign-Off Batch")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchCollectionResponse)", StatusCode=500)
// @DataContract
export class PISignOffBatchGetCollection implements IReturn<PISignOffBatchCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<PISignOffBatchGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PISignOffBatchGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PISignOffBatchCollectionResponse(); }
}

/**
* PI Sign-Off Batch
*/
// @Route("/pi-signoff/pi-sign-off-batch/create", "POST")
// @Api(Description="PI Sign-Off Batch")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchSingleResponse)", StatusCode=500)
// @DataContract
export class PISignOffBatchPostCreate implements IReturn<PISignOffBatchSingleResponse>
{
    /**
    * The metadata to use when creating the pi sign off batch.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the pi sign off batch.
    */
    // @DataMember(Order=2)
    public data: PISignOffBatch;

    public constructor(init?: Partial<PISignOffBatchPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PISignOffBatchPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PISignOffBatchSingleResponse(); }
}

/**
* PI Sign-Off Batch
*/
// @Route("/pi-signoff/pi-sign-off-batch/save", "POST")
// @Api(Description="PI Sign-Off Batch")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchSingleResponse)", StatusCode=500)
// @DataContract
export class PISignOffBatchPostSave implements IReturn<PISignOffBatchSingleResponse>
{
    /**
    * The metadata to use when creating the pi sign off batch.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The pi sign off batch to save.
    */
    // @DataMember(Order=2)
    public data: PISignOffBatch;

    public constructor(init?: Partial<PISignOffBatchPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PISignOffBatchPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PISignOffBatchSingleResponse(); }
}

/**
* PI Sign-Off Batch
*/
// @Route("/pi-signoff/pi-sign-off-batch/validate", "POST")
// @Api(Description="PI Sign-Off Batch")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchValidationResponse)", StatusCode=500)
// @DataContract
export class PISignOffBatchPostValidate implements IReturn<PISignOffBatchValidationResponse>
{
    /**
    * The metadata to use when validating the pi sign off batch.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The pi sign off batch to validate.
    */
    // @DataMember(Order=2)
    public data: PISignOffBatch;

    public constructor(init?: Partial<PISignOffBatchPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PISignOffBatchPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PISignOffBatchValidationResponse(); }
}

/**
* PI Sign-Off Batch
*/
// @Route("/pi-signoff/pi-sign-off-batch/create-signoff", "POST")
// @Api(Description="PI Sign-Off Batch")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchCreationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchCreationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchCreationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchCreationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchCreationSingleResponse)", StatusCode=500)
// @DataContract
export class PISignOffBatchPostCreateSignOff implements IReturn<PISignOffBatchCreationSingleResponse>
{
    /**
    * The metadata to use when creating the pi sign off batch.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the pi sign off batch.
    */
    // @DataMember(Order=2)
    public data: PISignOffBatch;

    /**
    * The options to use when creating the pi sign off batch.
    */
    // @DataMember(Order=2)
    public options: PISignOffBatchCreationOptions;

    public constructor(init?: Partial<PISignOffBatchPostCreateSignOff>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PISignOffBatchPostCreateSignOff'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PISignOffBatchCreationSingleResponse(); }
}

/**
* PI Sign-Off Batch
*/
// @Route("/pi-signoff/pi-sign-off-batch/single/pi-signoff-batch-id-patient-id/{PISignOffDefinitionId}/{Repeat}", "GET")
// @Api(Description="PI Sign-Off Batch")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchSingleResponse)", StatusCode=500)
// @DataContract
export class PISignOffBatchGetSingleByPISignOffDefinitionIdAndRepeat implements IReturn<PISignOffBatchSingleResponse>, IPISignOffBatchGetSingleByPISignOffDefinitionIdAndRepeat
{
    /**
    * The Definition ID of the PI Sign-Off Batch.
    */
    // @DataMember(Order=1)
    public piSignOffDefinitionId?: number;

    /**
    * The Repeat of the related PI Sign-Off Batch.
    */
    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<PISignOffBatchGetSingleByPISignOffDefinitionIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PISignOffBatchGetSingleByPISignOffDefinitionIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PISignOffBatchSingleResponse(); }
}

/**
* Query
*/
// @Route("/pi-signoff/query/single/id/{Id}", "GET")
// @Api(Description="Query")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.Query.QuerySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.Query.QuerySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.Query.QuerySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.Query.QuerySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.Query.QuerySingleResponse)", StatusCode=500)
// @DataContract
export class QueryGetSingleById implements IReturn<QuerySingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the query to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<QueryGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QueryGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QuerySingleResponse(); }
}

/**
* Query
*/
// @Route("/pi-signoff/query/collection", "GET")
// @Api(Description="Query")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.Query.QueryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.Query.QueryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.Query.QueryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.Query.QueryCollectionResponse)", StatusCode=500)
// @DataContract
export class QueryGetCollection implements IReturn<QueryCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<QueryGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QueryGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryCollectionResponse(); }
}

/**
* Query
*/
// @Route("/pi-signoff/query/create", "POST")
// @Api(Description="Query")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.Query.QuerySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.Query.QuerySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.Query.QuerySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.Query.QuerySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.Query.QuerySingleResponse)", StatusCode=500)
// @DataContract
export class QueryPostCreate implements IReturn<QuerySingleResponse>
{
    /**
    * The metadata to use when creating the query.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the query.
    */
    // @DataMember(Order=2)
    public data: Query;

    public constructor(init?: Partial<QueryPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QueryPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QuerySingleResponse(); }
}

/**
* Query
*/
// @Route("/pi-signoff/query/save", "POST")
// @Api(Description="Query")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.Query.QuerySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.Query.QuerySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.Query.QuerySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.Query.QuerySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.Query.QuerySingleResponse)", StatusCode=500)
// @DataContract
export class QueryPostSave implements IReturn<QuerySingleResponse>
{
    /**
    * The metadata to use when creating the query.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The query to save.
    */
    // @DataMember(Order=2)
    public data: Query;

    public constructor(init?: Partial<QueryPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QueryPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new QuerySingleResponse(); }
}

/**
* Query
*/
// @Route("/pi-signoff/query/validate", "POST")
// @Api(Description="Query")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.Query.QueryValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.Query.QueryValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.Query.QueryValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.Query.QueryValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.Query.QueryValidationResponse)", StatusCode=500)
// @DataContract
export class QueryPostValidate implements IReturn<QueryValidationResponse>
{
    /**
    * The metadata to use when validating the query.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The query to validate.
    */
    // @DataMember(Order=2)
    public data: Query;

    public constructor(init?: Partial<QueryPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QueryPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new QueryValidationResponse(); }
}

/**
* Query
*/
// @Route("/pi-signoff/query/collection/pi-signoff-id/{PISignOffId}", "GET")
// @Api(Description="Query")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.Query.QueryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.Query.QueryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.Query.QueryCollectionResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.Query.QueryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.Query.QueryCollectionResponse)", StatusCode=500)
// @DataContract
export class QueryGetCollectionByPISignOffId implements IReturn<QueryCollectionResponse>, IQueryGetCollectionByPISignOffId
{
    /**
    * The ID of the PI sign-off to retrieve query for.
    */
    // @DataMember(Order=1)
    public piSignOffId?: number;

    public constructor(init?: Partial<QueryGetCollectionByPISignOffId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QueryGetCollectionByPISignOffId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryCollectionResponse(); }
}

/**
* Query
*/
// @Route("/pi-signoff/query/save/collection", "POST")
// @Api(Description="Query")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.Query.QueryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.Query.QueryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.Query.QueryCollectionResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.Query.QueryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.Query.QueryCollectionResponse)", StatusCode=500)
// @DataContract
export class QueryPostSaveCollection implements IReturn<QueryCollectionResponse>
{
    /**
    * The metadata to use when creating the query.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The query to save.
    */
    // @DataMember(Order=2)
    public data: Query[];

    public constructor(init?: Partial<QueryPostSaveCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QueryPostSaveCollection'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new QueryCollectionResponse(); }
}

/**
* UpdatedFormProperty
*/
// @Route("/pi-signoff/updatedformproperty/single/id/{Id}", "GET")
// @Api(Description="UpdatedFormProperty")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=500)
// @DataContract
export class UpdatedFormPropertyGetSingleById implements IReturn<UpdatedFormPropertySingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the updatedformproperty to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<UpdatedFormPropertyGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdatedFormPropertyGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new UpdatedFormPropertySingleResponse(); }
}

/**
* UpdatedFormProperty
*/
// @Route("/pi-signoff/updatedformproperty/collection", "GET")
// @Api(Description="UpdatedFormProperty")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertyCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertyCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertyCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertyCollectionResponse)", StatusCode=500)
// @DataContract
export class UpdatedFormPropertyGetCollection implements IReturn<UpdatedFormPropertyCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<UpdatedFormPropertyGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdatedFormPropertyGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new UpdatedFormPropertyCollectionResponse(); }
}

/**
* UpdatedFormProperty
*/
// @Route("/pi-signoff/updatedformproperty/create", "POST")
// @Api(Description="UpdatedFormProperty")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=500)
// @DataContract
export class UpdatedFormPropertyPostCreate implements IReturn<UpdatedFormPropertySingleResponse>
{
    /**
    * The metadata to use when creating the updatedformproperty.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the updatedformproperty.
    */
    // @DataMember(Order=2)
    public data: UpdatedFormProperty;

    public constructor(init?: Partial<UpdatedFormPropertyPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdatedFormPropertyPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new UpdatedFormPropertySingleResponse(); }
}

/**
* UpdatedFormProperty
*/
// @Route("/pi-signoff/updatedformproperty/save", "POST")
// @Api(Description="UpdatedFormProperty")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=500)
// @DataContract
export class UpdatedFormPropertyPostSave implements IReturn<UpdatedFormPropertySingleResponse>
{
    /**
    * The metadata to use when creating the updatedformproperty.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The updatedformproperty to save.
    */
    // @DataMember(Order=2)
    public data: UpdatedFormProperty;

    public constructor(init?: Partial<UpdatedFormPropertyPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdatedFormPropertyPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new UpdatedFormPropertySingleResponse(); }
}

/**
* UpdatedFormProperty
*/
// @Route("/pi-signoff/updatedformproperty/validate", "POST")
// @Api(Description="UpdatedFormProperty")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertyValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertyValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertyValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertyValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertyValidationResponse)", StatusCode=500)
// @DataContract
export class UpdatedFormPropertyPostValidate implements IReturn<UpdatedFormPropertyValidationResponse>
{
    /**
    * The metadata to use when validating the updatedformproperty.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The updatedformproperty to validate.
    */
    // @DataMember(Order=2)
    public data: UpdatedFormProperty;

    public constructor(init?: Partial<UpdatedFormPropertyPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdatedFormPropertyPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new UpdatedFormPropertyValidationResponse(); }
}

/**
* UpdatedFormProperty
*/
// @Route("/pi-signoff/updatedformproperty/collection/form-definition-id-form-id/{FormDefinitionId}/{FormId}", "GET")
// @Api(Description="UpdatedFormProperty")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertyCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertyCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertyCollectionResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertyCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertyCollectionResponse)", StatusCode=500)
// @DataContract
export class UpdatedFormPropertyGetCollectionByFormDefinitionIdAndFormId implements IReturn<UpdatedFormPropertyCollectionResponse>, IUpdatedFormPropertyGetCollectionByFormDefinitionIdAndFormId
{
    /**
    * The Form Definition ID of the updatedformproperty to retrieve.
    */
    // @DataMember(Order=1)
    public formDefinitionId?: number;

    /**
    * The Form ID of the updatedformproperty to retrieve.
    */
    // @DataMember(Order=1)
    public formId?: number;

    public constructor(init?: Partial<UpdatedFormPropertyGetCollectionByFormDefinitionIdAndFormId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdatedFormPropertyGetCollectionByFormDefinitionIdAndFormId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new UpdatedFormPropertyCollectionResponse(); }
}

