import React, { Context, FunctionComponent, Suspense, useContext } from 'react';
import { FormsContext, FormsLayout } from '@ngt/forms';
import { useNavigate, Route, BrowserRouter, Routes } from 'react-router-dom';
import {
    PISignOffBatchesRoute,
    PISignOffBatchRoute,
    PISignOffRoute,
    PISignOffFormRoute,
    PISignOffQueriesRoute,
    PISignOffExtensionContext,
    PISignOffBatchesContainer,
    PISignOffBatchContainer,
    PISignOffQuerySummaryContainer,
    IPISignOffFormMapping,
    //MedicalReview,
    //MedicalReviewFormByPatientStudyNumberMedicalReviewRepeatAndRepeatRoute,
    //IPISignOffFormMapping,
    //MedicalReviewsByPatientStudyNumberRoute,
    //MedicalReviewByPatientStudyNumberAndRepeatRoute,
    //MedicalReviewQueriesByPatientStudyNumberAndRepeatRoute,
    //QuerySummary
} from '@ngt/forms-pi-signoff';
import { TrialsExtensionContext } from '@ngt/forms-trials';
import IFramePage from './containers/IFramePage';
import ParticipantStudySummary from './containers/form/ParticipantStudySummary';
import Error from './containers//Error';
import { IPISignOffExtensionOptions } from '@ngt/forms-pi-signoff/dist/PISignOffExtension';
import { Box } from '@mui/material';
import PISignOffContainer from './containers/PISignOff';

const formMapping: Record<string, IPISignOffFormMapping> = {
    "participant-study-summary": {
        components: <ParticipantStudySummary />
    }
}

const piSignOffBatchesConfiguration = { refreshInterval: 5000 };

const App: FunctionComponent = () => {
    const formsContext = useContext(FormsContext);
    const trialsExtensionContext = useContext(TrialsExtensionContext);
    const piSignOffExtensionContext: IPISignOffExtensionOptions = useContext(PISignOffExtensionContext);

    return (
        <Suspense fallback="/pi-signoff">
            {
                <FormsLayout>
                    <Routes>
                        <Route path={'/error'}
                            element={
                                <Error />
                            }
                        />
                        <Route path={'/error/:code'}
                            element={
                                <Error />
                            }
                        />
                        <Route
                            path="/report/queries"
                            element={
                                <IFramePage url="/neonapi/report/queries" />
                            }
                        />
                        <Route
                            path={`/:${piSignOffExtensionContext.routeParameters.piSignOffDefinitionCode}/:${piSignOffExtensionContext.routeParameters.piSignOffRepeat}/:${trialsExtensionContext.routeParameters.patientStudyNumber}/queries`}
                            element={
                                <PISignOffQueriesRoute
                                    resolveBeforeLoad
                                >
                                    <PISignOffQuerySummaryContainer />
                                </PISignOffQueriesRoute>
                            }
                        />
                        <Route
                            path={`/:${piSignOffExtensionContext.routeParameters.piSignOffDefinitionCode}/:${piSignOffExtensionContext.routeParameters.piSignOffRepeat}/:${trialsExtensionContext.routeParameters.patientStudyNumber}/:${formsContext.routeParameters.formDefinitionCode}`}
                            element={
                                <PISignOffFormRoute
                                    formMapping={formMapping}
                                    resolveBeforeLoad
                                />
                            }
                        />
                        <Route
                            path={`/:${piSignOffExtensionContext.routeParameters.piSignOffDefinitionCode}/:${piSignOffExtensionContext.routeParameters.piSignOffRepeat}/:${trialsExtensionContext.routeParameters.patientStudyNumber}/:${formsContext.routeParameters.formDefinitionCode}/:${formsContext.routeParameters.formRepeat}` }
                            element={
                                <PISignOffFormRoute
                                    formMapping={formMapping}
                                    resolveBeforeLoad
                                />
                            }
                        />
                        <Route
                            path={`/:${piSignOffExtensionContext.routeParameters.piSignOffDefinitionCode}/:${piSignOffExtensionContext.routeParameters.piSignOffRepeat}/:${trialsExtensionContext.routeParameters.patientStudyNumber}`}
                            element={
                                <PISignOffRoute
                                    resolveBeforeLoad
                                >
                                    <PISignOffContainer />
                                </PISignOffRoute>
                            }
                        />
                        <Route
                            path={`/:${piSignOffExtensionContext.routeParameters.piSignOffDefinitionCode}/:${piSignOffExtensionContext.routeParameters.piSignOffRepeat}`}
                            element={
                                <PISignOffBatchRoute
                                    resolveBeforeLoad
                                >
                                    <PISignOffBatchContainer />
                                </PISignOffBatchRoute>
                            }
                        />
                        <Route
                            path="/pi-signoff"
                            element={
                                <PISignOffBatchesRoute
                                    resolveBeforeLoad
                                    piSignOffBatchesConfiguration={piSignOffBatchesConfiguration}
                                >
                                    <PISignOffBatchesContainer />
                                </PISignOffBatchesRoute>
                            }
                        />
                        <Route
                            path="*"
                            element={
                                <PISignOffBatchesRoute
                                    resolveBeforeLoad
                                    piSignOffBatchesConfiguration={piSignOffBatchesConfiguration}
                                >
                                    <PISignOffBatchesContainer />
                                </PISignOffBatchesRoute>
                            }
                        />
                    </Routes>
                </FormsLayout>
            }
        </Suspense>
    );
};

export default App;
