import { Accordion, AccordionDetails, AccordionSummary, Box, SxProps, Table, TableBody, TableCell, TableHead, TableRow, Theme, Typography } from '@mui/material';
import { BooleanDisplay, DateDisplay, FieldProvider, FormLabel, getParentPropertyPath, GroupedFields, IGroupedFieldStyleProps, Input, IValidationError, NumericDisplay, TextDisplay } from '@ngt/forms';
import { Condition, ContextFormTitle, ContextPISignOffForm, GroupedField, GroupedSubformGrid, ICondition, IGroupedSubformGridCell, InputOnlyField, OpenClinicaFormDisplay } from '@ngt/forms-pi-signoff';
import React, { FunctionComponent, ReactNode } from 'react';
import { SecondPrimaryMalignancy, SurvivalFollowUp, BreastCancerRecurrence, DeathReport, ParticipantStudySummary, ParticipantStudySummary as RecurrenceSurvivalDto } from '../../apis/dtos';
import IfElseDisplay from '../../components/form/input/display/IfElseDisplay';
import { DateTime } from 'luxon';
import ExpectedDoseDisplay from '../../components/form/input/display/ExpectedDoseDisplay';
import ValueWithReasonAndSpecificationDisplay from '../../components/form/input/display/ValueWithReasonAndSpecificationDisplay';
import { ConditionMode, FieldContext, useFieldState, useScopedField } from '@ngt/forms-core';
import { BasicAlert } from '@ngt/forms-trials';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { faChevronDown } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles } from '@mui/styles';

declare let global: any;

if (typeof global === 'undefined') {
    (window as any).global = window;
}

const openClinicaUrl = global.OPEN_CLINICA_CRF_URL as string;

interface ISurvivalFollowUpAndRecurrenceProps {
}

const useStyles = makeStyles((theme: Theme) => ({
    title: {
        padding: theme.spacing(2, 2, 2)
    },
    titleContainer: {
        padding: theme.spacing(2, 2, 2),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    subtitle: {
        padding: theme.spacing(2, 2, 0)
    },
    patientInformation: {
        padding: theme.spacing(2, 2, 0)
    },
    alert: {
        padding: theme.spacing(2)
    },
    buttonGroup: {
        padding: theme.spacing(2),
        textAlign: 'right'
    },
    grid: {
        padding: theme.spacing(2, 0)
    },
    accordionSubtitle: {
        color: 'white'
    },
    heading: {
        padding: theme.spacing(2, 0, 1),
    },
    subheading: {
        padding: theme.spacing(2, 0),
    },
    accordionSummary: {
        backgroundColor: theme.palette.primary.main,
        margin: theme.spacing(0, 2),
        color: 'white'
    },
    accordionDetails: {
        flexDirection: 'column',
        margin: theme.spacing(0, 2),
    },
    accordion: {
        "&.MuiAccordion-root:before": {
            padding: theme.spacing(0, 2),
            margin: theme.spacing(0, 2)
        }
    },
    table: {
        fontSize: `0.875rem !important`,
        background: 'inherit',

        '& .MuiTypography-root': {
            fontSize: `0.875rem !important`,
        },
        '& .MuiGrid-root': {
            background: 'inherit',
        }
    }
}));

const groupStyleProps = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 7,
    xl: 7
};

const conditionSubscription = { value: true };

const VisitNumberHighlight: FunctionComponent<{ children?: ReactNode, sx?: SxProps }> = ({ children, sx }) => {
    const parentContext = React.useContext(FieldContext);

    const parentName = getParentPropertyPath(parentContext?.name);

    const { state: { value: visitNumber } } = useScopedField<number, IValidationError>(`${parentName}.visitNumber`, conditionSubscription, false);

    return (
        <Box
            sx={{
                padding: '1rem',
                height: '100%',
                background: `${(visitNumber ?? 0) % 2 == 0 ? 'inherit' : 'rgba(194, 181, 208, 0.4)'}`
            }}
        >
            {children}
        </Box>
    );
}

const hasNoSurvivalFollowUp: ICondition<RecurrenceSurvivalDto> = ({ formState: { value } = {} }) => !value?.survivalFollowUps || value?.survivalFollowUps?.length === 0;
const hasSurvivalFollowUp: ICondition<RecurrenceSurvivalDto> = ({ formState: { value } = {} }) => !!value?.survivalFollowUps && value?.survivalFollowUps?.length > 0;

const hasNoBreastCancerRecurrence: ICondition<RecurrenceSurvivalDto> = ({ formState: { value } = {} }) => !value?.breastCancerRecurrences || value?.breastCancerRecurrences?.length === 0;
const hasBreastCancerRecurrence: ICondition<RecurrenceSurvivalDto> = ({ formState: { value } = {} }) => !!value?.breastCancerRecurrences && value?.breastCancerRecurrences?.length > 0;

const hasNoSecondPrimaryMalignancy: ICondition<RecurrenceSurvivalDto> = ({ formState: { value } = {} }) => !value?.secondPrimaryMalignancies || value?.secondPrimaryMalignancies?.length === 0;
const hasSecondPrimaryMalignancy: ICondition<RecurrenceSurvivalDto> = ({ formState: { value } = {} }) => !!value?.secondPrimaryMalignancies && value?.secondPrimaryMalignancies?.length > 0;

const hasNoDeathReport: ICondition<RecurrenceSurvivalDto> = ({ formState: { value } = {} }) => !value?.deathReports || value?.deathReports?.length === 0;
const hasDeathReport: ICondition<RecurrenceSurvivalDto> = ({ formState: { value } = {} }) => !!value?.deathReports && value?.deathReports?.length > 0;

const survivalFollowUpColumns: IGroupedSubformGridCell<SurvivalFollowUp>[] = [
    {
        name: 'event',
        content: (
            <Input
                component={OpenClinicaFormDisplay}
                openClinicaUrl={openClinicaUrl}
                eventCrfIdName="eventCrfId"
                studyEventIdName="studyEventId"
                btnMinWidth="140px"
                eventCrfIdPrependParentName
                eventCrfIdTableCell
                studyEventIdPrependParentName
                studyEventIdTableCell
                textValueButton
            />
        ),
        sortValue: (row) => row?.event,
        WrappingComponent: ({ children }) => <VisitNumberHighlight>
            {children}
        </VisitNumberHighlight>,
    },
    {
        name: 'visitNumber',
        content: (
            <Input
                component={TextDisplay}
            />
        ),

        sortValue: (row) => row?.visitNumber,
        WrappingComponent: ({ children }) => <VisitNumberHighlight>
            {children}
        </VisitNumberHighlight>
    },
    {
        name: 'dateOfVisit',
        content: (
            <Input
                component={DateDisplay}
            />
        ),
        sortValue: (row) => row?.dateOfVisit,
        WrappingComponent: ({ children }) => <VisitNumberHighlight>
            {children}
        </VisitNumberHighlight>
    },
    {
        name: 'changesObserved',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.changesObserved,
        WrappingComponent: ({ children }) => <VisitNumberHighlight>
            {children}
        </VisitNumberHighlight>
    },
    {
        name: 'antiCancerTreatment',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.antiCancerTreatment,
        WrappingComponent: ({ children }) => <VisitNumberHighlight>
            {children}
        </VisitNumberHighlight>
    },
    {
        name: 'dateCommenced',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.dateCommenced,
        WrappingComponent: ({ children }) => <VisitNumberHighlight>
            {children}
        </VisitNumberHighlight>
    },
    {
        name: 'dateCompleted',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.dateCompleted,
        WrappingComponent: ({ children }) => <VisitNumberHighlight>
            {children}
        </VisitNumberHighlight>
    }
];

const breastCancerRecurrenceColumns: IGroupedSubformGridCell<BreastCancerRecurrence>[] = [
    {
        name: 'event',
        content: (
            <Input
                component={OpenClinicaFormDisplay}
                openClinicaUrl={openClinicaUrl}
                eventCrfIdName="eventCrfId"
                studyEventIdName="studyEventId"
                btnMinWidth="140px"
                eventCrfIdPrependParentName
                eventCrfIdTableCell
                studyEventIdPrependParentName
                studyEventIdTableCell
                textValueButton
            />
        ),
        sortValue: (row) => row?.event
    },
    {
        name: 'type',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.type
    },
    {
        name: 'location',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.location
    },
    {
        name: 'dateOfFirstSuspicion',
        content: (
            <Input
                component={DateDisplay}
            />
        ),
        sortValue: (row) => row?.dateOfFirstSuspicion
    },
    {
        name: 'dateOfConfirmation',
        content: (
            <Input
                component={DateDisplay}
            />
        ),
        sortValue: (row) => row?.dateOfConfirmation
    },
    {
        name: 'timeOnStudy',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.timeOnStudy
    },
    {
        name: 'typeOfConfirmation',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.typeOfConfirmation
    }
];

const secondPrimaryMalignancyColumns: IGroupedSubformGridCell<SecondPrimaryMalignancy>[] = [
    {
        name: 'event',
        content: (
            <Input
                component={OpenClinicaFormDisplay}
                openClinicaUrl={openClinicaUrl}
                eventCrfIdName="eventCrfId"
                studyEventIdName="studyEventId"
                btnMinWidth="140px"
                eventCrfIdPrependParentName
                eventCrfIdTableCell
                studyEventIdPrependParentName
                studyEventIdTableCell
                textValueButton
            />
        ),
        sortValue: (row) => row?.event
    },
    {
        name: 'location',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.location
    },
    {
        name: 'dateOfFirstSuspicion',
        content: (
            <Input
                component={DateDisplay}
            />
        ),
        sortValue: (row) => row?.dateOfFirstSuspicion
    },
    {
        name: 'dateOfConfirmation',
        content: (
            <Input
                component={DateDisplay}
            />
        ),
        sortValue: (row) => row?.dateOfConfirmation
    },
    {
        name: 'timeOnStudy',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.timeOnStudy
    },
    {
        name: 'typeOfConfirmation',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.typeOfConfirmation
    }
];

const deathReportColumns: IGroupedSubformGridCell<DeathReport>[] = [
    {
        name: 'event',
        content: (
            <Input
                component={OpenClinicaFormDisplay}
                openClinicaUrl={openClinicaUrl}
                eventCrfIdName="eventCrfId"
                studyEventIdName="studyEventId"
                btnMinWidth="140px"
                eventCrfIdPrependParentName
                eventCrfIdTableCell
                studyEventIdPrependParentName
                studyEventIdTableCell
                textValueButton
            />
        ),
        sortValue: (row) => row?.event
    },
    {
        name: 'dateOfDeath',
        content: (
            <Input
                component={DateDisplay}
            />
        ),
        sortValue: (row) => row?.dateOfDeath
    },
    {
        name: 'timeOnStudy',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.timeOnStudy
    },
    {
        name: 'causeOfDeath',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.causeOfDeath
    }
];

const SurvivalFollowUpAndRecurrence: FunctionComponent<ISurvivalFollowUpAndRecurrenceProps> = () => {
    const classes = useStyles();

    return (
        <>
            <Accordion
                defaultExpanded
                elevation={0}
                classes={{
                    root: classes.accordion
                }}
            >
                <AccordionSummary
                    expandIcon={<FontAwesomeIcon icon={faChevronDown} color="white" />}
                    className={classes.accordionSummary}
                >
                    <Typography variant="h2" className={classes.accordionSubtitle}>
                        Survival Follow-Up & Recurrence
                    </Typography>
                </AccordionSummary>
                <AccordionDetails
                    className={classes.accordionDetails}
                >
                    <GroupedFields>
                        <Typography variant="h2" className={classes.subtitle}>
                            Survival Follow Up
                        </Typography>
                        <Condition
                            type={RecurrenceSurvivalDto}
                            condition={hasSurvivalFollowUp}
                            subscription={conditionSubscription}
                            mode={ConditionMode.Show}
                        >
                            <Box className={`${classes.table} survivalFollowUp`}>
                                <GroupedSubformGrid
                                    type={SurvivalFollowUp}
                                    name="survivalFollowUps"
                                    columns={survivalFollowUpColumns}
                                    disabled
                                    stickHeader
                                    tableStyle={{
                                        height: '100%',

                                        '.MuiTableBody-root': {
                                            height: '100%',

                                            '& tr': {
                                                height: '100%',

                                                '& td': {
                                                    padding: 0,
                                                    height: '100%',

                                                    '& *': {
                                                        height: '100%',
                                                    }
                                                }
                                            }
                                        },
                                    }}
                                />
                            </Box>
                        </Condition>
                        <Condition
                            type={RecurrenceSurvivalDto}
                            condition={hasNoSurvivalFollowUp}
                            subscription={conditionSubscription}
                            mode={ConditionMode.Show}
                        >
                            <div className={classes.alert}>
                                <BasicAlert
                                    title="No Survival Follow Up"
                                    description="There is no Survival Follow Up associated with this patient."
                                    icon={faInfoCircle}
                                    severity="info"
                                />
                            </div>
                        </Condition>
                    </GroupedFields>
                    <GroupedFields>
                        <Typography variant="h2" className={classes.subtitle}>
                            Breast Cancer Recurrence
                        </Typography>
                        <Condition
                            type={RecurrenceSurvivalDto}
                            condition={hasBreastCancerRecurrence}
                            subscription={conditionSubscription}
                            mode={ConditionMode.Show}
                        >
                            <Box className={classes.table}>
                                <GroupedSubformGrid
                                    type={BreastCancerRecurrence}
                                    name="breastCancerRecurrences"
                                    columns={breastCancerRecurrenceColumns}
                                    disabled
                                    stickHeader
                                />
                            </Box>
                        </Condition>
                        <Condition
                            type={RecurrenceSurvivalDto}
                            condition={hasNoBreastCancerRecurrence}
                            subscription={conditionSubscription}
                            mode={ConditionMode.Show}
                        >
                            <div className={classes.alert}>
                                <BasicAlert
                                    title="No Breast Cancer Recurrence"
                                    description="There is no Breast Cancer Recurrence associated with this patient."
                                    icon={faInfoCircle}
                                    severity="info"
                                />
                            </div>
                        </Condition>
                    </GroupedFields>
                    <GroupedFields>
                        <Typography variant="h2" className={classes.subtitle}>
                            Second Primary Malignancy
                        </Typography>
                        <Condition
                            type={RecurrenceSurvivalDto}
                            condition={hasSecondPrimaryMalignancy}
                            subscription={conditionSubscription}
                            mode={ConditionMode.Show}
                        >
                            <Box className={classes.table}>
                                <GroupedSubformGrid
                                    type={SecondPrimaryMalignancy}
                                    name="secondPrimaryMalignancies"
                                    columns={secondPrimaryMalignancyColumns}
                                    disabled
                                    stickHeader
                                />
                            </Box>
                        </Condition>
                        <Condition
                            type={RecurrenceSurvivalDto}
                            condition={hasNoSecondPrimaryMalignancy}
                            subscription={conditionSubscription}
                            mode={ConditionMode.Show}
                        >
                            <div className={classes.alert}>
                                <BasicAlert
                                    title="No Second Primary Malignancy"
                                    description="There is no Second Primary Malignancy associated with this patient."
                                    icon={faInfoCircle}
                                    severity="info"
                                />
                            </div>
                        </Condition>
                    </GroupedFields>
                    <GroupedFields>
                        <Typography variant="h2" className={classes.subtitle}>
                            Death Report Form
                        </Typography>
                        <Condition
                            type={RecurrenceSurvivalDto}
                            condition={hasDeathReport}
                            subscription={conditionSubscription}
                            mode={ConditionMode.Show}
                        >
                            <Box className={classes.table}>
                                <GroupedSubformGrid
                                    type={DeathReport}
                                    name="deathReports"
                                    columns={deathReportColumns}
                                    disabled
                                    stickHeader
                                />
                            </Box>
                        </Condition>
                        <Condition
                            type={RecurrenceSurvivalDto}
                            condition={hasNoDeathReport}
                            subscription={conditionSubscription}
                            mode={ConditionMode.Show}
                        >
                            <div className={classes.alert}>
                                <BasicAlert
                                    title="No Death Report Form"
                                    description="There is no Death Report Form associated with this patient."
                                    icon={faInfoCircle}
                                    severity="info"
                                />
                            </div>
                        </Condition>
                    </GroupedFields>
                </AccordionDetails>
            </Accordion>
        </>
    );
};

export default SurvivalFollowUpAndRecurrence;
