import { Accordion, AccordionDetails, AccordionSummary, Box, Table, TableBody, TableCell, TableHead, TableRow, Theme, Typography } from '@mui/material';
import { BooleanDisplay, DateDisplay, FieldProvider, FormLabel, getParentPropertyPath, GroupedFields, IGroupedFieldStyleProps, Input, NumericDisplay, TextDisplay } from '@ngt/forms';
import { Condition, ContextFormTitle, ContextPISignOffForm, ICondition, GroupedSubformGrid, IGroupedSubformGridCell, InputOnlyField, GroupedField, OpenClinicaFormDisplay } from '@ngt/forms-pi-signoff';
import React, { FunctionComponent } from 'react';
import { ConcomitantMedication, ParticipantStudySummary as ConcomitantMedicationsDto, MedicalHistoryMedication } from '../../apis/dtos';
import IfElseDisplay from '../../components/form/input/display/IfElseDisplay';
import { DateTime } from 'luxon';
import ExpectedDoseDisplay from '../../components/form/input/display/ExpectedDoseDisplay';
import ValueWithReasonAndSpecificationDisplay from '../../components/form/input/display/ValueWithReasonAndSpecificationDisplay';
import { ConditionMode, useFieldState, useScopedField } from '@ngt/forms-core';
import { BasicAlert } from '@ngt/forms-trials';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { faChevronDown } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles } from '@mui/styles';

declare let global: any;

if (typeof global === 'undefined') {
    (window as any).global = window;
}
const openClinicaUrl = global.OPEN_CLINICA_CRF_URL as string;

interface IConcomitantMedicationsProps {
}

const useStyles = makeStyles((theme: Theme) => ({
    title: {
        padding: theme.spacing(2, 2, 2)
    },
    titleContainer: {
        padding: theme.spacing(2, 2, 2),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    subtitle: {
        padding: theme.spacing(2, 2, 0)
    },
    patientInformation: {
        padding: theme.spacing(2, 2, 0)
    },
    alert: {
        padding: theme.spacing(2)
    },
    formPadding: {
        padding: theme.spacing(2)
    },
    buttonGroup: {
        padding: theme.spacing(2),
        textAlign: 'right'
    },
    grid: {
        padding: theme.spacing(2, 0)
    },
    accordionSubtitle: {
        color: 'white'
    },
    accordionSummary: {
        backgroundColor: theme.palette.primary.main,
        margin: theme.spacing(0, 2),
        color: 'white'
    },
    accordionDetails: {
        flexDirection: 'column',
        margin: theme.spacing(0, 2),
    },
    accordion: {
        "&.MuiAccordion-root:before": {
            padding: theme.spacing(0, 2),
            margin: theme.spacing(0, 2)
        }
    },
    table: {
        fontSize: `0.875rem !important`,
        background: 'inherit',

        '& .MuiTypography-root': {
            fontSize: `0.875rem !important`,
        },
        '& .MuiGrid-root': {
            background: 'inherit',
        }
    }
}));

const registrationConcomitantMedicationColumns: IGroupedSubformGridCell<MedicalHistoryMedication>[] = [
    {
        name: 'medicationName',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.medicationName?.toLowerCase()
    },
    {
        name: 'indication',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.indication
    },
    {
        name: 'startDate',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.startDate
    }
];

const onStudyConcomitantMedicationColumns: IGroupedSubformGridCell<ConcomitantMedication>[] = [
    {
        name: 'medication',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.medication?.toLowerCase()
    },
    {
        name: 'indication',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.indication
    },
    {
        name: 'dateStarted',
        content: (
            <Input
                component={DateDisplay}
            />
        ),
        sortValue: (row) => row?.dateStarted
    },
    {
        name: 'dateStopped',
        content: (
            <Input
                component={DateDisplay}
            />
        ),
        sortValue: (row) => row?.dateStopped
    },
    {
        name: 'ongoing',
        content: (
            <Input
                component={BooleanDisplay}
            />
        ),
        sortValue: (row) => row?.ongoing
    }
];


const hasNoRegistrationConcomitantMedications: ICondition<ConcomitantMedicationsDto> = ({ formState: { value } = {} }) => !value?.registrationConcomitantMedications || value?.registrationConcomitantMedications?.length === 0;
const hasRegistrationConcomitantMedications: ICondition<ConcomitantMedicationsDto> = ({ formState: { value } = {} }) => !!value?.registrationConcomitantMedications && value?.registrationConcomitantMedications?.length > 0;
const hasNoOnStudyConcomitantMedications: ICondition<ConcomitantMedicationsDto> = ({ formState: { value } = {} }) => !value?.onStudyConcomitantMedications || value?.onStudyConcomitantMedications?.length === 0;
const hasOnStudyConcomitantMedications: ICondition<ConcomitantMedicationsDto> = ({ formState: { value } = {} }) => !!value?.onStudyConcomitantMedications && value?.onStudyConcomitantMedications?.length > 0;

const conditionSubscription = { value: true };

const ConcomitantMedications: FunctionComponent<IConcomitantMedicationsProps> = () => {
    const classes = useStyles();

    return (
        <>
            <Accordion
                defaultExpanded
                elevation={0}
                classes={{
                    root: classes.accordion
                }}
            >
                <AccordionSummary
                    expandIcon={<FontAwesomeIcon icon={faChevronDown} color="white" />}
                    className={classes.accordionSummary}
                >
                    <Typography variant="h2" className={classes.accordionSubtitle}>
                        Concomitant Medications
                    </Typography>
                </AccordionSummary>
                <AccordionDetails
                    className={classes.accordionDetails}
                >
                    <GroupedFields>
                        <Typography variant="h2" className={classes.subtitle}>
                            Medical History
                        </Typography>
                        <Condition
                            type={ConcomitantMedicationsDto}
                            condition={hasRegistrationConcomitantMedications}
                            subscription={conditionSubscription}
                            mode={ConditionMode.Show}
                        >
                            <Box className={classes.table}>
                                <GroupedSubformGrid
                                    type={MedicalHistoryMedication}
                                    name="registrationConcomitantMedications"
                                    columns={registrationConcomitantMedicationColumns}
                                    disabled
                                    stickHeader
                                    
                                />
                            </Box>
                        </Condition>
                        <Condition
                            type={ConcomitantMedicationsDto}
                            condition={hasNoRegistrationConcomitantMedications}
                            subscription={conditionSubscription}
                            mode={ConditionMode.Show}
                        >
                            <div className={classes.alert}>
                                <BasicAlert
                                    title="No Medical History Concomitant Medications"
                                    description="There is no Medical History Concomitant Medication associated with this patient."
                                    icon={faInfoCircle}
                                    severity="info"
                                />
                            </div>
                        </Condition>
                    </GroupedFields>
                    <GroupedFields>
                        <Box
                            sx={{ display: 'flex', gap: '1rem' }}
                        >
                            <Typography variant="h2" className={classes.subtitle}>
                                On Study
                            </Typography>
                            <FieldProvider
                                name="event"
                            >
                                <Box sx={{ marginTop: '0.75rem' }} >
                                    <Input
                                        component={OpenClinicaFormDisplay}
                                        openClinicaUrl={openClinicaUrl}
                                        eventCrfIdName="eventCrfId"
                                        studyEventIdName="studyEventId"
                                        btnMinWidth="140px"
                                        eventCrfIdPrependParentName={false}
                                        studyEventIdPrependParentName={false}
                                        textValueButton
                                    />
                                </Box>
                            </FieldProvider>
                        </Box>
                        <Condition
                            type={ConcomitantMedicationsDto}
                            condition={hasOnStudyConcomitantMedications}
                            subscription={conditionSubscription}
                            mode={ConditionMode.Show}
                        >
                            <Box className={classes.table}>
                                <GroupedSubformGrid
                                    type={ConcomitantMedication}
                                    name="onStudyConcomitantMedications"
                                    columns={onStudyConcomitantMedicationColumns}
                                    disabled
                                    stickHeader
                                />
                            </Box>
                        </Condition>
                        <Condition
                            type={ConcomitantMedicationsDto}
                            condition={hasNoOnStudyConcomitantMedications}
                            subscription={conditionSubscription}
                            mode={ConditionMode.Show}
                        >
                            <div className={classes.alert}>
                                <BasicAlert
                                    title="No On Study Concomitant Medications"
                                    description="There is no On Study Concomitant Medication associated with this patient."
                                    icon={faInfoCircle}
                                    severity="info"
                                />
                            </div>
                        </Condition>
                    </GroupedFields>
                </AccordionDetails>
            </Accordion>
        </>
    );
};

export default ConcomitantMedications;
