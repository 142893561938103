import { faChevronDown } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Accordion, AccordionDetails, AccordionSummary, Table, TableBody, TableCell, TableHead, TableRow, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { border } from '@mui/system';
import { DateDisplay, FieldProvider, FormLabel, GroupedFields, IGroupedFieldStyleProps, TextDisplay } from '@ngt/forms';
import { ContextFormTitle, ContextPISignOffForm, InputOnlyField, GroupedField } from '@ngt/forms-pi-signoff';
import React, { FunctionComponent } from 'react';

declare let global: any;

if (typeof global === 'undefined') {
    (window as any).global = window;
}

interface ITreatmentExposureSummaryProps {
}

const useStyles = makeStyles((theme: Theme) => ({
    title: {
        padding: theme.spacing(2, 2, 2)
    },
    titleContainer: {
        padding: theme.spacing(2, 2, 2),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    subtitle: {
        padding: theme.spacing(2, 2, 0)
    },
    patientInformation: {
        padding: theme.spacing(2, 2, 0)
    },
    alert: {
        padding: theme.spacing(2)
    },
    buttonGroup: {
        padding: theme.spacing(2),
        textAlign: 'right'
    },
    grid: {
        padding: theme.spacing(2, 0)
    },
    accordionSubtitle: {
        color: 'white'
    },
    legend: {
        padding: theme.spacing(2),
        lineBreak: 'anywhere',
        '& span': {
            padding: theme.spacing(0, 1),
            whiteSpace: 'nowrap'
        }
    },
    accordionSummary: {
        backgroundColor: theme.palette.primary.main,
        margin: theme.spacing(0, 2),
        color: 'white'
    },
    accordionDetails: {
        flexDirection: 'column',
    },
    header: {
        '& tr > th > p': {
            fontWeight: 600
        }
    }
}));

const groupStyleProps = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 7,
    xl: 7
};

const TreatmentExposureSummary: FunctionComponent<ITreatmentExposureSummaryProps> = () => {
    const classes = useStyles();

    return (
        <>
            <Accordion
                defaultExpanded
                elevation={0}
            >
                <AccordionSummary
                    expandIcon={<FontAwesomeIcon icon={faChevronDown} color="white"/>}
                    className={classes.accordionSummary}
                    
                >
                    <Typography variant="h2" className={classes.accordionSubtitle}>
                        Treatment Exposure Summary
                    </Typography>
                </AccordionSummary>
                <AccordionDetails
                    className={classes.accordionDetails}
                >
                    <Table>
                        <TableHead className={classes.header}>
                            <TableRow>
                                <TableCell>
                                    <FieldProvider name="treatmentExposureNivolumab">
                                        <FormLabel />
                                    </FieldProvider>
                                </TableCell>
                                <TableCell>
                                    <FieldProvider name="treatmentExposureCarboplatin">
                                        <FormLabel />
                                    </FieldProvider>
                                </TableCell>
                                <TableCell>
                                    <FieldProvider name="treatmentExposurePaclitaxel">
                                        <FormLabel />
                                    </FieldProvider>
                                </TableCell>
                                <TableCell>
                                    <FieldProvider name="treatmentExposureSurgery">
                                        <FormLabel />
                                    </FieldProvider>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    <InputOnlyField
                                        name="treatmentExposureNivolumab"
                                        component={TextDisplay}
                                    />
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="treatmentExposureCarboplatin"
                                        component={TextDisplay}
                                    />
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="treatmentExposurePaclitaxel"
                                        component={TextDisplay}
                                    />
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="treatmentExposureSurgery"
                                        component={TextDisplay}
                                    />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                    <div className={classes.legend}>
                        <Typography color="textSecondary" component="span">
                            BCS = Breast Conserving Surgery;
                        </Typography>
                        <Typography color="textSecondary" component="span">
                            M = Mastectomy;
                        </Typography>
                        <Typography color="textSecondary" component="span">
                            M+R = Mastectomy + Reconstruction;
                        </Typography>
                        <Typography color="textSecondary" component="span">
                            OS = Oncoplastic Surgery;
                        </Typography>
                        <Typography color="textSecondary" component="span">
                            SLNB = Sentinel Lymph Node Biopsy;
                        </Typography>
                        <Typography color="textSecondary" component="span">
                            S-SM = Skin Sparing Mastectomy;
                        </Typography>
                    </div>
                </AccordionDetails>
            </Accordion>
        </>
    );
};

export default TreatmentExposureSummary;
