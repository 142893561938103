import { Accordion, AccordionSummary, Theme, Table, TableBody, TableCell, TableHead, TableRow, Typography, Box } from '@mui/material';
import { BooleanDisplay, DateDisplay, FieldProvider, FormLabel, getParentPropertyPath, GroupedFields, IGroupedFieldStyleProps, Input, NumericDisplay, TextDisplay } from '@ngt/forms';
import { ContextFormTitle, ContextPISignOffForm, GroupedSubformGrid, ICondition, IGroupedSubformGridCell, OpenClinicaFormDisplay, Condition, InputOnlyField } from '@ngt/forms-pi-signoff';
import React, { FunctionComponent } from 'react';
import { AdverseEvent, SeriousAdverseEvent, MedicalHistory, ParticipantStudySummary as AdverseEventsDto } from '../../apis/dtos';
import IfElseDisplay from '../../components/form/input/display/IfElseDisplay';
import { DateTime } from 'luxon';
import ValueWithReasonAndSpecificationDisplay from '../../components/form/input/display/ValueWithReasonAndSpecificationDisplay';
import { ConditionMode, useFieldState, useScopedField } from '@ngt/forms-core';
import { BasicAlert } from '@ngt/forms-trials';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { faChevronDown } from '@fortawesome/pro-duotone-svg-icons';
import TableTextResizer from '../../components/form/TableTextResizer';
import { AccordionDetails } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles } from '@mui/styles';

import OpmsSaeDisplay from '../../components/form/input/display/OpmsSaeDisplay';

declare let global: any;

if (typeof global === 'undefined') {
    (window as any).global = window;
}

const openClinicaUrl = global.OPEN_CLINICA_CRF_URL as string;
const opmsUrl = global.OPMS_URL as string;

interface IAdverseEventsProps {
}

const useStyles = makeStyles((theme: Theme) => ({
    title: {
        padding: theme.spacing(2, 2, 2)
    },
    titleContainer: {
        padding: theme.spacing(2, 2, 2),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    subtitle: {
        padding: theme.spacing(2, 2, 0)
    },
    patientInformation: {
        padding: theme.spacing(2, 2, 0)
    },
    alert: {
        padding: theme.spacing(2)
    },
    buttonGroup: {
        padding: theme.spacing(2),
        textAlign: 'right'
    },
    grid: {
        padding: theme.spacing(2, 0)
    },
    heading: {
        padding: theme.spacing(2, 0, 1),
    },
    subheading: {
        padding: theme.spacing(2, 0),
    },
    accordionSubtitle: {
        color: 'white'
    },
    accordionSummary: {
        backgroundColor: theme.palette.primary.main,
        margin: theme.spacing(0, 2),
        color: 'white'
    },
    accordionDetails: {
        flexDirection: 'column',
        margin: theme.spacing(0, 2),
    },
    accordion: {
        "&.MuiAccordion-root:before": {
            padding: theme.spacing(0, 2),
            margin: theme.spacing(0, 2)
        }
    },
    table: {
        fontSize: `0.875rem !important`,
        background: 'inherit',

        '& .MuiTypography-root': {
            fontSize: `0.875rem !important`,
        },
        '& .MuiGrid-root': {
            background: 'inherit',
        }
    }
}));

const groupStyleProps = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 7,
    xl: 7
};

const suspectDrugAccessor = () => "None";

const registrationAdverseEventColumns: IGroupedSubformGridCell<MedicalHistory>[] = [
    {
        name: 'medicalCondition',
        content: (
            <Input component={TextDisplay} />
        ),
        sortValue: (row) => row?.medicalCondition
    },
    {
        name: 'currentAtRegistration',
        content: (
            <Input component={TextDisplay} />
        ),
        sortValue: (row) => row?.currentAtRegistration
    },
    {
        name: 'dateOfDiagnosis',
        content: (
            <Input component={TextDisplay} />
        ),
        sortValue: (row) => row?.dateOfDiagnosis
    },
    {
        name: 'treatmentOngoing',
        content: (
            <Input component={TextDisplay} />
        ),
        sortValue: (row) => row?.treatmentOngoing
    }
];

const onStudyAdverseEventColumns: IGroupedSubformGridCell<AdverseEvent>[] = [
    {
        name: 'systemsOrganClass',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.systemsOrganClass
    },
    {
        name: 'aeTerm',
        content: (
            <Input
                component={ValueWithReasonAndSpecificationDisplay}
                reasonName="aeTermSpecify"
                reasonPrependParentName
                reasonTableCell
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'aeTermSpecify'
                }
            ]
        },
        sortValue: (row) => !!row?.aeTerm ? `${row?.aeTerm} - ${row?.aeTermSpecify}` : undefined
    },
    {
        name: 'grade',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.grade
    },
    {
        name: 'onset',
        content: (
            <Input
                component={DateDisplay}
            />
        ),
        sortValue: (row) => row?.onset
    },
    {
        name: 'outcome',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.outcome
    },
    {
        name: 'resolution',
        content: (
            <Input
                component={DateDisplay}
            />
        ),
        sortValue: (row) => row?.resolution
    },
    {
        name: 'serious',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.resolution
    },
    {
        name: 'relationshipToTreatment',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.relationshipToTreatment
    },
    {
        name: 'actionTakenNivolumab',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.actionTakenNivolumab
    },
    {
        name: 'actionTakenPaclitaxel',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.actionTakenPaclitaxel
    },
    {
        name: 'actionTakenCarboplatin',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.actionTakenCarboplatin
    }
];

const saeColumns: IGroupedSubformGridCell<SeriousAdverseEvent>[] = [
    {
        name: 'saeNumber',
        content: (
            <Input
                component={OpmsSaeDisplay}
                opmsUrl={opmsUrl}
                saeIdName="saeNumber"
                btnMinWidth="50px"
                saeIdPrependParentName
                saeIdTableCell
                textValueButton
            />
        ),
        sortValue: (row) => row?.saeNumber
    },
    {
        name: 'term',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.term
    },
    {
        name: 'onset',
        content: (
            <Input
                component={DateDisplay}
            />
        ),
        sortValue: (row) => row?.onset
    },
    {
        name: 'outcome',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.outcome
    },
    {
        name: 'outcomeDate',
        content: (
            <Input
                component={DateDisplay}
            />
        ),
        sortValue: (row) => row?.outcomeDate
    },
    {
        name: 'suspectDrugOrCause',
        content: (
            <Input
                component={IfElseDisplay}
                ifPrependParentName
                ifTableCell
                ifName="suspectDrugOrCause"
                elsePrependParentName
                elseTableCell
                elseAccessor={suspectDrugAccessor}
                elseName="suspectDrugOrCause"
            />

        ),
        sortValue: (row) => row?.suspectDrugOrCause ?? 'None'
    },
    {
        name: 'expectedness',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.expectedness
    },
    {
        name: 'comments',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.comments
    }
];

const hasRegistrationAdverseEvents: ICondition<AdverseEventsDto> = ({ formState: { value } = {} }) => !!value?.registrationAdverseEvents && value?.registrationAdverseEvents?.length > 0;
const hasNoRegistrationAdverseEvents: ICondition<AdverseEventsDto> = ({ formState: { value } = {} }) => !value?.registrationAdverseEvents || value?.registrationAdverseEvents?.length === 0;

const hasNoOnStudyAdverseEvents: ICondition<AdverseEventsDto> = ({ formState: { value } = {} }) => !value?.onStudyAdverseEvents || value?.onStudyAdverseEvents?.length === 0;
const hasOnStudyAdverseEvents: ICondition<AdverseEventsDto> = ({ formState: { value } = {} }) => !!value?.onStudyAdverseEvents && value?.onStudyAdverseEvents?.length > 0;

const hasNoSeriousAdverseEvents: ICondition<AdverseEventsDto> = ({ formState: { value } = {} }) => !value?.seriousAdverseEvents || value?.seriousAdverseEvents?.length === 0;
const hasSeriousAdverseEvents: ICondition<AdverseEventsDto> = ({ formState: { value } = {} }) => !!value?.seriousAdverseEvents && value?.seriousAdverseEvents?.length > 0;

const conditionSubscription = { value: true };

const AdverseEvents: FunctionComponent<IAdverseEventsProps> = () => {
    const classes = useStyles();

    return (
        <>
            <Accordion
                defaultExpanded
                elevation={0}
                classes={{
                    root: classes.accordion
                }}
            >
                <AccordionSummary
                    expandIcon={<FontAwesomeIcon icon={faChevronDown} color="white" />}
                    className={classes.accordionSummary}
                >
                    <Typography variant="h2" className={classes.accordionSubtitle}>
                        Adverse Events
                    </Typography>
                </AccordionSummary>
                <AccordionDetails
                    className={classes.accordionDetails}
                >
                    <GroupedFields>
                        <Typography variant="h2" className={classes.subtitle}>
                            Medical History
                        </Typography>
                        <Condition
                            type={AdverseEventsDto}
                            condition={hasRegistrationAdverseEvents}
                            subscription={conditionSubscription}
                            mode={ConditionMode.Show}
                        >
                            <Box className={classes.table}>
                                <GroupedSubformGrid
                                    type={MedicalHistory}
                                    name="registrationAdverseEvents"
                                    columns={registrationAdverseEventColumns}
                                    disabled
                                    stickHeader
                                />
                            </Box>
                        </Condition>
                        <Condition
                            type={AdverseEventsDto}
                            condition={hasNoRegistrationAdverseEvents}
                            subscription={conditionSubscription}
                            mode={ConditionMode.Show}
                        >
                            <div className={classes.alert}>
                                <BasicAlert
                                    title="No Medical History Adverse Events"
                                    description="There is no Medical History Adverse Events associated with this patient."
                                    icon={faInfoCircle}
                                    severity="info"
                                />
                            </div>
                        </Condition>
                    </GroupedFields>
                    <GroupedFields>
                        <Box
                            sx={{ display: 'flex', gap: '1rem' }}
                        >
                            <Typography variant="h2" className={classes.subtitle}>
                                On Study
                            </Typography>
                            <FieldProvider
                                name="event"
                            >
                                <Box sx={{ marginTop: '0.75rem' }} >
                                    <Input
                                        component={OpenClinicaFormDisplay}
                                        openClinicaUrl={openClinicaUrl}
                                        eventCrfIdName="eventCrfId"
                                        studyEventIdName="studyEventId"
                                        btnMinWidth="140px"
                                        eventCrfIdPrependParentName={false}
                                        studyEventIdPrependParentName={false}
                                        textValueButton
                                    />
                                </Box>
                            </FieldProvider>
                        </Box>
                        <Condition
                            type={AdverseEventsDto}
                            condition={hasOnStudyAdverseEvents}
                            subscription={conditionSubscription}
                            mode={ConditionMode.Show}
                        >
                            <Box className={classes.table}>
                                <GroupedSubformGrid
                                    type={AdverseEvent}
                                    name="onStudyAdverseEvents"
                                    columns={onStudyAdverseEventColumns}
                                    disabled
                                    stickHeader
                                />
                            </Box>
                        </Condition>
                        <Condition
                            type={AdverseEventsDto}
                            condition={hasNoOnStudyAdverseEvents}
                            subscription={conditionSubscription}
                            mode={ConditionMode.Show}
                        >
                            <div className={classes.alert}>
                                <BasicAlert
                                    title="No On Study Adverse Events"
                                    description="There is no On Study Adverse Events associated with this patient."
                                    icon={faInfoCircle}
                                    severity="info"
                                />
                            </div>
                        </Condition>
                    </GroupedFields>
                    <GroupedFields>
                        <Typography variant="h2" className={classes.subtitle}>
                            Serious Adverse Event Summary
                        </Typography>
                        <Condition
                            type={AdverseEventsDto}
                            condition={hasSeriousAdverseEvents}
                            subscription={conditionSubscription}
                            mode={ConditionMode.Show}
                        >
                            <Box className={classes.table}>
                                <GroupedSubformGrid
                                    type={SeriousAdverseEvent}
                                    name="seriousAdverseEvents"
                                    columns={saeColumns}
                                    disabled
                                />
                            </Box>
                        </Condition>
                        <Condition
                            type={AdverseEventsDto}
                            condition={hasNoSeriousAdverseEvents}
                            subscription={conditionSubscription}
                            mode={ConditionMode.Show}
                        >
                            <div className={classes.alert}>
                                <BasicAlert
                                    title="No Serious Adverse Events"
                                    description="There is no Serious Adverse Events associated with this patient."
                                    icon={faInfoCircle}
                                    severity="info"
                                />
                            </div>
                        </Condition>
                    </GroupedFields>
                </AccordionDetails>
            </Accordion>
        </>
    );
};

export default AdverseEvents;
