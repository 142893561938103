import { Accordion, AccordionDetails, AccordionSummary, Table, TableBody, TableCell, TableHead, TableRow, Theme, Typography } from '@mui/material';
import { DateDisplay, FieldProvider, FormLabel, getParentPropertyPath, GroupedFields, IGroupedFieldStyleProps, Input, NumericDisplay, TextDisplay } from '@ngt/forms';
import { Condition, ContextFormTitle, ContextPISignOffForm, GroupedField, GroupedSubformGrid, ICondition, IGroupedSubformGridCell, IUpdatedFormPropertyStyler } from '@ngt/forms-pi-signoff';
import React, { FunctionComponent } from 'react';
import { NivolumabTherapy, CarboplatinTherapy, PaclitaxelTherapy, ParticipantStudySummary } from '../../apis/dtos';
import IfElseDisplay from '../../components/form/input/display/IfElseDisplay';
import { DateTime } from 'luxon';
import ExpectedDoseDisplay from '../../components/form/input/display/ExpectedDoseDisplay';
import ValueWithReasonAndSpecificationDisplay from '../../components/form/input/display/ValueWithReasonAndSpecificationDisplay';
import { ConditionMode, useFieldState, useScopedField } from '@ngt/forms-core';
import { InputOnlyField } from '@ngt/forms-pi-signoff';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-duotone-svg-icons';
import { makeStyles } from '@mui/styles';
import { BasicAlert } from '@ngt/forms-trials';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { IGroupedFieldStyleColumns } from '@ngt/forms/dist/components/form/field/grouped/GroupedField';

declare let global: any;

if (typeof global === 'undefined') {
    (window as any).global = window;
}

interface INeonTherapyProps {
}

const useStyles = makeStyles((theme: Theme) => ({
    title: {
        padding: theme.spacing(2, 2, 2)
    },
    titleContainer: {
        padding: theme.spacing(2, 2, 2),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    subtitle: {
        padding: theme.spacing(2, 2, 0)
    },
    patientInformation: {
        padding: theme.spacing(2, 2, 0)
    },
    alert: {
        padding: theme.spacing(2)
    },
    formPadding: {
        padding: theme.spacing(2)
    },
    buttonGroup: {
        padding: theme.spacing(2),
        textAlign: 'right'
    },
    legend: {
        padding: theme.spacing(2),
        lineBreak: 'anywhere',
        '& span': {
            padding: theme.spacing(0, 1),
            whiteSpace: 'nowrap'
        }
    },
    heading: {
        padding: theme.spacing(2, 0, 1),
    },
    subheading: {
        padding: theme.spacing(2, 0),
    },
    accordionSubtitle: {
        color: 'white'
    },
    accordionSummary: {
        backgroundColor: theme.palette.primary.main,
        margin: theme.spacing(0, 2),
        color: 'white'
    },
    accordionDetails: {
        flexDirection: 'column',
        margin: theme.spacing(0, 2),
    },
    accordion: {
        "&.MuiAccordion-root:before": {
            padding: theme.spacing(0, 2),
            margin: theme.spacing(0, 2)
        }
    }
}));

const defaultInputColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 7,
    xl: 7
}

const defaultLabelColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 5,
    xl: 5
}

const groupStyleProps: IGroupedFieldStyleProps = {
    labelColumn: defaultLabelColumnSizes,
    inputColumn: defaultInputColumnSizes
};

const hasNivolumab: ICondition<ParticipantStudySummary> = ({ formState: { value } = {} }) => !!value?.nivolumabTherapies && value?.nivolumabTherapies?.length > 0;
const hasNoNivolumab: ICondition<ParticipantStudySummary> = ({ formState: { value } = {} }) => !value?.nivolumabTherapies || value?.nivolumabTherapies?.length === 0;

const hasPaclitaxel: ICondition<ParticipantStudySummary> = ({ formState: { value } = {} }) => !!value?.paclitaxelTherapies && value?.paclitaxelTherapies?.length > 0;
const hasNoPaclitaxel: ICondition<ParticipantStudySummary> = ({ formState: { value } = {} }) => !value?.paclitaxelTherapies || value?.paclitaxelTherapies?.length === 0;

const hasCarboplatin: ICondition<ParticipantStudySummary> = ({ formState: { value } = {} }) => !!value?.carboplatinTherapies && value?.carboplatinTherapies?.length > 0;
const hasNoCarboplatin: ICondition<ParticipantStudySummary> = ({ formState: { value } = {} }) => !value?.carboplatinTherapies || value?.carboplatinTherapies?.length === 0;

const dateAccessor = (value: string) => value ? DateTime.fromISO(value).toFormat('dd/MM/yyyy') : null;
const notAdministeredAccessor = (value: string) => value ? <>Not Administered &ndash; {value}</> : 'Not Administered';
const booleanAccessor = (value: boolean) => value === true ? 'Yes' : value === false ? 'No' : null;

const nivolumabColumns: IGroupedSubformGridCell<NivolumabTherapy>[] = [
    {
        name: 'eventName',
        width: 170,
        minWidth: 170,
        maxWidth: 170,
        content: (
            <Input component={TextDisplay} />
        )
    },
    {
        name: 'administered',
        width: 300,
        content: (
            <Input
                component={IfElseDisplay}
                ifName="date"
                elseName="notAdministeredReason"
                ifPrependParentName
                ifTableCell
                elsePrependParentName
                elseTableCell
                ifAccessor={dateAccessor}
                elseAccessor={notAdministeredAccessor}
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'date'
                },
                {
                    name: 'notAdministeredReason'
                }
            ]
        }
    },
    {
        name: 'doseAdministered',
        width: 300,
        content: (
            <Input
                component={NumericDisplay}
                suffix={
                    <Typography color="textSecondary" variant="caption" component="span"> mg</Typography>
                }
            />
        )
    },
    {
        name: 'expectedDose',
        width: 300,
        content: (
            <Input
                component={NumericDisplay}
                suffix={
                    <Typography color="textSecondary" variant="caption" component="span"> mg</Typography>
                }
            />
        )
    },
    {
        name: 'doseDelayed',
        width: 300,
        content: (
            <Input
                component={ValueWithReasonAndSpecificationDisplay}
                specificationName="doseDelayedReason"
                specificationPrependParentName
                specificationTableCell
                accessor={booleanAccessor}
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'doseDelayedReason'
                }
            ]
        }
    },
    {
        name: 'lastDose',
        width: 300,
        content: (
            <Input
                component={ValueWithReasonAndSpecificationDisplay}
                specificationName="lastDoseSpecify"
                reasonName="lastDoseReason"
                reasonPrependParentName
                reasonTableCell
                specificationPrependParentName
                specificationTableCell
                accessor={booleanAccessor}
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'lastDoseSpecify'
                },
                {
                    name: 'lastDoseReason'
                }
            ]
        }
    }
];


const paclitaxelColumns: IGroupedSubformGridCell<PaclitaxelTherapy>[] = [
    {
        name: 'eventName',
        width: 170,
        minWidth: 170,
        maxWidth: 170,
        content: (
            <Input component={TextDisplay} />
        )
    },
    {
        name: 'timepoint',
        width: 300,
        content: (
            <Input component={TextDisplay} />
        )
    },
    {
        name: 'administered',
        width: 300,
        content: (
            <Input
                component={IfElseDisplay}
                ifName="date"
                elseName="notAdministeredReason"
                ifPrependParentName
                ifTableCell
                elsePrependParentName
                elseTableCell
                ifAccessor={dateAccessor}
                elseAccessor={notAdministeredAccessor}
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'date'
                },
                {
                    name: 'notAdministeredReason'
                }
            ]
        }
    },
    {
        name: 'doseAdministered',
        width: 300,
        content: (
            <Input
                component={NumericDisplay}
                suffix={
                    <Typography color="textSecondary" variant="caption" component="span"> mg</Typography>
                }
            />
        )
    },
    {
        name: 'reduced',
        width: 300,
        content: (
            <Input
                component={ValueWithReasonAndSpecificationDisplay}
                specificationName="reducedReason"
                specificationPrependParentName
                specificationTableCell
                accessor={booleanAccessor}
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'reducedReason'
                }
            ]
        }
    },
    {
        name: 'omitted',
        width: 300,
        content: (
            <Input
                component={ValueWithReasonAndSpecificationDisplay}
                specificationName="omittedReason"
                specificationPrependParentName
                specificationTableCell
                accessor={booleanAccessor}
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'omittedReason'
                }
            ]
        }
    },
    {
        name: 'lastDose',
        width: 300,
        content: (
            <Input
                component={ValueWithReasonAndSpecificationDisplay}
                specificationName="lastDoseSpecify"
                reasonName="lastDoseReason"
                reasonPrependParentName
                reasonTableCell
                specificationPrependParentName
                specificationTableCell
                accessor={booleanAccessor}
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'lastDoseSpecify'
                },
                {
                    name: 'lastDoseReason'
                }
            ]
        }
    }
];


const carboplatinColumns: IGroupedSubformGridCell<CarboplatinTherapy>[] = [
    {
        name: 'eventName',
        width: 170,
        minWidth: 170,
        maxWidth: 170,
        content: (
            <Input component={TextDisplay} />
        )
    },
    {
        name: 'administered',
        width: 300,
        content: (
            <Input
                component={IfElseDisplay}
                ifName="date"
                elseName="notAdministeredReason"
                ifPrependParentName
                ifTableCell
                elsePrependParentName
                elseTableCell
                ifAccessor={dateAccessor}
                elseAccessor={notAdministeredAccessor}
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'date'
                },
                {
                    name: 'notAdministeredReason'
                }
            ]
        }
    },
    {
        name: 'expectedDoseCalvert',
        width: 300,
        content: (
            <Input
                component={NumericDisplay}
                decimalPlaces={2}
                suffix={
                    <Typography color="textSecondary" variant="caption" component="span"> mg</Typography>
                }
            />
        )
    },
    {
        name: 'doseAdministered',
        width: 300,
        content: (
            <Input
                component={NumericDisplay}
                suffix={
                    <Typography color="textSecondary" variant="caption" component="span"> mg</Typography>
                }
            />
        )
    },
    {
        name: 'reduced',
        width: 300,
        content: (
            <Input
                component={ValueWithReasonAndSpecificationDisplay}
                specificationName="reducedReason"
                specificationPrependParentName
                specificationTableCell
                accessor={booleanAccessor}
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'reducedReason'
                }
            ]
        }
    },
    {
        name: 'doseDelayed',
        width: 300,
        content: (
            <Input
                component={ValueWithReasonAndSpecificationDisplay}
                specificationName="doseDelayedReason"
                specificationPrependParentName
                specificationTableCell
                accessor={booleanAccessor}
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'doseDelayedReason'
                }
            ]
        }
    },
    {
        name: 'lastDose',
        width: 300,
        content: (
            <Input
                component={ValueWithReasonAndSpecificationDisplay}
                specificationName="lastDoseSpecify"
                reasonName="lastDoseReason"
                reasonPrependParentName
                reasonTableCell
                specificationPrependParentName
                specificationTableCell
                accessor={booleanAccessor}
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'lastDoseSpecify'
                },
                {
                    name: 'lastDoseReason'
                }
            ]
        }
    }
];

const NeonTherapy: FunctionComponent<INeonTherapyProps> = () => {
    const classes = useStyles();

    return (
        <>
            <Accordion
                defaultExpanded
                elevation={0}
                classes={{
                    root: classes.accordion
                }}
            >
                <AccordionSummary
                    expandIcon={<FontAwesomeIcon icon={faChevronDown} color="white" />}
                    className={classes.accordionSummary}
                >
                    <Typography variant="h2" className={classes.accordionSubtitle}>
                        Neo-N Therapy
                    </Typography>
                </AccordionSummary>
                <AccordionDetails
                    className={classes.accordionDetails}
                >
                    <GroupedFields>
                        <Typography variant="h2" className={classes.subtitle}>
                            Nivolumab
                        </Typography>
                        <GroupedField
                            name="treatment"
                            component={TextDisplay}
                            GroupStyleProps={groupStyleProps}
                        />
                        <GroupedField
                            name="plannedStartDate"
                            component={DateDisplay}
                            GroupStyleProps={groupStyleProps}
                        />
                        <Condition
                            type={ParticipantStudySummary}
                            condition={hasNivolumab}
                            subscription={{ value: true }}
                            mode={ConditionMode.Show}
                        >
                            <GroupedSubformGrid
                                type={NivolumabTherapy}
                                name="nivolumabTherapies"
                                columns={nivolumabColumns}
                                disabled
                            />
                        </Condition>
                        <Condition
                            type={ParticipantStudySummary}
                            condition={hasNoNivolumab}
                            subscription={{ value: true }}
                            mode={ConditionMode.Show}
                        >
                            <div className={classes.alert}>
                                <BasicAlert
                                    title="No Nivolumab Data"
                                    description="There is no Nivolumab information associated with this patient."
                                    icon={faInfoCircle}
                                    severity="info"
                                />
                            </div>
                        </Condition>
                    </GroupedFields>
                    <GroupedFields>
                        <Typography variant="h2" className={classes.subtitle}>
                            Paclitaxel
                        </Typography>
                        <GroupedField
                            name="expectedDoseMosteller"
                            component={NumericDisplay}
                            decimalPlaces={2}
                            GroupStyleProps={groupStyleProps}
                            suffix={
                                <Typography color="textSecondary" variant="caption" component="span"> mg/m<sup>2</sup></Typography>
                            }
                        />
                        <GroupedField
                            name="expectedDoseDubois"
                            component={NumericDisplay}
                            decimalPlaces={2}
                            GroupStyleProps={groupStyleProps}
                            suffix={
                                <Typography color="textSecondary" variant="caption" component="span"> mg/m<sup>2</sup></Typography>
                            }
                        />
                        <Condition
                            type={ParticipantStudySummary}
                            condition={hasPaclitaxel}
                            subscription={{ value: true }}
                            mode={ConditionMode.Show}
                        >
                            <GroupedSubformGrid
                                type={PaclitaxelTherapy}
                                name="paclitaxelTherapies"
                                columns={paclitaxelColumns}
                                disabled
                            />
                        </Condition>
                        <Condition
                            type={ParticipantStudySummary}
                            condition={hasNoPaclitaxel}
                            subscription={{ value: true }}
                            mode={ConditionMode.Show}
                        >
                            <div className={classes.alert}>
                                <BasicAlert
                                    title="No Paclitaxel Data"
                                    description="There is no Paclitaxel information associated with this patient."
                                    icon={faInfoCircle}
                                    severity="info"
                                />
                            </div>
                        </Condition>
                    </GroupedFields>
                    <GroupedFields>
                        <Typography variant="h2" className={classes.subtitle}>
                            Carboplatin
                        </Typography>
                        <Condition
                            type={ParticipantStudySummary}
                            condition={hasCarboplatin}
                            subscription={{ value: true }}
                            mode={ConditionMode.Show}
                        >
                            <GroupedSubformGrid
                                type={CarboplatinTherapy}
                                name="carboplatinTherapies"
                                columns={carboplatinColumns}
                                disabled
                            />
                        </Condition>
                        <Condition
                            type={ParticipantStudySummary}
                            condition={hasNoCarboplatin}
                            subscription={{ value: true }}
                            mode={ConditionMode.Show}
                        >
                            <div className={classes.alert}>
                                <BasicAlert
                                    title="No Carboplatin Data"
                                    description="There is no Carboplatin information associated with this patient."
                                    icon={faInfoCircle}
                                    severity="info"
                                />
                            </div>
                        </Condition>
                    </GroupedFields>
                </AccordionDetails>
            </Accordion>
                
                
        </>
    );
};

export default NeonTherapy;
