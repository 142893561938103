import React, { FunctionComponent } from 'react';
import {  useIsMobile } from '@ngt/forms';
import { Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopyright } from '@fortawesome/pro-regular-svg-icons/faCopyright';

interface IFooterProps {
}

const Footer: FunctionComponent<IFooterProps> = () => {

    const isMobile = useIsMobile();

    if (isMobile) {
        return null;
    }

    return (
        <Typography variant="caption">
            Copyright <FontAwesomeIcon icon={faCopyright} fixedWidth /> {(new Date().getFullYear())} ANZ Breast Cancer Trials Group Ltd | ABN 64 051 369 496
        </Typography>
    );
};

export default Footer;
